import { Box, Typography, Container } from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';

import Wave from '../../../assets/img/telepathology_dx/wave.png';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: '125px',
      minHeight: '100vh',
      height: 'auto',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        paddingTop: '75px'
      },
      position: 'relative',
      overflowX: 'hidden'
    },
    wave: {
      position: 'absolute',
      top: '10rem',
      width: '100%'
    },
    container: {
      marginTop: '50px',
      [theme.breakpoints.down('sm')]: {
        padding: '26px'
      }
    },
    title: {
      fontSize: '71px !important',
      fontWeight: '600 !important',
      lineHeight: '129% !important',
      color: '#20C2E5 !important',
      textAlign: 'center',
      [theme.breakpoints.down('lg')]: {
        fontSize: '60px !important'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '51px !important'
      }
    },
    content: {
      fontSize: '16px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    image: {
      width: '100%',
      maxWidth: '600px',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '400px',
      }
    }
  })
);

const Oddiyana = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img src={Wave} className={classes.wave} />
      <Container maxWidth='xl' className={classes.container}>
        <Box sx={{ mt: { xs: '0', lg: '175px' }, mb: '50px', display: 'flex', alignItems: 'center', flexDirection: {xs: 'column', md: 'row'}, gap: '60px' }}>
          <Typography className={classes.title}>Digital Pathology Innovator, ZayaAI Partners with Oddiyana Ventures - Pioneering AI & Blockchain in Healthcare</Typography>
          <img src='/news/1.jpeg' className={classes.image} />
        </Box>
        <div>
          <p className={classes.content}>
            <b>[Bucharest, May 20th, 2024]</b> - ZayaAI, an AI-focused technology company pioneering a new era in pathology diagnosis, revealed its partnership with Oddiyana Ventures, an investment firm focused on funding innovation in the decentralization movement.
          </p> <br />
          <p className={classes.content}>
            Leveraging AI and blockchain, ZayaAI equips pathologists and researchers in clinical labs with powerful deep-learning artificial intelligence software to reduce diagnosis time, decrease lab costs and reduce human error and improve patient outcomes.          
          </p> <br />
          <p className={classes.content}>
            By combining ZayaAI’s cutting-edge technology with Oddiyana Ventures’ strategic investment and industry expertise, both companies aim to enhance the accuracy and efficiency of pathology diagnostics, ultimately improving patient outcomes.
          </p> <br />
          <p className={classes.content}>
            <b>Cristian Mogodici, CEO of ZayaAI</b>, expressed his enthusiasm stating, “We are thrilled to partner with Oddiyana Ventures. Their deep understanding of Web3 technologies and track record aligns perfectly with our vision of driving innovation at the intersection of healthcare and advanced digital solutions.”
          </p> <br />
          <p className={classes.content}>
            <b>Dorji Rabten, Oddiyana Ventures</b>, echoed the sentiment, stating, “By partnering with ZayaAI we hope to bridge the gap between Web2 and Web3 technology, for the advancement and improvement of healthcare.”
          </p> <br />

          <p className={classes.content}>
            ZayaAI’s approach seamlessly merges Web2 with Web3 tools, providing a unique synergy in healthcare innovation. The company is confident that Oddiyana Venture’s expertise and strategic guidance will amplify ZayaAI's mission to revolutionize the healthcare landscape.
          </p> <br />

          <a href='https://www.zaya.ai/' style={{ fontWeight: 500 }}>About ZayaAI</a>
          <p className={classes.content}>
            ZayaAI equips pathologists and researchers in clinical labs with powerful deep-learning artificial intelligence software for translating images into discoveries, decisions, and diagnoses. The cloud-based ZayaAI products and services aim to improve the efficiency and precision of pathology diagnosis and pathology image analysis beyond current capabilities, across a variety of fields from oncology to dermato-pathology and more.
          </p> <br />

          <a href='' style={{ fontWeight: 500 }}>Oddiyana Ventures</a>
          <p className={classes.content}>
            Oddiyana Ventures is an investment firm focused on funding the innovation in the decentralization movement, Defi, NFTs, web3 application, metaverse, blockchain gaming, unstoppable finance, and other new developments that are building game-changing business models in the crypto space.
            Having worked with more than 20+ blockchain projects since 2017, this extensive investment relationship, networks/resources, and project incubation experiences put us in a unique place to cater to the needs of the startup.
            Our crypto-native team have deep backgrounds in startup and technology alongside years of experience in the blockchain space. Our approach is to bridge the eastern and the western markets to provide maximum exposure to the startup in addition to our combination of expertise and competence in CeFi, Defi, and NFT. Oddiyana Ventures has made strategic investments in more than 100+ projects.          
          </p> <br />
        </div>
      </Container>
    </Box>
  );
};

export { Oddiyana };
