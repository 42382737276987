import { Address, SmartContract } from '@multiversx/sdk-core';

import { ZayaContracts, interactWithContract, loadAbi } from './utils';

export async function claimTokens (props: {config:  {
  contractAddress: string,
  callerAddress: string,
  chainId: string,
  gasLimit: number,
  networkProviderUrl: string
}}) {
  const { config } = props;
  const { contractAddress, callerAddress, chainId, gasLimit, networkProviderUrl} = config;

  const abi = await loadAbi({contractType: ZayaContracts.VestingContract});

  const contract = new SmartContract({
    address: Address.fromBech32(contractAddress),
    abi
  });

  await interactWithContract({
    contract,
    method: 'claimTokens',
    args: [],
    callerAddress: callerAddress,
    chainId: chainId,
    gasLimit: gasLimit,
    networkProviderUrl: networkProviderUrl
  });
}