export const StakingContractAbi = {
    'buildInfo': {
        'rustc': {
            'version': '1.75.0-nightly',
            'commitHash': '7adc89b69b941afceadcf8609dd6b2999353e550',
            'commitDate': '2023-11-07',
            'channel': 'Nightly',
            'short': 'rustc 1.75.0-nightly (7adc89b69 2023-11-07)'
        },
        'contractCrate': {
            'name': 'contract',
            'version': '0.0.0'
        },
        'framework': {
            'name': 'multiversx-sc',
            'version': '0.46.1'
        }
    },
    'docs': [
        'An empty contract. To be used as a template when starting a new contract from scratch.'
    ],
    'name': 'Contract',
    'constructor': {
        'inputs': [
            {
                'name': 'token_id',
                'type': 'TokenIdentifier'
            }
        ],
        'outputs': []
    },
    'endpoints': [
        {
            'name': 'upgrade',
            'mutability': 'mutable',
            'inputs': [
                {
                    'name': 'token_id',
                    'type': 'TokenIdentifier'
                }
            ],
            'outputs': []
        },
        {
            'docs': [
                '    * Stake tokens - the user can send the tokens to the contract and stake them.\\n     * Only the TOKEN_IDENTIFIER is accepted.\\n     '
            ],
            'name': 'stake',
            'mutability': 'mutable',
            'payableInTokens': [
                '*'
            ],
            'inputs': [
                {
                    'name': 'stake_type_id',
                    'type': 'u32'
                }
            ],
            'outputs': []
        },
        {
            'docs': [
                '    * Claim the tokens, if the stake is claimable. The claimable amount is computed based on the\\n     * REWARD_PERCENTAGE and the stake amount from the last restake (or initial stake if the stake\\n     * was never restaked)\\n     *\\n     * The stake is claimable if:\\n     * - the stake is not auto-restaked\\n     * - the stake is not claimed yet\\n     * - the stake is staked for at least STAKING_PERIOD\\n     '
            ],
            'name': 'claim',
            'mutability': 'mutable',
            'inputs': [
                {
                    'name': 'stake_id',
                    'type': 'u32'
                }
            ],
            'outputs': []
        },
        {
            'docs': [
                '    * Manual restake endpoint. The stake is restaked now, and the claimable amount is computed based\\n     * on the REWARD_PERCENTAGE and the stake amount from the last restake (or initial stake if the stake\\n     * was never restaked).\\n     '
            ],
            'name': 'restake',
            'mutability': 'mutable',
            'inputs': [
                {
                    'name': 'stake_id',
                    'type': 'u32'
                }
            ],
            'outputs': []
        },
        {
            'name': 'enableAutoRestake',
            'mutability': 'mutable',
            'inputs': [
                {
                    'name': 'stake_id',
                    'type': 'u32'
                },
                {
                    'name': 'periods',
                    'type': 'u32'
                }
            ],
            'outputs': []
        },
        {
            'name': 'disableAutoRestake',
            'mutability': 'mutable',
            'inputs': [
                {
                    'name': 'stake_id',
                    'type': 'u32'
                }
            ],
            'outputs': []
        },
        {
            'name': 'fundContract',
            'onlyOwner': true,
            'mutability': 'mutable',
            'payableInTokens': [
                '*'
            ],
            'inputs': [],
            'outputs': []
        },
        {
            'name': 'addStakeType',
            'onlyOwner': true,
            'mutability': 'mutable',
            'inputs': [
                {
                    'name': 'period',
                    'type': 'u64'
                },
                {
                    'name': 'reward_rate',
                    'type': 'u32'
                },
                {
                    'name': 'max_auto_restakes',
                    'type': 'u32'
                }
            ],
            'outputs': [
                {
                    'type': 'u32'
                }
            ]
        },
        {
            'name': 'setStakeTypeEnabled',
            'onlyOwner': true,
            'mutability': 'mutable',
            'inputs': [
                {
                    'name': 'stake_type_id',
                    'type': 'u32'
                },
                {
                    'name': 'is_enabled',
                    'type': 'bool'
                }
            ],
            'outputs': [
                {
                    'type': 'StakeType'
                }
            ]
        },
        {
            'name': 'updateStakedToken',
            'onlyOwner': true,
            'mutability': 'mutable',
            'inputs': [
                {
                    'name': 'token_id',
                    'type': 'TokenIdentifier'
                }
            ],
            'outputs': [
                {
                    'type': 'TokenIdentifier'
                }
            ]
        },
        {
            'name': 'withdrawFund',
            'onlyOwner': true,
            'mutability': 'mutable',
            'inputs': [
                {
                    'name': 'opt_token_id',
                    'type': 'optional<EgldOrEsdtTokenIdentifier>',
                    'multi_arg': true
                },
                {
                    'name': 'opt_token_nonce',
                    'type': 'optional<u64>',
                    'multi_arg': true
                },
                {
                    'name': 'opt_token_amount',
                    'type': 'optional<BigUint>',
                    'multi_arg': true
                }
            ],
            'outputs': [],
            'allow_multiple_var_args': true
        },
        {
            'name': 'getTokenIdentifier',
            'mutability': 'readonly',
            'inputs': [],
            'outputs': [
                {
                    'type': 'TokenIdentifier'
                }
            ]
        },
        {
            'name': 'getCanClaim',
            'mutability': 'readonly',
            'inputs': [
                {
                    'name': 'user',
                    'type': 'Address'
                },
                {
                    'name': 'stake_id',
                    'type': 'u32'
                }
            ],
            'outputs': [
                {
                    'type': 'bool'
                }
            ]
        },
        {
            'name': 'getClaimableAmount',
            'mutability': 'readonly',
            'inputs': [
                {
                    'name': 'user',
                    'type': 'Address'
                },
                {
                    'name': 'stake_id',
                    'type': 'u32'
                }
            ],
            'outputs': [
                {
                    'type': 'BigUint'
                }
            ]
        },
        {
            'name': 'getTimestamp',
            'mutability': 'readonly',
            'inputs': [],
            'outputs': [
                {
                    'type': 'u64'
                }
            ]
        },
        {
            'name': 'getStakeTypesLen',
            'mutability': 'readonly',
            'inputs': [],
            'outputs': [
                {
                    'type': 'u32'
                }
            ]
        },
        {
            'name': 'getStakeTypes',
            'mutability': 'readonly',
            'inputs': [],
            'outputs': [
                {
                    'type': 'List<StakeType>'
                }
            ]
        },
        {
            'name': 'getUserStake',
            'mutability': 'readonly',
            'inputs': [
                {
                    'name': 'user',
                    'type': 'Address'
                },
                {
                    'name': 'stake_id',
                    'type': 'u32'
                }
            ],
            'outputs': [
                {
                    'type': 'UserStake'
                }
            ]
        },
        {
            'name': 'getUserStakeCount',
            'mutability': 'readonly',
            'inputs': [
                {
                    'name': 'user',
                    'type': 'Address'
                }
            ],
            'outputs': [
                {
                    'type': 'u32'
                }
            ]
        },
        {
            'docs': [
                '    * Return the user data.\\n     * This includes the user stakes, with extra information, total staked amount and total claimable amount, current timestamp.\\n     '
            ],
            'name': 'getUserData',
            'mutability': 'readonly',
            'inputs': [
                {
                    'name': 'user',
                    'type': 'Address'
                }
            ],
            'outputs': [
                {
                    'type': 'UserData'
                }
            ]
        }
    ],
    'events': [
        {
            'identifier': 'StakedEvent',
            'inputs': [
                {
                    'name': 'amount',
                    'type': 'BigUint'
                },
                {
                    'name': 'user',
                    'type': 'Address',
                    'indexed': true
                },
                {
                    'name': 'stake_id',
                    'type': 'u32',
                    'indexed': true
                },
                {
                    'name': 'timestamp',
                    'type': 'u64',
                    'indexed': true
                }
            ]
        },
        {
            'identifier': 'ClaimedEvent',
            'inputs': [
                {
                    'name': 'amount',
                    'type': 'BigUint'
                },
                {
                    'name': 'user',
                    'type': 'Address',
                    'indexed': true
                },
                {
                    'name': 'stake_id',
                    'type': 'u32',
                    'indexed': true
                },
                {
                    'name': 'timestamp',
                    'type': 'u64',
                    'indexed': true
                }
            ]
        },
        {
            'identifier': 'RestakedEvent',
            'inputs': [
                {
                    'name': 'user',
                    'type': 'Address',
                    'indexed': true
                },
                {
                    'name': 'stake_id',
                    'type': 'u32',
                    'indexed': true
                },
                {
                    'name': 'timestamp',
                    'type': 'u64',
                    'indexed': true
                }
            ]
        },
        {
            'identifier': 'AutoRestakeEnabledEvent',
            'inputs': [
                {
                    'name': 'user',
                    'type': 'Address',
                    'indexed': true
                },
                {
                    'name': 'stake_id',
                    'type': 'u32',
                    'indexed': true
                },
                {
                    'name': 'timestamp',
                    'type': 'u64',
                    'indexed': true
                }
            ]
        },
        {
            'identifier': 'AutoRestakeDisabledEvent',
            'inputs': [
                {
                    'name': 'user',
                    'type': 'Address',
                    'indexed': true
                },
                {
                    'name': 'stake_id',
                    'type': 'u32',
                    'indexed': true
                },
                {
                    'name': 'timestamp',
                    'type': 'u64',
                    'indexed': true
                }
            ]
        },
        {
            'identifier': 'FundedEvent',
            'inputs': [
                {
                    'name': 'amount',
                    'type': 'BigUint'
                },
                {
                    'name': 'timestamp',
                    'type': 'u64',
                    'indexed': true
                }
            ]
        }
    ],
    'esdtAttributes': [],
    'hasCallback': false,
    'types': {
        'StakeType': {
            'type': 'struct',
            'fields': [
                {
                    'name': 'period',
                    'type': 'u64'
                },
                {
                    'name': 'reward_rate',
                    'type': 'u32'
                },
                {
                    'name': 'max_auto_restakes',
                    'type': 'u32'
                },
                {
                    'name': 'is_enabled',
                    'type': 'bool'
                }
            ]
        },
        'UserData': {
            'type': 'struct',
            'fields': [
                {
                    'name': 'stakes',
                    'type': 'List<UserStakeWithClaimAmount>'
                },
                {
                    'name': 'total_staked',
                    'type': 'BigUint'
                },
                {
                    'name': 'total_claimable',
                    'type': 'BigUint'
                },
                {
                    'name': 'stake_count',
                    'type': 'u32'
                },
                {
                    'name': 'current_timestamp',
                    'type': 'u64'
                }
            ]
        },
        'UserStake': {
            'type': 'struct',
            'fields': [
                {
                    'name': 'staked_at',
                    'type': 'u64'
                },
                {
                    'name': 'restaked_at',
                    'type': 'u64'
                },
                {
                    'name': 'claimable_at',
                    'type': 'u64'
                },
                {
                    'name': 'initial_amount',
                    'type': 'BigUint'
                },
                {
                    'name': 'current_amount',
                    'type': 'BigUint'
                },
                {
                    'name': 'auto_restake',
                    'type': 'bool'
                },
                {
                    'name': 'restake_count',
                    'type': 'u32'
                },
                {
                    'name': 'claimed',
                    'type': 'bool'
                },
                {
                    'name': 'stake_type_id',
                    'type': 'u32'
                }
            ]
        },
        'UserStakeWithClaimAmount': {
            'type': 'struct',
            'fields': [
                {
                    'name': 'stake',
                    'type': 'UserStake'
                },
                {
                    'name': 'will_claim',
                    'type': 'BigUint'
                }
            ]
        }
    }
};
