import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import {
  createStyles, makeStyles
} from '@mui/styles';
import { Box } from '@mui/material';
const useStyles = makeStyles((theme: any) =>
  createStyles({
    header: {
      color: '#FFF',
      fontSize: '38px !important',
      fontWeight: '600 !important',
      lineHeight: 'normal !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px !important'
      },
    },
    contentBox: {
      padding: '27px 24px',
      borderRadius: '15px',
      border: '0.84px solid rgba(255, 255, 255, 0.05)',
      background: '#FFF',
      backdropFilter: 'blur(83.9669418334961px)',
      fontSize: '30px !important',
      color: '#16374D !important',
      marginTop: '12px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px !important'
      },
    },
    contentTitle: {
      fontWeight: '600 !important',
      fontSize: '26px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important'
      },
    },
    contentBody: {
      fontWeight: '400 !important',
      fontSize: '26px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important'
      },
    }
  })
);

const CustomTimeline = () => {
  const classes = useStyles();
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0,
          paddingLeft: 0
        },
      }}
    >
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ paddingTop: '24px'}}>
          <Typography className={classes.header}>
            2023
          </Typography>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q4 - Establishing the Economic Model, Tokenomics, and Roadmap (Q4 2022)
            </Typography>
            <Typography className={classes.contentBody}>
              Determine the economic model for the entirety of the blockchain-related ecosystem, define tokenomics, and develop a comprehensive project roadmap.
            </Typography>
          </Box>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ paddingTop: '24px'}}>
          <Typography className={classes.header}>
            2024
          </Typography>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q1 - Obtaining Legal Opinion
            </Typography>
            <Typography className={classes.contentBody}>
              Seek legal advice to ensure the project complies with all relevant laws and regulations. This includes aspects related to blockchain technology, cryptocurrencies, NFTs, and medical services.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q1 - Drafting the Whitepaper
            </Typography>
            <Typography className={classes.contentBody}>
              Create a whitepaper that clearly outlines the project's vision, mission, and technical intricacies. It would provide a thorough explanation of the economic model, tokenomics, and the overall roadmap.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q1 - Website Redesign, Adding Blockchain-Related Components
            </Typography>
            <Typography className={classes.contentBody}>
              Update the current website design and adding sections related to blockchain technology, NFTs, the Zaya token, and the medical services being offered.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q2 - Zaya Pathology LIS 1.0
            </Typography>
            <Typography className={classes.contentBody}>
            We plan to launch the first version of our Zaya Laboratory
            Information System (LIS), which will streamline laboratory operations and improve efficiency in pathology labs.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q2 - Token Issuance
            </Typography>
            <Typography className={classes.contentBody}>
              Issue the Zaya token, the native cryptocurrency of the platform.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q3 - Staking Module Development
            </Typography>
            <Typography className={classes.contentBody}>
              The development of a staking mechanism for Zaya token holders to earn rewards.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q4 - NFT Auction Module Development
            </Typography>
            <Typography className={classes.contentBody}>
              Create an auction system for the trading of NFTs through the Zaya Platform.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q4 - NFT Reward Module Development
            </Typography>
            <Typography className={classes.contentBody}>
              The development of a system to reward users with NFTs, promoting user engagement and incentivizing positive actions within the ecosystem.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q4 - Zaya Urothelial Carcinoma AI Diagnosis Model Commercial Launch
            </Typography>
            <Typography className={classes.contentBody}>
              We aim to commercially launch our AI-driven diagnosis model for urothelial carcinoma, providing pathologists with a powerful tool to enhance diagnostic accuracy and patient care.
            </Typography>
          </Box>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ paddingTop: '24px'}}>
          <Typography className={classes.header}>
            2025
          </Typography>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q1 - NFT Minting
            </Typography>
            <Typography className={classes.contentBody}>
              Start minting NFTs, ensuring seamless integration with the auction and reward systems.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q1 - Zaya Token Payment Module Development for Medical Services
            </Typography>
            <Typography className={classes.contentBody}>
              Create a module that enables users to pay for medical services using the Zaya token.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q1 - ZAYA Pathology Platform European Launch
            </Typography>
            <Typography className={classes.contentBody}>
              We will expand our presence in the European market with the launch of the ZAYA Pathology Platform, offering a comprehensive suite of AI-driven diagnostic tools and services to pathology labs and medical professionals across the continent.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q2 - ZAYA Pathology Platform UK Launch
            </Typography>
            <Typography className={classes.contentBody}>
              We will further our global expansion by launching the ZAYA Pathology Platform in the United Kingdom, bringing our innovative solutions to the UK healthcare market.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q2 - Automatic Burn Module Construction
            </Typography>
            <Typography className={classes.contentBody}>
              The development of an automated token burning mechanism. For each purchase of medical services with Fiat currency, an extra 10% would be used to buy back Zaya tokens from the market to burn and remove them from the total token supply.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q3 - Zaya Pathology Lab Opening
            </Typography>
            <Typography className={classes.contentBody}>
              Our state-of-the-art Zaya Pathology Lab will open its doors, offering a wide range of diagnostic services supported by advanced AI technologies and experienced professionals.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q3 - Zaya Medical Clinic Opening
            </Typography>
            <Typography className={classes.contentBody}>
              We will inaugurate the Zaya Medical Clinic, providing patients with access to high-quality medical services, preventative care, and personalized treatment plans.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q3 - Module Development for Encrypted Communication Between Doctors and AI Algorithm
            </Typography>
            <Typography className={classes.contentBody}>
              The creation of a system on Ethereum blockchain for the secure, encrypted transmission of information between doctors and the Zaya AI algorithm.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q3 - Module Development for Rewarding/Compensating Annotating Doctors with Zaya Token
            </Typography>
            <Typography className={classes.contentBody}>
              The development of a system to reward doctors who annotate their data contributions, compensating them with Zaya tokens for their work.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q4 - ISO 27001 and ISO 13485 Accreditations
            </Typography>
            <Typography className={classes.contentBody}>
              We plan to obtain ISO 27001 (Information Security Management) and ISO 13485 (Medical Devices Quality Management) accreditations, demonstrating our commitment to the highest standards of quality, safety, and data protection.
            </Typography>
          </Box>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ paddingTop: '24px'}}>
          <Typography className={classes.header}>
            2026
          </Typography>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q2 - ZayaAI Prediction Model for Ulcerative Colitis Therapy Released
            </Typography>
            <Typography className={classes.contentBody}>
              We plan to release our AI-based prediction model for ulcerative colitis therapy, helping medical professionals identify the most effective treatment strategies for patients suffering from this chronic inflammatory bowel disease.
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q3 - ZAYA Pathology Platform US Launch
            </Typography>
            <Typography className={classes.contentBody}>
              We plan to launch the ZAYA Pathology Platform in the United States, bringing our innovative suite of Al-driven diagnostic tools and services to one of the largest healthcare markets in the world.            
            </Typography>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>
              Q4 - CE-Mark Software as a Medical Device
            </Typography>
            <Typography className={classes.contentBody}>
              We aim to obtain the CE Mark certification for our software as a Medical Device (SaMD), demonstrating our commitment to meeting the highest standards of safety and performance in the European market. This certification will enable us to further expand our presence in Europe and provide our cutting-edge AI solutions to even more healthcare professionals.
            </Typography>
          </Box>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export { CustomTimeline };
