import {
  Box, Typography, Grid, Container
} from '@mui/material';

import {
  createStyles, makeStyles
} from '@mui/styles';

import Wave from '../../assets/img/telepathology_dx/wave.png';

const useStyles = makeStyles((theme: any) =>
  createStyles({
      root: {
          paddingTop: '125px',
          minHeight: '100vh',
          height: 'auto',
          width: '100%',
          [theme.breakpoints.down('md')]: {
              paddingTop: '75px'
          },
          position: 'relative',
          overflowX: 'hidden'
      },
      wave: {
          position: 'absolute',
          top: '10rem',
          width: '100%'
      },
      container: {
          marginTop: '50px',
          [theme.breakpoints.down('sm')]: {
              padding: '26px'
          },
      },
      gridPadding: {
          paddingRight: '3rem',
          [theme.breakpoints.down('sm')]: {
              paddingRight: '0'
          },
      },
      gridPaddingLeft: {
          paddingLeft: '3rem',
          [theme.breakpoints.down('sm')]: {
              paddingLeft: '0',
              marginTop: '90px !important'
          },
      },
      title: {
        fontSize: '71px !important',
        fontWeight: '500 !important',
        lineHeight: '129% !important',
        color: '#20C2E5 !important',
        [theme.breakpoints.down('sm')]: {
          fontSize: '51px !important'
        }
      },
      desc: {
          fontSize: '20px !important',
          fontWeight: '400 !important',
          marginTop: '3rem !important',
          [theme.breakpoints.down('sm')]: {
              marginTop: '20px !important',
              fontSize: '16px !important'
          },
      },
      subTitle: {
          marginTop: '3rem !important',
          fontSize: '30px !important',
          fontWeight: '400 !important',
          [theme.breakpoints.down('sm')]: {
              marginTop: '20px !important',
          },
      },
      container1: {
          display: 'flex',
          flexDirection: 'column'
      },
      imageContainer: {
        background: 'linear-gradient(90deg, rgba(3,20,32,1) 0%, rgba(62,95,117,1) 42%, rgba(32,194,229,1) 100%)',
        width: '100%',
        height: '100%',
        borderRadius: '12px',
        padding: '1.5px',
    
      },
      inner: {
        background: 'white',
        padding: '10px 10px 20px 10px',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%'
      },
      imgContainer: {
        background: 'linear-gradient(90deg, rgba(0,0,0,0) 50%, rgba(32,194,229,1) 100%)',
        borderRadius: '50%',
        padding: '3px',
        width: '120px',
        height: '120px',
        overflow: 'hidden'
      },
      img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '50%'
      },
      division: {
        display: 'flex',
        marginTop: '40px',
        width: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        },
      },
      divisionName: {
        fontWeight: '900',
        fontSize: '30px',
        color: 'black',
        width: '130px',
        transform: 'rotate(-90deg)',
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
          transform: 'rotate(0deg)',
          order: 0
        },
      },
      adjustWidth: {
        width: '70%',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      adjustWidthManagement: {
        width: '90%',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: '1fr',
          order: 1
        },
      },
      order: {
        [theme.breakpoints.down('sm')]: {
          order: 1
        },
      }
  })
);


const Team = () => {
  const classes = useStyles();
  const team = [
    {
      name: 'Fabian Van Doesburg',
      position: 'VC Advisor Oddiyana Ventures',
      img: '8.jpeg'
    },
    {
      name: 'George Vlad - Mr. Spread',
      position: 'Blockchain Division Market Analyst',
      img: '4.jpg'
    },
    {
      name: 'Dani Topala',
      position: 'Blockchain Division Financial Lead',
      img: '3.jpeg'
    },
    {
      name: 'Sergiu Vasilescu',
      position: 'Blockchain Division Legal Expert',
      img: '7.jpeg'
    },
    {
      name: 'Alexandru Carbunariu',
      position: 'Blockchain Division Go-To-Market Strategist',
      img: '6.jpeg'
    },
    {
      name: 'Petri Marin Voicu',
      position: 'Blockchain Division Tokenomics Specialist',
      img: '5.jpeg'
    },
    {
      name: 'Cristian Avram Ionut',
      position: 'Blockchain Division CTO',
      img: '2.jpeg'
    },
    {
      name: 'Adam Noaf',
      position: 'Blockchain Division CMO',
      img: '1.jpg'
    }
  ];

  const management = [
    {
      name: 'Sabina Zurac',
      position: 'CSO&Co-founder, PhD, Pathologist',
      img: '11.jpeg'
    },
    {
      name: 'Cristian Mogodici',
      position: 'Co-Founder & CEO ZAYA AI',
      img: '12.jpeg'
    },
    {
      name: 'Cristiana Pop',
      position: 'CMO',
      img: '13.jpeg'
    },
    {
      name: 'Popescu Stefan',
      position: 'Marketing Officer',
      img: '14.jpeg'
    },
  ];

  const pathologists = [
    {
      name: 'Luciana Nichita',
      position: 'Senior Pathologist',
      img: '15.jpeg'
    },
    {
      name: 'Liana Sticlaru',
      position: 'Senior Pathologist',
      img: '16.jpeg'
    },
    {
      name: 'Alexandra Cioroianu',
      position: 'Phd std, Pathologist',
      img: '17.jpeg'
    },
    {
      name: 'Mihai Busca',
      position: 'Pathologist',
      img: '18.jpeg'
    },
    {
      name: 'Mirela Cioplea',
      position: 'Phd std, Senior Pathologist',
      img: '19.jpeg'
    },
  ];

  const developers = [
    {
      name: 'Julian Dcruz',
      position: 'ML Engineer',
      img: '20.jpg'
    },
    {
      name: 'Bogdan Ceachi',
      position: 'ML Engineer',
      img: '21.jpeg'
    },
    {
      name: 'Laurentiu Motoonu',
      position: 'Full Stack Engineer',
      img: '22.jpeg'
    }
  ];

  return (
      <Box className={classes.root}>
        <img src={Wave} className={classes.wave} />
        <Container maxWidth='xl' className={classes.container}>
          <Box sx={{ mt: {xs: '0', md: '175px' }, mb: {xs: '0', md: '50px'}}}>
            <Typography className={classes.title}>
              Team Members
            </Typography>
          </Box>
          <div className={classes.division}>
            <Grid container className={classes.order}>
              {
                team.map((member, index) => {
                  return (
                    <Grid item md={6} lg={3} key={index} style={{ padding: '10px', width: '100%' }}>
                      <Box className={classes.imageContainer}>
                          <Box className={classes.inner}>
                            <Box className={classes.imgContainer}>
                              <img src={`/team/${member.img}`} alt='Team member' className={classes.img} />
                            </Box>
                            <div style={{ margin: '20px 0px'}}>
                              <Typography fontWeight={700} fontSize={'20px'} color={'black'}  textAlign={'center'}>{member.name}</Typography>
                              <Typography fontSize={'16px'} color={'black'} textAlign={'center'}>{member.position}</Typography>
                            </div>
                          </Box>
                      </Box>
                    </Grid>
                  );
                })
              }
            </Grid>
            <div className={classes.divisionName}>
              Web 3.0
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center'}}>
            <div className={`${classes.division} ${classes.adjustWidthManagement}`}>
              <Grid container className={classes.order}>
                {
                  management.map((member, index) => {
                    return (
                      <Grid item md={6} lg={3} key={index} style={{ padding: '10px', width: '100%' }}>
                        <Box className={classes.imageContainer}>
                            <Box className={classes.inner}>
                              <Box className={classes.imgContainer}>
                                <img src={`/team/${member.img}`} alt='Team member' className={classes.img} />
                              </Box>
                              <div style={{ margin: '20px 0px'}}>
                                <Typography fontWeight={700} fontSize={'20px'} color={'black'}  textAlign={'center'}>{member.name}</Typography>
                                <Typography fontSize={'16px'} color={'black'} textAlign={'center'}>{member.position}</Typography>
                              </div>
                            </Box>
                        </Box>
                      </Grid>
                    );
                  })
                }
              </Grid>
              <div className={classes.divisionName} style={{ width: '180px' }}>
                Management
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center'}}>
            <div className={classes.division}>
              <div className={classes.grid}>
                {
                  pathologists.map((member, index) => {
                    return (
                      <div key={index} style={{ padding: '10px', width: '100%' }}>
                        <Box className={classes.imageContainer}>
                            <Box className={classes.inner}>
                              <Box className={classes.imgContainer}>
                                <img src={`/team/${member.img}`} alt='Team member' className={classes.img} />
                              </Box>
                              <div style={{ margin: '20px 0px'}}>
                                <Typography fontWeight={700} fontSize={'20px'} color={'black'}  textAlign={'center'}>{member.name}</Typography>
                                <Typography fontSize={'16px'} color={'black'} textAlign={'center'}>{member.position}</Typography>
                              </div>
                            </Box>
                        </Box>
                      </div>
                    );
                  })
                }
              </div>
              <div className={classes.divisionName} style={{ width: '180px' }}>
                Pathologists
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center'}}>
            <div className={`${classes.division} ${classes.adjustWidth}`}>
              <Grid container className={classes.order}>
                {
                  developers.map((member, index) => {
                    return (
                      <Grid item md={6} lg={4} key={index} style={{ padding: '10px', width: '100%' }}>
                        <Box className={classes.imageContainer}>
                            <Box className={classes.inner}>
                              <Box className={classes.imgContainer}>
                                <img src={`/team/${member.img}`} alt='Team member' className={classes.img} />
                              </Box>
                              <div style={{ margin: '20px 0px'}}>
                                <Typography fontWeight={700} fontSize={'20px'} color={'black'}  textAlign={'center'}>{member.name}</Typography>
                                <Typography fontSize={'16px'} color={'black'} textAlign={'center'}>{member.position}</Typography>
                              </div>
                            </Box>
                        </Box>
                      </Grid>
                    );
                  })
                }
              </Grid>
              <div className={classes.divisionName} style={{ width: '150px' }}>
                Developers
              </div>
            </div>
          </div>
        </Container>
      </Box>
  );
};

export { Team };
