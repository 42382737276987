import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import styles from './styles.module.css';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    noOpacity: {
      opacity: 0
    },
    totalBox: {
      position: 'absolute',
      left: 0,
      right: 0,
      margin: 'auto',
      top: '45%',
      textAlign: 'center',
      width: '170px'
    },
    line: {
      position: 'absolute',
      textAlign: 'end'
    },
    lineLeft: {
      position: 'absolute',
      textAlign: 'start'
    },
    seedLine: {
      top: '-11%',
      right: '-10%',
      [theme.breakpoints.down('sm')]: {
        top: '-9.5%',
      }
    },
    strategicLine: {
      top: '-5%',
      right: '-19%',
    },
    privateLine: {
      top: '2%',
      right: '-20%',
    },
    publicLine: {
      top: '9%',
      right: '-34.5%'
    },
    communityLine: {
      top: '0%',
      right: '-26.5%',
    },
    marketingLine: {
      top: '22%',
      right: '-39.5%'
    },
    companyLine: {
      top: '58%',
      right: '-33.5%',
    },
    liquidityLine: {
      top: '58%',
      right: '-39.5%',
      [theme.breakpoints.down('sm')]: {
        right: '-37%',
      }
    },
    advisorsLine: {
      top: '68%',
      left: '-38%',
      [theme.breakpoints.down('sm')]: {
        top: '65%',
        left: '-35%',
      }
    },
    stakeLine: {
      top: '86%',
      right: '-15%',
      [theme.breakpoints.down('sm')]: {
        top: '82%',
      }
    },
    innovationLine: {
      top: '86%',
      left: '-25%',
      [theme.breakpoints.down('sm')]: {
        top: '78%',
        left: '-48%',
      }
    },
    teamLine: {
      top: '38%',
      left: '-50%',
      [theme.breakpoints.down('sm')]: {
        top: '35%',
        left: '-45%',
      }
    },
    treasuryLine: {
      top: '13%',
      left: '-47%',
      [theme.breakpoints.down('sm')]: {
        top: '10%',
        left: '-40%',
      }
    },
    seedSaleLine: {
      top: '-14%',
      left: '0%',
    },
    scale: {
      [theme.breakpoints.down('sm')]: {
        scale: '0.8'
      }
    }
  })
);

const TokenChart: React.FC = () => {
  const classes = useStyles();
  const [selectedChartSlice, setSelectedChartSlice] = useState(0);

  return (
    <Box sx={{ position: 'relative', padding: '30px', scale: {xs: '0.7', md: '1'}}}>
      <Box className={classes.totalBox}>
        <Typography fontSize={{xs:'10px', md: '18px'}}>Total</Typography>
        <Typography fontSize={{xs:'10px', md: '18px'}} fontWeight={700}>100,000,000</Typography>
      </Box>

      <Box className={`${classes.lineLeft} ${classes.seedSaleLine} ${selectedChartSlice === 1 ? styles.selectedSliceHovered1 : styles.selectedSlice1}`}>
        <Typography fontSize={{xs:'10px', md: '18px'}} sx={{ transform: { xs: 'translateY(7px)', sm: 'none'} }}>Seed sale <span style={{ fontWeight: 700}}>1,86%</span></Typography>
        <svg width="279" height="68" viewBox="0 0 279 68" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.lineSvg}>
          <path d="M213.115 2.60384V2.27121H213.253L213.35 2.36863L213.115 2.60384ZM3.81761 2.60384C3.81761 3.58358 3.02336 4.37782 2.04362 4.37782C1.06386 4.37782 0.269638 3.58358 0.269638 2.60384C0.269638 1.62409 1.06386 0.829848 2.04362 0.829848C3.02336 0.829848 3.81761 1.62409 3.81761 2.60384ZM277.898 67.8561L212.88 2.83904L213.35 2.36863L278.368 67.3857L277.898 67.8561ZM213.115 2.93646H2.04362V2.27121H213.115V2.93646Z" fill="#1EB1D1"/>
        </svg>
      </Box>

      <Box 
        className={`${classes.line} ${classes.seedLine} ${selectedChartSlice === 2 ? styles.selectedSliceLineHovered : styles.selectedSliceLineHoveredBack}`
      }>
        <Typography fontSize={{xs:'10px', md: '18px'}} sx={{ transform: { xs: 'translateY(85%)', sm: 'none'} }}>Private sale <span style={{ fontWeight: 700}}>2%</span></Typography>
        <svg xmlns="http://www.w3.org/2000/svg" width="231" height="54" viewBox="0 0 231 54" fill="none" className={styles.lineSvg}>
          <path d="M51.0364 2.683V2.35037H50.8986L50.8012 2.4478L51.0364 2.683ZM227.216 2.683C227.216 3.66274 228.01 4.45699 228.99 4.45699C229.969 4.45699 230.764 3.66274 230.764 2.683C230.764 1.70325 229.969 0.90901 228.99 0.90901C228.01 0.90901 227.216 1.70325 227.216 2.683ZM0.712954 53.4769L51.2716 2.9182L50.8012 2.4478L0.242555 53.0065L0.712954 53.4769ZM51.0364 3.01562H228.99V2.35037H51.0364V3.01562Z" fill="#168096"/>
        </svg>
      </Box>

      <Box className={`${classes.line} ${classes.strategicLine} ${selectedChartSlice === 3 ? styles.selectedSliceHovered3 : styles.selectedSlice3}`}>
        <Typography fontSize={{xs:'10px', md: '18px'}} sx={{ transform: { xs: 'translateY(85%)', sm: 'none'} }}>Strategic sale <span style={{ fontWeight: 700}}>4%</span></Typography>
        <svg width="232" height="53" viewBox="0 0 232 53" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.lineSvg}>
          <path d="M51.4625 1.77599V1.44337H51.3247L51.2273 1.54079L51.4625 1.77599ZM227.642 1.77599C227.642 2.75574 228.436 3.54998 229.416 3.54998C230.395 3.54998 231.19 2.75574 231.19 1.77599C231.19 0.796243 230.395 0.00200164 229.416 0.00200164C228.436 0.00200164 227.642 0.796243 227.642 1.77599ZM1.13901 52.5699L51.6977 2.01119L51.2273 1.54079L0.668609 52.0994L1.13901 52.5699ZM51.4625 2.10861H229.416V1.44337H51.4625V2.10861Z" fill="#028ba8"/>
        </svg>
      </Box>

      <Box 
        className={`${classes.line} ${classes.publicLine} ${selectedChartSlice === 2 ? styles.selectedSliceLineHovered : styles.selectedSliceLineHoveredBack}`
      }>
        <Typography fontSize={{xs:'10px', md: '18px'}} sx={{ transform: { xs: 'translateY(85%)', sm: 'none'} }}>Public sale <span style={{ fontWeight: 700}}>6%</span></Typography>
        <svg xmlns="http://www.w3.org/2000/svg" width="231" height="54" viewBox="0 0 231 54" fill="none" className={styles.lineSvg}>
          <path d="M51.0364 2.683V2.35037H50.8986L50.8012 2.4478L51.0364 2.683ZM227.216 2.683C227.216 3.66274 228.01 4.45699 228.99 4.45699C229.969 4.45699 230.764 3.66274 230.764 2.683C230.764 1.70325 229.969 0.90901 228.99 0.90901C228.01 0.90901 227.216 1.70325 227.216 2.683ZM0.712954 53.4769L51.2716 2.9182L50.8012 2.4478L0.242555 53.0065L0.712954 53.4769ZM51.0364 3.01562H228.99V2.35037H51.0364V3.01562Z" fill="#1fc2e5"/>
        </svg>
      </Box>

      <Box 
        className={`${classes.line} ${classes.marketingLine} ${selectedChartSlice === 2 ? styles.selectedSliceLineHovered : styles.selectedSliceLineHoveredBack}`
      }>
        <Typography fontSize={{xs:'10px', md: '18px'}} sx={{ transform: { xs: 'translateY(85%)', sm: 'none'} }}>Marketing <span style={{ fontWeight: 700}}>5%</span></Typography>
        <svg xmlns="http://www.w3.org/2000/svg" width="231" height="54" viewBox="0 0 231 54" fill="none" className={styles.lineSvg}>
          <path d="M51.0364 2.683V2.35037H50.8986L50.8012 2.4478L51.0364 2.683ZM227.216 2.683C227.216 3.66274 228.01 4.45699 228.99 4.45699C229.969 4.45699 230.764 3.66274 230.764 2.683C230.764 1.70325 229.969 0.90901 228.99 0.90901C228.01 0.90901 227.216 1.70325 227.216 2.683ZM0.712954 53.4769L51.2716 2.9182L50.8012 2.4478L0.242555 53.0065L0.712954 53.4769ZM51.0364 3.01562H228.99V2.35037H51.0364V3.01562Z" fill="#028ba8"/>
        </svg>
      </Box>

      <Box className={`${classes.line} ${classes.liquidityLine} ${selectedChartSlice === 5 ? styles.selectedSliceHovered5 : styles.selectedSlice5}`}>
        <Typography fontSize={{xs:'10px', md: '18px'}} sx={{ transform: { xs: 'translateY(250%)', sm: 'translateY(170%)'} }}>Liquidity <span style={{ fontWeight: 700}}>12%</span></Typography>
        <svg width="231" height="54" viewBox="0 0 231 54" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.lineSvg}>
          <path d="M51.0874 51.5176V51.8503H50.9497L50.8522 51.7528L51.0874 51.5176ZM227.267 51.5176C227.267 50.5379 228.061 49.7437 229.041 49.7437C230.02 49.7437 230.815 50.5379 230.815 51.5176C230.815 52.4974 230.02 53.2916 229.041 53.2916C228.061 53.2916 227.267 52.4974 227.267 51.5176ZM0.763978 0.723778L51.3226 51.2824L50.8522 51.7528L0.293578 1.19418L0.763978 0.723778ZM51.0874 51.185H229.041V51.8503H51.0874V51.185Z" fill="#6BD2E9"/>
        </svg>
      </Box>

      <Box className={`${classes.line} ${classes.stakeLine} ${selectedChartSlice === 6 ? styles.selectedSliceHovered6 : styles.selectedSlice6}`}>
        <Typography fontSize={{xs:'10px', md: '18px'}} sx={{ transform: { xs: 'translateY(35px)', sm: 'translateY(170%)'} }}>Stake Rewards <span style={{ fontWeight: 700}}>18%</span></Typography>
        <svg width="231" height="54" viewBox="0 0 231 54" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.lineSvg}>
          <path d="M51.0874 51.5176V51.8503H50.9497L50.8522 51.7528L51.0874 51.5176ZM227.267 51.5176C227.267 50.5379 228.061 49.7437 229.041 49.7437C230.02 49.7437 230.815 50.5379 230.815 51.5176C230.815 52.4974 230.02 53.2916 229.041 53.2916C228.061 53.2916 227.267 52.4974 227.267 51.5176ZM0.763978 0.723778L51.3226 51.2824L50.8522 51.7528L0.293578 1.19418L0.763978 0.723778ZM51.0874 51.185H229.041V51.8503H51.0874V51.185Z" fill="#1fc2e5"/>
        </svg>
      </Box>

      <Box className={`${classes.line} ${classes.communityLine} ${selectedChartSlice === 7 ? styles.selectedSliceHovered7 : styles.selectedSlice7}`}>
        <Typography fontSize={{xs:'10px', md: '18px'}} sx={{ transform: { xs: 'translateY(90%)', sm: 'translateY(0%)'}}}>Community <span style={{ fontWeight: 700}}>3%</span></Typography>
        <svg width="232" height="53" viewBox="0 0 232 53" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.lineSvg}>
          <path d="M51.4625 1.77599V1.44337H51.3247L51.2273 1.54079L51.4625 1.77599ZM227.642 1.77599C227.642 2.75574 228.436 3.54998 229.416 3.54998C230.395 3.54998 231.19 2.75574 231.19 1.77599C231.19 0.796243 230.395 0.00200164 229.416 0.00200164C228.436 0.00200164 227.642 0.796243 227.642 1.77599ZM1.13901 52.5699L51.6977 2.01119L51.2273 1.54079L0.668609 52.0994L1.13901 52.5699ZM51.4625 2.10861H229.416V1.44337H51.4625V2.10861Z" fill="#6BD2E9"/>
        </svg>
      </Box>

      <Box className={`${classes.lineLeft} ${classes.innovationLine} ${selectedChartSlice === 8 ? styles.selectedSliceHovered8 : styles.selectedSlice8}`}>
        <Typography fontSize={{xs:'10px', md: '18px'}} sx={{ transform: { xs: 'translateY(55px)', sm: 'translateY(210%)'}, ml: { xs: '30px', sm: '0'} }}>Innovation&Research <span style={{ fontWeight: 700}}>10%</span></Typography>
        <svg width="270" height="68" viewBox="0 0 270 68" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.scale}>
          <path d="M218.742 65.7656V66.0982H218.88L218.977 66.0008L218.742 65.7656ZM3.77399 65.7656C3.77399 64.7859 2.97975 63.9916 2 63.9916C1.02025 63.9916 0.226012 64.7859 0.226012 65.7656C0.226012 66.7454 1.02025 67.5396 2 67.5396C2.97975 67.5396 3.77399 66.7454 3.77399 65.7656ZM283.525 0.513326L218.507 65.5304L218.977 66.0008L283.995 0.983729L283.525 0.513326ZM218.742 65.433H2V66.0982H218.742V65.433Z" fill="#038BA8"/>
        </svg>
      </Box>

      <Box className={`${classes.lineLeft} ${classes.advisorsLine} ${selectedChartSlice === 9 ? styles.selectedSliceHovered9 : styles.selectedSlice9}`}>
        <Typography fontSize={{xs:'10px', md: '18px'}} sx={{ transform: { xs: 'translateY(50px)', sm: 'translateY(210%)'} }}>Advisors <span style={{ fontWeight: 700}}>7%</span></Typography>
        <svg width="245" height="68" viewBox="0 0 245 68" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.lineSvg}>
          <path d="M179.741 65.7651V66.0977H179.879L179.977 66.0003L179.741 65.7651ZM3.56229 65.7651C3.56229 64.7853 2.76805 63.9911 1.7883 63.9911C0.808563 63.9911 0.0143127 64.7853 0.0143127 65.7651C0.0143127 66.7448 0.808563 67.5391 1.7883 67.5391C2.76805 67.5391 3.56229 66.7448 3.56229 65.7651ZM244.524 0.512772L179.506 65.5299L179.977 66.0003L244.995 0.98317L244.524 0.512772ZM179.741 65.4325H1.7883V66.0977H179.741V65.4325Z" fill="#00D2FF"/>
        </svg>
      </Box>

      <Box className={`${classes.lineLeft} ${classes.teamLine} ${selectedChartSlice === 1 ? styles.selectedSliceHovered1 : styles.selectedSlice1}`}>
        <Typography fontSize={{xs:'10px', md: '18px'}} sx={{ transform: { xs: 'translateY(12px)', sm: 'none'}, ml: { xs: '20px', sm: '0'} }}>Team <span style={{ fontWeight: 700}}>5.64%</span></Typography>
        <svg width="279" height="68" viewBox="0 0 279 68" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${styles.lineSvg} ${classes.scale}`}>
          <path d="M213.115 2.60384V2.27121H213.253L213.35 2.36863L213.115 2.60384ZM3.81761 2.60384C3.81761 3.58358 3.02336 4.37782 2.04362 4.37782C1.06386 4.37782 0.269638 3.58358 0.269638 2.60384C0.269638 1.62409 1.06386 0.829848 2.04362 0.829848C3.02336 0.829848 3.81761 1.62409 3.81761 2.60384ZM277.898 67.8561L212.88 2.83904L213.35 2.36863L278.368 67.3857L277.898 67.8561ZM213.115 2.93646H2.04362V2.27121H213.115V2.93646Z" fill="#51abbd"/>
        </svg>
      </Box>

      <Box className={`${classes.lineLeft} ${classes.treasuryLine} ${selectedChartSlice === 1 ? styles.selectedSliceHovered1 : styles.selectedSlice1}`}>
        <Typography fontSize={{xs:'10px', md: '18px'}} sx={{ transform: { xs: 'translateY(12px)', sm: 'none'}, ml: { xs: '20px', sm: '0'} }}>Treasury <span style={{ fontWeight: 700}}>25,5%</span></Typography>
        <svg width="279" height="68" viewBox="0 0 279 68" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${styles.lineSvg} ${classes.scale}`}>
          <path d="M213.115 2.60384V2.27121H213.253L213.35 2.36863L213.115 2.60384ZM3.81761 2.60384C3.81761 3.58358 3.02336 4.37782 2.04362 4.37782C1.06386 4.37782 0.269638 3.58358 0.269638 2.60384C0.269638 1.62409 1.06386 0.829848 2.04362 0.829848C3.02336 0.829848 3.81761 1.62409 3.81761 2.60384ZM277.898 67.8561L212.88 2.83904L213.35 2.36863L278.368 67.3857L277.898 67.8561ZM213.115 2.93646H2.04362V2.27121H213.115V2.93646Z" fill="#1EB1D1"/>
        </svg>
      </Box>

      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox="0 0 500 500"
        style={{ overflow: 'visible' }}
      >
        <mask id="path-1-inside-1_568_2" fill="white">
          <path d="M270.244 0.939017C279.847 1.62964 289.402 2.8723 298.862 4.66085L289.539 53.9766C281.97 52.5457 274.326 51.5516 266.644 50.9991L270.244 0.939017Z"/>
        </mask>

        <path
          onMouseOver={() => setSelectedChartSlice(2)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 2 ? classes.noOpacity : styles.selectedSlice}
          d='M270.244 0.939017C279.847 1.62964 289.402 2.8723 298.862 4.66085L289.539 53.9766C281.97 52.5457 274.326 51.5516 266.644 50.9991L270.244 0.939017Z'
          fill='#2EE6FF'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-1-inside-1_3_2)'
        />

        <path
          onMouseOver={() => setSelectedChartSlice(2)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 2 ? styles.selectedSliceHovered : classes.noOpacity}
          d='M270.244 0.939017C279.847 1.62964 289.402 2.8723 298.862 4.66085L289.539 53.9766C281.97 52.5457 274.326 51.5516 266.644 50.9991L270.244 0.939017Z'
          fill='#2EE6FF'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-1-inside-1_3_2)'
        />

        <mask id="path-2-inside-2_568_2" fill="white">
          <path d="M195.956 496.021C150.026 485.647 107.942 462.559 74.5126 429.398L109.859 393.766C136.602 420.296 170.27 438.766 207.014 447.065L195.956 496.021Z"/>
        </mask>

        <path
          onMouseOver={() => setSelectedChartSlice(1)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 1 ? classes.noOpacity : styles.selectedSlice1}
          d='M195.956 496.021C150.026 485.647 107.942 462.559 74.5126 429.398L109.859 393.766C136.602 420.296 170.27 438.766 207.014 447.065L195.956 496.021Z'
          fill='#1EB1D1'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-2-inside-2_3_2)'
        />

        <path
          onMouseOver={() => setSelectedChartSlice(1)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 1 ? styles.selectedSliceHovered1 : classes.noOpacity}
          d='M195.956 496.021C150.026 485.647 107.942 462.559 74.5126 429.398L109.859 393.766C136.602 420.296 170.27 438.766 207.014 447.065L195.956 496.021Z'
          fill='#1EB1D1'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-2-inside-2_3_2)'
        />

        <mask id="path-3-inside-3_568_2" fill="white">
          <path d="M73.0948 427.98C46.9584 401.634 26.9904 369.82 14.6292 334.829L61.9526 318.111C71.8415 346.104 87.8159 371.556 108.725 392.632L73.0948 427.98Z"/>
        </mask>

        <path
          onMouseOver={() => setSelectedChartSlice(3)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 3 ? classes.noOpacity : styles.selectedSlice3}
          d='M73.0948 427.98C46.9584 401.634 26.9904 369.82 14.6292 334.829L61.9526 318.111C71.8415 346.104 87.8159 371.556 108.725 392.632L73.0948 427.98Z'
          fill='#6BD2E9'
          stroke='white'
          strokeWidth='0.93279'
          mask='url(#path-3-inside-3_3_2)'
        />

        <path
          onMouseOver={() => setSelectedChartSlice(3)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 3 ? styles.selectedSliceHovered3 : classes.noOpacity}
          d='M73.0948 427.98C46.9584 401.634 26.9904 369.82 14.6292 334.829L61.9526 318.111C71.8415 346.104 87.8159 371.556 108.725 392.632L73.0948 427.98Z'
          fill='#6BD2E9'
          stroke='white'
          strokeWidth='0.93279'
          mask='url(#path-3-inside-3_3_2)'
        />
        <mask id="path-4-inside-4_568_2" fill="white">
          <path d="M14.0737 333.239C4.46131 305.437 -0.187643 276.16 0.339118 246.747L50.5205 247.646C50.0991 271.176 53.8182 294.597 61.5081 316.84L14.0737 333.239Z"/>
        </mask>
        <path
          onMouseOver={() => setSelectedChartSlice(4)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 4 ? classes.noOpacity : styles.selectedSlice4}
          d='M14.0737 333.239C4.46131 305.437 -0.187643 276.16 0.339118 246.747L50.5205 247.646C50.0991 271.176 53.8182 294.597 61.5081 316.84L14.0737 333.239Z'
          fill='#51ABBE'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-4-inside-4_3_2)'
        />

        <path
          onMouseOver={() => setSelectedChartSlice(4)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 4 ? styles.selectedSliceHovered4 : classes.noOpacity}
          d='M14.0737 333.239C4.46131 305.437 -0.187643 276.16 0.339118 246.747L50.5205 247.646C50.0991 271.176 53.8182 294.597 61.5081 316.84L14.0737 333.239Z'
          fill='#51ABBE'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-4-inside-4_3_2)'
        />
        <mask id="path-5-inside-5_568_2" fill="white">
          <path d="M0.402206 244.041C1.37058 210.305 9.13384 177.112 23.2275 146.446C37.3212 115.78 57.4558 88.2723 82.4266 65.5681C107.397 42.8639 136.691 25.4299 168.556 14.3091C200.42 3.18823 234.201 -1.39089 267.876 0.845639L264.55 50.9247C237.61 49.1355 210.585 52.7988 185.094 61.6954C159.602 70.5921 136.167 84.5393 116.19 102.703C96.2138 120.866 80.1062 142.872 68.8312 167.405C57.5562 191.937 51.3456 218.492 50.5709 245.481L0.402206 244.041Z"/>
        </mask>
        <path
          onMouseOver={() => setSelectedChartSlice(5)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 5 ? classes.noOpacity : styles.selectedSlice5}
          d='M0.402206 244.041C1.37058 210.305 9.13384 177.112 23.2275 146.446C37.3212 115.78 57.4558 88.2723 82.4266 65.5681C107.397 42.8639 136.691 25.4299 168.556 14.3091C200.42 3.18823 234.201 -1.39089 267.876 0.845639L264.55 50.9247C237.61 49.1355 210.585 52.7988 185.094 61.6954C159.602 70.5921 136.167 84.5393 116.19 102.703C96.2138 120.866 80.1062 142.872 68.8312 167.405C57.5562 191.937 51.3456 218.492 50.5709 245.481L0.402206 244.041Z'
          fill='#1691AC'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-5-inside-5_3_2)'
        />

        <path
          onMouseOver={() => setSelectedChartSlice(5)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 5 ? styles.selectedSliceHovered5 : classes.noOpacity}
          d='M0.402206 244.041C1.37058 210.305 9.13384 177.112 23.2275 146.446C37.3212 115.78 57.4558 88.2723 82.4266 65.5681C107.397 42.8639 136.691 25.4299 168.556 14.3091C200.42 3.18823 234.201 -1.39089 267.876 0.845639L264.55 50.9247C237.61 49.1355 210.585 52.7988 185.094 61.6954C159.602 70.5921 136.167 84.5393 116.19 102.703C96.2138 120.866 80.1062 142.872 68.8312 167.405C57.5562 191.937 51.3456 218.492 50.5709 245.481L0.402206 244.041Z'
          fill='#1691AC'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-5-inside-5_3_2)'
        />
        <mask id="path-6-inside-6_568_2" fill="white">
          <path d="M300.957 5.0693C311.237 7.10349 321.38 9.77958 331.325 13.0819L315.509 60.7141C307.553 58.0723 299.439 55.9314 291.215 54.304L300.957 5.0693Z"/>
        </mask>
        <path
          onMouseOver={() => setSelectedChartSlice(6)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 6 ? classes.noOpacity : styles.selectedSlice}
          d='M300.957 5.0693C311.237 7.10349 321.38 9.77958 331.325 13.0819L315.509 60.7141C307.553 58.0723 299.439 55.9314 291.215 54.304L300.957 5.0693Z'
          fill='#168097'
          stroke='white'
          strokeWidth='0.93279'
          mask='url(#path-6-inside-6_3_2)'
        />

        <path
          onMouseOver={() => setSelectedChartSlice(6)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 6 ? styles.selectedSliceHovered : classes.noOpacity}
          d='M300.957 5.0693C311.237 7.10349 321.38 9.77958 331.325 13.0819L315.509 60.7141C307.553 58.0723 299.439 55.9314 291.215 54.304L300.957 5.0693Z'
          fill='#168097'
          stroke='white'
          strokeWidth='0.93279'
          mask='url(#path-6-inside-6_3_2)'
        />
        <mask id="path-7-inside-7_568_2" fill="white">
          <path d="M332.456 13.7961C352.102 20.5156 370.839 29.6474 388.235 40.9822L360.837 83.0336C346.92 73.9658 331.931 66.6603 316.214 61.2847L332.456 13.7961Z"/>
        </mask>
        <path
          onMouseOver={() => setSelectedChartSlice(7)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 7 ? classes.noOpacity : styles.selectedSlice7}
          d='M332.456 13.7961C352.102 20.5156 370.839 29.6474 388.235 40.9822L360.837 83.0336C346.92 73.9658 331.931 66.6603 316.214 61.2847L332.456 13.7961Z'
          fill='#038BA8'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-7-inside-7_3_2)'
        />

        <path
          onMouseOver={() => setSelectedChartSlice(7)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 7 ? styles.selectedSliceHovered7 : classes.noOpacity}
          d='M332.456 13.7961C352.102 20.5156 370.839 29.6474 388.235 40.9822L360.837 83.0336C346.92 73.9658 331.931 66.6603 316.214 61.2847L332.456 13.7961Z'
          fill='#038BA8'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-7-inside-7_3_2)'
        />
        <mask id="path-8-inside-8_568_2" fill="white">
          <path d="M389.09 40.8903C402.291 49.478 414.653 59.2892 426.015 70.194L391.26 106.403C382.171 97.6794 372.281 89.8305 361.721 82.9604L389.09 40.8903Z"/>
        </mask>
        <path
          onMouseOver={() => setSelectedChartSlice(8)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 8 ? classes.noOpacity : styles.selectedSlice8}
          d='M389.09 40.8903C402.291 49.478 414.653 59.2892 426.015 70.194L391.26 106.403C382.171 97.6794 372.281 89.8305 361.721 82.9604L389.09 40.8903Z'
          fill='#00D2FF'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-8-inside-8_3_2)'
        />

        <path
          onMouseOver={() => setSelectedChartSlice(8)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 8 ? styles.selectedSliceHovered8 : classes.noOpacity}
          d='M389.09 40.8903C402.291 49.478 414.653 59.2892 426.015 70.194L391.26 106.403C382.171 97.6794 372.281 89.8305 361.721 82.9604L389.09 40.8903Z'
          fill='#00D2FF'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-8-inside-8_3_2)'
        />
        <mask id="path-9-inside-9_568_2" fill="white">
          <path d="M427.078 71.2197C449.679 93.1685 467.946 119.177 480.922 147.886L435.188 168.558C424.806 145.591 410.193 124.784 392.112 107.225L427.078 71.2197Z"/>
        </mask>
        <path
          onMouseOver={() => setSelectedChartSlice(9)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 9 ? classes.noOpacity : styles.selectedSlice9}
          d='M427.078 71.2197C449.679 93.1685 467.946 119.177 480.922 147.886L435.188 168.558C424.806 145.591 410.193 124.784 392.112 107.225L427.078 71.2197Z'
          fill='#20C2E5'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-9-inside-9_3_2)'
        />
         <path
          onMouseOver={() => setSelectedChartSlice(9)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 9 ? styles.selectedSliceHovered9 : classes.noOpacity}
          d='M427.078 71.2197C449.679 93.1685 467.946 119.177 480.922 147.886L435.188 168.558C424.806 145.591 410.193 124.784 392.112 107.225L427.078 71.2197Z'
          fill='#20C2E5'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-9-inside-9_3_2)'
        />

        <mask id="path-10-inside-10_568_2" fill="white">
          <path d="M481.37 148.887C492.153 173.023 499.054 198.71 501.819 224.999L451.905 230.249C449.693 209.217 444.172 188.667 435.546 169.359L481.37 148.887Z"/>
        </mask>

        <path
          onMouseOver={() => setSelectedChartSlice(10)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 10 ? classes.noOpacity : styles.selectedSlice10}
          d='M481.37 148.887C492.153 173.023 499.054 198.71 501.819 224.999L451.905 230.249C449.693 209.217 444.172 188.667 435.546 169.359L481.37 148.887Z'
          fill='#038BA8'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-10-inside-10_3_2)'
        />
         <path
          onMouseOver={() => setSelectedChartSlice(10)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 10 ? styles.selectedSliceHovered10 : classes.noOpacity}
          d='M481.37 148.887C492.153 173.023 499.054 198.71 501.819 224.999L451.905 230.249C449.693 209.217 444.172 188.667 435.546 169.359L481.37 148.887Z'
          fill='#038BA8'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-10-inside-10_3_2)'
        />

        <mask id="path-11-inside-11_568_2" fill="white">
          <path d="M501.995 226.759C508.228 290.331 489.984 353.887 450.982 404.474L411.235 373.828C442.437 333.359 457.032 282.514 452.045 231.657L501.995 226.759Z"/>
        </mask>

        <path
          onMouseOver={() => setSelectedChartSlice(11)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 11 ? classes.noOpacity : styles.selectedSlice11}
          d='M501.995 226.759C508.228 290.331 489.984 353.887 450.982 404.474L411.235 373.828C442.437 333.359 457.032 282.514 452.045 231.657L501.995 226.759Z'
          fill='#00D2FF'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-11-inside-11_3_2)'
        />
         <path
          onMouseOver={() => setSelectedChartSlice(11)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 11 ? styles.selectedSliceHovered11 : classes.noOpacity}
          d='M501.995 226.759C508.228 290.331 489.984 353.887 450.982 404.474L411.235 373.828C442.437 333.359 457.032 282.514 452.045 231.657L501.995 226.759Z'
          fill='#00D2FF'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-11-inside-11_3_2)'
        />

        <mask id="path-12-inside-12_568_2" fill="white">
          <path d="M449.952 405.804C420.801 443.093 381.698 471.377 337.159 487.392C292.619 503.407 244.459 506.499 198.237 496.311L209.04 447.298C246.017 455.448 284.545 452.974 320.177 440.163C355.809 427.351 387.09 404.724 410.411 374.893L449.952 405.804Z"/>
        </mask>

        <path
          onMouseOver={() => setSelectedChartSlice(12)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 12 ? classes.noOpacity : styles.selectedSlice12}
          d='M449.952 405.804C420.801 443.093 381.698 471.377 337.159 487.392C292.619 503.407 244.459 506.499 198.237 496.311L209.04 447.298C246.017 455.448 284.545 452.974 320.177 440.163C355.809 427.351 387.09 404.724 410.411 374.893L449.952 405.804Z'
          fill='#20C2E5'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-12-inside-12_3_2)'
        />
         <path
          onMouseOver={() => setSelectedChartSlice(12)}
          onMouseLeave={() => setSelectedChartSlice(0)}
          className={selectedChartSlice === 12 ? styles.selectedSliceHovered12 : classes.noOpacity}
          d='M449.952 405.804C420.801 443.093 381.698 471.377 337.159 487.392C292.619 503.407 244.459 506.499 198.237 496.311L209.04 447.298C246.017 455.448 284.545 452.974 320.177 440.163C355.809 427.351 387.09 404.724 410.411 374.893L449.952 405.804Z'
          fill='#20C2E5'
          stroke='white'
          strokeWidth='0.932789'
          mask='url(#path-12-inside-12_3_2)'
        />
      </svg>
    </Box>
  );
};

export { TokenChart };
