import { AbiRegistry, Account, Address, ITokenTransfer, ITransactionValue, ResultsParser, SmartContract, Transaction, TransactionWatcher } from '@multiversx/sdk-core';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { SendTransactionsPropsType } from '@multiversx/sdk-dapp/types';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers';
import { BigNumber } from 'bignumber.js';
import { StakingContractAbi } from './stakingAbi';
import { VestingContractAbi } from './vestingAbi';

export const enum ZayaContracts {
  StakingContract, 
  VestingContract
}

/**
 * Load the contract's ABI
 * @returns The ABI (AbiRegistry)
 */
export async function loadAbi (params : {contractType: ZayaContracts}): Promise<AbiRegistry> {
  const { contractType } = params;

  return contractType === ZayaContracts.VestingContract ? AbiRegistry.create(VestingContractAbi) : AbiRegistry.create(StakingContractAbi);
}

/**
 * Query the specified contract's method
 * @param params.contract The SmartContract object
 * @param params.method The method of the contract that should be queried
 * @param params.args The arguments that will be used to call the smart contract
 * @param params.networkProviderUrl The URL of the network provider
 * @returns
 */
export async function queryContract (params: {
  contract: SmartContract,
  method: string,
  args?: any[] | undefined,
  networkProviderUrl: string,
}) {
  const { contract, method, args, networkProviderUrl } = params;
  const networkProvider = new ApiNetworkProvider(networkProviderUrl);

  const interaction = contract.methods[method](args);

  const tx = interaction.check().buildQuery();

  const queryResponse = await networkProvider.queryContract(tx);

  const typedBundle = new ResultsParser().parseQueryResponse(queryResponse, interaction.getEndpoint());

  return typedBundle;
}

/**
 * Create and execute a contract interaction.
 * The transaction results will be printed to the console and returned by the function
 * @param params.contract The SmartContract object
 * @param params.method The method of the contract that should be called
 * @param params.args The arguments that will be used to call the smart contract
 * @param callerPemPath The relative path to the PEM of the caller
 * @param callerAddress The address of the caller
 * @param gasLimit The gas limit used for the call
 * @param networkProviderUrl The url of the NetworkProvider used
 * @param chainId The ID of the chain this call is done on
 * @returns The TypedOutcomeBundle
 */
export async function interactWithContract (params: {
  contract: SmartContract,
  networkProviderUrl: string,
  callerAddress: string,
  gasLimit: number,
  chainId: string,
  method: string,
  value? : ITransactionValue,
  multiTransfer?: ITokenTransfer[],
  args?: any[] | undefined
}) {
  const { contract, gasLimit, chainId, multiTransfer, args, value, method, networkProviderUrl, callerAddress } = params;
  const networkProvider = new ApiNetworkProvider(networkProviderUrl);

  const interaction = contract.methods[method](args);

  const caller = await accountFromAddress({
    networkProviderUrl,
    address: callerAddress
  });

  let tx = interaction
    .withSender(caller.address)
    .withGasLimit(gasLimit)
    .withChainID(chainId);

  if (multiTransfer && multiTransfer.length > 0) {
    tx = tx.withMultiESDTNFTTransfer(multiTransfer);
  } else if (value) {
    tx = tx.withValue(value);
  }

  const transaction = tx.buildTransaction();

  const sendableTx = await signTransaction({
    transaction
  });

  const { sessionId: sid } = await sendTransactions(sendableTx);
  console.log(`Transaction sent with hash: ${sid}`);

  const txNetwork = await new TransactionWatcher(networkProvider).awaitCompleted(transaction);

  const typedBundle = new ResultsParser().parseOutcome(txNetwork, interaction.getEndpoint());

  console.log({ typedBundle });

  return typedBundle;
}

/**
 * Sign a transaction with the given signer
 * @param params.transaction The transaction to sign
 * @param params.networkProviderUrl The network provider URL
 * @param params.signerAddress The address of the signer
 * @param params.signerPemPath The path to the PEM file of the signer
 * @returns The signed transaction
 */
export async function signTransaction (params: {
    transaction: Transaction,
}): Promise<SendTransactionsPropsType> {
  const { transaction } = params;

  // TODO - Rewrite for SDK-DAPP
  // const signer = await signer
  const sendTxProps: SendTransactionsPropsType = {
    transactions: [transaction as any],
    signWithoutSending: false,
    redirectAfterSign: false,
    transactionsDisplayInfo: {
      processingMessage: 'Processing transaction',
      errorMessage: 'An error occurred while processing the transaction',
      successMessage: 'Blockchain transaction successfully sent'
    }
  };

  return sendTxProps;
}

/**
 * Get the account from the network, associated with the given address
 * @param params.networkProviderUrl The network provider URL
 * @param params.address The address of the account
 * @returns The account associated with the given address
 */
export async function accountFromAddress (params: { networkProviderUrl: string; address: string; }) {
  const { networkProviderUrl, address } = params;
  const networkProvider = new ApiNetworkProvider(networkProviderUrl);
  const deployerAddress = new Address(address);

  const deployer = new Account(deployerAddress);
  const deployerOnNetwork = await networkProvider.getAccount(deployerAddress);
  deployer.update(deployerOnNetwork);
  return deployer;
}


export function floatToBigNumber (n: number, decimals = 18) {
  const value = new BigNumber(n);

  return value.multipliedBy(new BigNumber(10).exponentiatedBy(decimals));
}

export function bigNumberToFloat (n: BigNumber, decimals = 18) {
  return n.dividedBy(new BigNumber(10).exponentiatedBy(decimals)).toNumber();
}

export function parseScTokenAmount (params: {value: bigint}): number {
  const { value } = params;
  return bigNumberToFloat(BigNumber(value.toString()), 18);
}