import { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { StakingMethods } from 'helpers/smartcontract/stakingMethods';
import { bigNumberToFloat } from 'helpers/smartcontract/utils';
import { Switch } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    section: {
      marginTop: '100px', 
      width: '100%', 
      borderRadius: '12.09px 12.09px 0px 0px', 
      overflowY: 'hidden',
      overflowX: 'scroll'
    },
    fullButton: {
      borderRadius: '85px !important',
      background: '#20C2E5 !important',
      display: 'flex',
      width: '105px',
      height: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#1B2B38 !important',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      marginRight: '5px !important',
      border: 'none !important',
      [theme.breakpoints.down('sm')]: {
        width: '85px'
      }
    },
    fullButtonDisabled: {
      borderRadius: '85px !important',
      background: 'rgba(32 194 229, 0.5) !important',
      display: 'flex',
      width: '105px',
      height: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#1B2B38 !important',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      marginRight: '5px !important',
      border: 'none !important',
      [theme.breakpoints.down('sm')]: {
        width: '85px'
      }
    },
    tableHead: {
      borderRadius: '12.09px 12.09px 0px 0px',
      background: '#E9E9E9',
      padding: '20px',
      width: '100%'
    },
    tableRow: {
      color: '#16374D',
      fontSize: '16px',
      fontWeight: 300,
      border: '4px solid #E7F0FF',
      background: 'white'
    },
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      padding: '1.75rem'
    },
    select: {
      width: '100px !important',
    },
    menuItem: {
      color: 'black !important',
      background: 'white !important'
    }
  })
);

export default function StakingTable({
  stakes,
  setRefreshStakes,
  config,
  address
}: {
  stakes: any[];
  setRefreshStakes: any;
  config: any;
  address: string;
}) {
  const classes = useStyles();
  const [canClaim, setCanClaim] = useState<{ [index: number]: boolean }>({});
  const [count, setCount] = useState<{[index: number]: string }>({});
  const [pending, setPending] = useState(true);
  const [stakeTypes, setStakeTypes] = useState<any>([]);

  const canClaimStake = () => {
    const stakesNumber = stakes.length;
    for (let i = 1; i <= stakesNumber; i++) {
      StakingMethods.getCanClaim({
        config,
        stakeId: i,
        user: address
      }).then((response) => {
        setCanClaim((prev) => ({ ...prev, [i]: response }));
      });
    }
  };

  const header = {
    initial_amount: {
      name: 'Initial amount',
      slug: 'initial_amount',
      type: 'bigint'
    },
    current_amount: {
      name: 'Current amount',
      slug: 'current_amount',
      type: 'bigint'
    },
    staked_at: {
      name: 'Staked at',
      slug: 'staked_at',
      type: 'date'
    },
    claimable_at: {
      name: 'Claimable at',
      slug: 'claimable_at',
      type: 'date'
    },
    will_claim: {
      name: 'Will claim',
      slug: 'will_claim',
      type: 'bigint'
    },
    auto_restake: {
      name: 'Autorestake',
      slug: 'auto_restake',
      type: 'boolean'
    },
    restake_count: {
      name: 'Autorestake period',
      slug: 'restake_count',
      type: 'dropdown',
      optionsSmall: [
        '1', '2', '3', '4'
      ],
      optionsLong: [
        '1', '2'
      ]
    },
    claim: {
      name: 'Claim',
      slug: 'claim',
      type: 'action',
      disabled: 'claimed',
      triggeredAction: (index: number) => handleClaim(index)
    },
    restake: {
      name: 'Restake',
      slug: 'restake',
      type: 'action',
      disabled: 'claimed',
      triggeredAction: (index: number) => handleRestake(index)
    }
  } as const;

  const handleChange = (event: SelectChangeEvent, index: number) => {
    const temp = {...count, [index]: event.target.value};
    setCount(temp);
  };

  const handleClaim = async (index: number) => {
    await StakingMethods.claim({ config, stakeId: index });
    setRefreshStakes(true);
  };

  const handleRestake = async (index: number) => {
    await StakingMethods.restake({ config, stakeId: index });
    setRefreshStakes(true);
  };

  const handleAutoRestake = async (index: number) => {
    const hasAutoRestakeEnabled = stakes[index - 1].stake.auto_restake;
    hasAutoRestakeEnabled
      ? await StakingMethods.disableAutoRestake({ config, stakeId: index })
      : await StakingMethods.enableAutoRestake({ config, stakeId: index, restake_count: parseInt(count[index]) });
    setRefreshStakes(true);
  };

  const getCurrentTimestamp = async () => {
    const response = await StakingMethods.getTimestamp({config});
  };

  const getStakeTypes = async () => {
    const response = await StakingMethods.getStakeTypes({config});
    setStakeTypes(response);
  };

  useEffect(() => {
    const temp: {[index: number]: string } = {};
    stakes.forEach((item, index) => {
      temp[index + 1] = item.stake.restake_count || 1;
    });

    setCount(temp);
    setPending(false);
  }, [stakeTypes]);


  const roundDown = (number: number, decimalPlaces: number) => {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.floor(number * multiplier) / multiplier;
  };

  useEffect(() => {
    canClaimStake();
    getCurrentTimestamp();
    getStakeTypes();
  }, []);

  return (
    <section className={classes.section}>
      <table style={{ width: '100%' }}>
        <thead className={classes.tableHead}>
          <tr className={classes.tableHead}>
            {Object.entries(header).map(([_, { slug, name }]) => (
              <th key={slug + name} className='p-3 text-start'>
                {name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {stakes.map((column: any, i: number) => {
            const index = i + 1;
            const fields = { ...column.stake, will_claim: column.will_claim };
            return (
              <tr key={index} className={classes.tableRow}>
                {Object.entries(header).map(([key, entry]) => {
                  const  { type, name } = entry;
                  const options =  column.stake.stake_type_id === 7 ? (entry as any).optionsSmall : (entry as any).optionsLong;
                  switch (type) {
                    case 'boolean':
                      if(!fields.claimed) {
                        return (
                          <td key={key + index} className='p-3 text-start'>
                            <Switch
                              checked={fields[key]}
                              disabled={canClaim[index]}
                              onChange={() => handleAutoRestake(index)}
                            />
                          </td>
                        );
                      } else return <td></td>;

                    case 'date':
                      return (
                        <td key={key + index} className='p-3 text-start'>
                          <span>
                            {format(
                              new Date(fields[key] * 1000).toISOString(),
                              'yyyy-MM-dd'
                            )}
                          </span>
                        </td>
                      );

                    case 'bigint':
                      return (
                        <td key={key + index} className='p-3 text-start'>
                          {
                            fields.auto_restake && key === 'will_claim' 
                              ? <Tooltip title="The amount that will become available for claim can be calculated when autorestake is not enabled.">
                                  <IconButton>
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip> 
                              : <Tooltip title={`${bigNumberToFloat(fields[key])} ZAYA`}>
                                <span>{roundDown(bigNumberToFloat(fields[key]), 2)}</span>
                              </Tooltip>
                          }
                        </td>
                      );
                    case 'dropdown': 
                    if(!fields.claimed) {
                      return (
                        <td key={key + index} className='p-3 text-start'>
                          {
                            !pending && 
                            <Select
                              value={count[index]}
                              onChange={(e) => handleChange(e, index)}
                              className={classes.select}
                            >
                              {
                                options.map((item: string) => {
                                  return (
                                    <MenuItem
                                      key={item} 
                                      value={item}
                                      className={classes.menuItem}
                                    >
                                      {parseInt(item) * stakeTypes[column.stake.stake_type_id - 1]?.period / 24 / 60 / 60} days
                                    </MenuItem>
                                  );
                                })
                              }
                            </Select>
                          }
                        </td>
                      );
                    } else return <td key={key + index} ></td>;

                    case 'action':
                      if(!fields.claimed) {
                        return (
                          <td key={key + index} >
                            {
                              !canClaim[index] && fields.auto_restake ? <Tooltip title="If the autorestake period has passed you have to disable autorestake button in order to be able to claim.">
                                   <button
                                    onClick={() => {
                                      if (!('triggeredAction' in entry)) return;
                                      entry.triggeredAction(index);
                                    }}
                                    className={
                                      canClaim[index]
                                        ? classes.fullButton
                                        : classes.fullButtonDisabled
                                    }
                                    disabled={canClaim[index] ? false : true}
                                  >
                                    {name}
                                  </button>
                                </Tooltip> :   <button
                              onClick={() => {
                                if (!('triggeredAction' in entry)) return;
                                entry.triggeredAction(index);
                              }}
                              className={
                                canClaim[index]
                                  ? classes.fullButton
                                  : classes.fullButtonDisabled
                              }
                              disabled={canClaim[index] ? false : true}
                            >
                              {name}
                            </button>
                            }
                          
                          </td>
                        );
                      } else return <td key={key + index} ></td>;

                    default:
                      <></>;
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );

  
}
