import React, { useState, useEffect } from 'react';
import {
  FormControl,
  TextField,
  Button,

} from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import emailjs from 'emailjs-com';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    submitBtn: {
      width: '100% !important',
      margin: '1rem !important',
      paddingTop: '1rem !important',
      paddingBottom: '1rem !important',
      borderRadius: '108px !important',
      border: '1px solid var(--neutral-400, #D9DBE9)',
      background: '#20C2E5 !important',
      boxShadow: '0px 2px 12px 0px rgba(20, 20, 43, 0.08)',
      marginTop: '1rem !important',
      color: 'white !important',
      fontSize: '1.2rem !important',
      [theme.breakpoints.down('sm')]: {
        margin: '0 !important'
      }
    },
    gridPaddingLeft: {
      paddingLeft: '3rem',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0',
        marginTop: '90px !important'
      }
    },
    textField: {
      borderRadius: '10px',
      background: '#f7f7f7',
      margin: '1rem 0 !important',
      '& fieldset': {
        border: 'none !important'
      }
    },
    subscription: {
      margin: '1rem !important',
      fontSize: '15px !important'
    }
  })
);

const TokenForm = () => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [wallet, setWallet] = useState('');
  const [amount, setAmount] = useState<any>();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_KEY as string);
  }, []);

  const verifyUser = async (token: string) => {
    const response = await axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/user/verify-user`,
      method: 'post',
      data: { token },
    });

    return response.data.isTokenValid;
  };

  const handleSubmit = async (e: any) => {
    let token = '';

    if (!name) {
      toast.warn('Name is required');
      return;
    }
    if (!wallet) {
      toast.warn('Wallet address is required');
      return;
    }
    if (!amount || amount < 1000) {
      toast.warn('The amount has to be grater than 1000$');
      return;
    }

    let isUserValid = false;

    if (executeRecaptcha) {
      token = await executeRecaptcha('token');
      isUserValid = await verifyUser(token);
    } else console.log('Execute recaptcha not yet available');

    if (isUserValid) {
      try {
        emailjs.send(process.env.REACT_APP_SERVICE_ID as string, process.env.REACT_APP_TOKEN_FORM_TEMPLATE as string, {
         from_name: name,
         to_name: 'Zaya',
         wallet: wallet,
         amount: amount
       });
      } catch (e) {
        console.log(e);
      }

      toast.success('Your data has been sent successfully');
      setName('');
      setWallet('');
      setAmount(0);
    }
  };

  return (
    <>
      <div>Full name *</div>
      <FormControl fullWidth variant='standard'>
        <TextField
          id='standard-adornment-amount'
          variant='outlined'
          value={name}
          className={classes.textField}
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>
      <div>MultiversX wallet *</div>
      <FormControl fullWidth variant='standard'>
        <TextField
          id='standard-adornment-amount'
          variant='outlined'
          value={wallet}
          className={classes.textField}
          onChange={(e) => setWallet(e.target.value)}
        />
      </FormControl>
      <div>Amount that you wish to invest($) *</div>
      <FormControl fullWidth variant='standard'>
        <TextField
          id='standard-adornment-amount'
          variant='outlined'
          value={amount}
          type='number'
          className={classes.textField}
          onChange={(e) => setAmount(e.target.value)}
        />
      </FormControl>

      <Button className={classes.submitBtn} onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
};

export { TokenForm };
