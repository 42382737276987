import { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import  { StakingMethods }  from 'helpers/smartcontract/stakingMethods';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      background: 'linear-gradient(180deg, #031420 0%, #0F3249 101.33%, #3E5F75 101.33%)',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '32px 24px',
      width: '100%',
      maxWidth: '400px',
      borderRadius: '6px',
      zIndex: 10
    },
    overlay: {
      position: 'fixed',
      left: 0,
      top: 0,
      height: '100vh',
      width: '100vw',
      background: 'black',
      opacity: '0.8',
      zIndex: 9,
      backdropFilter: 'blur(110px)'
    },
    title: {
      fontSize: '71px !important',
      fontWeight: '500 !important',
      lineHeight: '129% !important',
      marginBottom: '40px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '51px !important'
      }
    },
    stakingTitle: {
      color: '#FFF',
      fontSize: '28px',
      fontWeight: '700',
      marginBottom: '20px',
      textAlign: 'center'
    },
    description: {
      color: '#FFF',
      fontSize: '12px',
      marginBottom: '20px',
      fontWeight: 400,
      textAlign: 'center'
    },
    fullButton: {
      borderRadius: '85px !important',
      background: '#20C2E5 !important',
      display: 'flex',
      width: '100%',
      padding: '18px !important',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#1B2B38 !important',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
      border: 'none !important'
    },
    smallButton: {
      borderRadius: '6px !important',
      background: '#20C2E5 !important',
      display: 'flex',
      width: 'fit-content',
      padding: '4px 16px !important',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#1B2B38 !important',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16px',
      border: 'none !important'
    },
    stakeButton: {
      borderRadius: '85px !important',
      background: '#1B2B38 !important',
      display: 'flex',
      width: '100%',
      padding: '18px !important',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#20C2E5 !important',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
      border: 'none !important'
    },
    selected: {
      borderRadius: '85px !important',
      background: '#20C2E5 !important',
      display: 'flex',
      width: '100%',
      padding: '18px !important',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#1B2B38 !important',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
      border: 'none !important'
    },
    input: {
      color: '#16374D',
      background: 'white',
      padding: '10px 25px',
      width: '100%',
      borderRadius: '6px !important',
      border: 'none',
      '&:focus-visible': {
        outline: 'none !important'
      }
    },
    closeButton: {
      borderRadius: '85px !important',
      background: '#20C2E5 !important',
      display: 'flex',
      width: 'fit-content',
      padding: '10px !important',
      color: '#1B2B38 !important',
      fontWeight: 600,
      lineHeight: '20px',
      border: 'none !important'
    },
    alignEnd: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%'
    }
  })
);

export default function StakingModal({ balance, setOpen, reward, setReward, setRefreshStakes, config }: { balance: number, setOpen: any, reward: number, setReward: any, setRefreshStakes: any, config: any }) {
  const [stakeAmount, setStakeAmount] = useState<number>();
  const classes = useStyles();
  const [periods, setPeriods] = useState(1);

  const handleStake = async () => {
    setOpen(false);
    await StakingMethods.stake({config, amount: stakeAmount || 0, tokenIdentifier: process.env.REACT_APP_TOKEN_IDENTIFIER as string, stake_type_id: periods});
    setRefreshStakes(true);
  };

  const choosePeriod = (id: number) => {
    setPeriods(id);
    id === 1 ? setReward(1.38) : setReward(2);
  };

  const roundDown = (number: number, decimalPlaces: number) => {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.floor(number * multiplier) / multiplier;
  };

  return (
    <>
      <div className={classes.overlay}></div>
      <section className={classes.root}>
        <div className={classes.alignEnd}>
          <button onClick={() => setOpen(false)} className={classes.closeButton}>
            <img src='/close-icon.svg' style={{ width: '16px', height: '16px' }} />
          </button>
        </div>
        <div className={classes.stakingTitle}>Stake Zaya</div>
        <div style={{ display: 'flex', gap: '4px'}}>
          <input 
            onChange={(e: any) =>  setStakeAmount(e.target.value)}
            value={stakeAmount} 
            className={classes.input} 
            placeholder='0'
            type='number' 
          />
          <button 
            onClick={() => setStakeAmount(balance)}
            className={`${classes.smallButton}`}
          >Max</button>
        </div>
        <p style={{ color: 'white', marginTop: '8px'}}>Your balance is: {roundDown(balance, 2)} ZAYA</p>
        <p style={{ color: 'white', marginTop: '8px', marginBottom: '8px'}}>Please choose a staking period:</p>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <button 
            onClick={() => choosePeriod(1)}
            className={`${classes.stakeButton} ${periods === 1 ? classes.selected : ''}`}
          >
            90 days
          </button>
          <button
            onClick={() => choosePeriod(4)}
            className={`${classes.stakeButton} ${periods === 4 ? classes.selected : ''}`}            
          >
            180 days
          </button>
        </div>

        <p style={{ color: 'white', marginTop: '16px', marginBottom: '8px'}}>
          Your reward will be: {reward}% monthly
        </p>
        <button 
          onClick={() => handleStake()}
          className={classes.fullButton}
          style={{ marginBottom: '32px', marginTop: '16px' }}
        >Stake</button>
        <div className={classes.description}>ZayaAI is a ground-breaking ecosystem that aims to combat cancers by leveraging the latest AI technologies and medical screening procedures to provide early detection, prevention, and treatment.</div>
      </section>
    </>
  );
}