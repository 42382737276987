import { Box, Typography, Container } from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';

import Wave from '../../../assets/img/telepathology_dx/wave.png';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: '125px',
      minHeight: '100vh',
      height: 'auto',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        paddingTop: '75px'
      },
      position: 'relative',
      overflowX: 'hidden'
    },
    wave: {
      position: 'absolute',
      top: '10rem',
      width: '100%'
    },
    container: {
      marginTop: '50px',
      [theme.breakpoints.down('sm')]: {
        padding: '26px'
      }
    },
    title: {
      fontSize: '71px !important',
      fontWeight: '600 !important',
      lineHeight: '129% !important',
      color: '#20C2E5 !important',
      textAlign: 'center',
      [theme.breakpoints.down('lg')]: {
        fontSize: '60px !important'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '51px !important'
      }
    },
    content: {
      fontSize: '16px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    image: {
      width: '100%',
      maxWidth: '600px',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '400px',
      }
    }
  })
);

const Luna = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img src={Wave} className={classes.wave} />
      <Container maxWidth='xl' className={classes.container}>
        <Box sx={{ mt: { xs: '0', lg: '175px' }, mb: '50px', display: 'flex', alignItems: 'center', flexDirection: {xs: 'column', md: 'row'}, gap: '60px' }}>
          <Typography className={classes.title}>ZayaAI Appoints Luna PR to Lead Web2-Web3 Integration</Typography>
          <img src='/news/3.png' className={classes.image} />
        </Box>
        <div>
          <p className={classes.content}>
            <b>[Bucharest, May 15th, 2024]</b> - ZayaAI, a comprehensive ecosystem dedicated to combating cancer using advanced artificial intelligence (AI), is pleased to announce the appointment of Luna PR, an acclaimed agency recognized for its expertise in Web3 and emerging technologies.
          </p> <br />
          <p className={classes.content}>
            This collaboration marks a significant milestone for ZayaAI, leveraging Luna PR's proficiency to amplify visibility and communicate the company's mission and competencies across both Web2 and Web3 platforms. 
          </p> <br />
          <p className={classes.content}>
            <b>Cristian Mogodici, CEO of ZayaAI</b>, expressed his enthusiasm stating, “We are thrilled to welcome Luna PR onboard as our strategic PR and marketing partner. Their deep understanding of Web3 technologies aligns perfectly with our vision of driving innovation at the intersection of healthcare and advanced digital solutions.”
          </p> <br />
          <p className={classes.content}>
            <b>Nikita Sachdev, Founder and CEO of Luna PR</b>, echoed the sentiment, stating, “At Luna PR, we deeply understand the evolving blockchain landscape and recognize AI's immense potential, particularly in healthcare. Partnering with ZayaAI offers Luna PR an exciting opportunity to contribute to this transformative impact, and we are committed to amplifying ZayaAI's global influence and setting new benchmarks for industry leadership and technological advancement.”
          </p> <br />

          <p className={classes.content}>
            ZayaAI’s approach seamlessly merges Web2 with Web3 tools, providing a unique synergy in healthcare innovation. The company is confident that Luna PR’s expertise and strategic guidance will amplify ZayaAI's mission to revolutionize the healthcare landscape. By harnessing Luna PR's deep understanding of Web3 technologies and its proven track record in elevating brands within the emerging tech sector, ZayaAI looks forward to fostering greater awareness, engagement, and adoption of its groundbreaking solutions worldwide.
          </p> <br />

          <a href='https://www.zaya.ai/' style={{ fontWeight: 500 }}>About ZayaAI</a>
          <p className={classes.content}>
            ZayaAI equips pathologists and researchers in clinical labs with powerful deep-learning artificial intelligence software for translating images into discoveries, decisions, and diagnoses. The cloud-based ZayaAI products and services aim to improve the efficiency and precision of pathology diagnosis and pathology image analysis beyond current capabilities, across a variety of fields from oncology to dermato-pathology and more.          
          </p> <br />

          <a href='https://www.lunapr.io/' style={{ fontWeight: 500 }}>About Luna PR</a>
          <p className={classes.content}>
            Luna PR is an award-winning public relations and marketing agency that has offices worldwide, with its headquarters in Dubai. Founded in 2017, Luna PR has since served prominent companies in the Web3, fintech, emerging tech, and gaming industries. The agency’s portfolio of clients span across startups to established multinational corporations. With a team of over 100 employees across 3 continents, Luna PR connects tomorrow’s technology with today’s audience.         
          </p> <br />

          <p className={classes.content}>
            To learn more about Luna PR, visit <a href='www.lunapr.io'>www.lunapr.io</a>
          </p>
        </div>
      </Container>
    </Box>
  );
};

export { Luna };
