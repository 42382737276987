import React, { useEffect } from 'react';
import {
    Box, Button, Grid, Typography
} from '@mui/material';

import {
    createStyles, makeStyles
} from '@mui/styles';


import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { useNavigate, useLocation } from 'react-router-dom';
import { Blog } from 'components/common/Blog';
import { FAQ } from 'components/common/Faq';
import { TokenChart } from 'components/TokenChart/TokenChart';
import Ball from '../../assets/img/blockchain/ball.svg';
import BenefitBack from '../../assets/img/blockchain/benefit_back.png';
import Cer1 from '../../assets/img/blockchain/cer1.svg';
import Cer2 from '../../assets/img/blockchain/cer2.svg';
import Cer3 from '../../assets/img/blockchain/cer3.svg';
import Cer4 from '../../assets/img/blockchain/cer4.svg';
import Frame from '../../assets/img/blockchain/Frame.png';
import Hand from '../../assets/img/blockchain/hand.png';

import Wave from '../../assets/img/telepathology_dx/wave.png';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ArcElement,
    Tooltip,
    Legend,
);

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            paddingTop: '267px',
            position: 'relative',
            overflow: 'hidden',
            background: 'linear-gradient(180deg, #031420 0%, #0F3249 100%, #3E5F75 100%)',
            [theme.breakpoints.down('md')]: {
                paddingTop: '72px'
            }
        },
        landing: {
            minHeight: '608px',
            width: '100%',
            background: 'linear-gradient(180deg, #031420 0%, #0F3249 101.33%, #3E5F75 101.33%)',
            [theme.breakpoints.down('md')]: {
                paddingTop: '75px'
            }
        },
        wave: {
            position: 'absolute',
            top: '564px',
            width: '100%'
        },
        backImgContainer: {
            [theme.breakpoints.down('xl')]: {
                position: 'relative'
            },
            [theme.breakpoints.down('lg')]: {
                width: '100%'
            },
            [theme.breakpoints.down('md')]: {
                marginTop: '50px'
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '80px',
                marginBottom: '80px'
            },
        },
        light: {
            borderRadius: '565px',
            height: '565px',
            width: '565px',
            background: 'rgba(0, 117, 254, 0.51)',
            filter: 'blur(250px)',
            position: 'absolute',
            right: 0,
            [theme.breakpoints.down('md')]: {
                height: '365px',
                width: '365px',
            },
        },
        shutter: {
            position: 'absolute',
            top: '238px',
            right: '-23px',
            width: '803px',
            left: '70%',
            [theme.breakpoints.down('xl')]: {
                width: '600px',
                position: 'relative',
                top: 0,
                left: '50%'
            },
            [theme.breakpoints.down('lg')]: {
                position: 'relative',
                width: '400px',
                top: 0,
                left: '80%',
                transform: 'translateX(-60%)'
            },
            [theme.breakpoints.down('md')]: {
                position: 'relative',
                width: '400px',
                left: '85%',
                top: 0,
                transform: 'translateX(-60%)'
            },
            [theme.breakpoints.down('sm')]: {
                position: 'relative',
                width: '400px',
                top: 0,
                left: '100%',
                transform: 'translateX(-60%)'
            }
        },
        ball: {
            position: 'absolute',
            top: '350px',
            right: '25%',
            [theme.breakpoints.down('xl')]: {
                width: '150px',
                top: '80px',
                right: '40%',
            },
            [theme.breakpoints.down('lg')]: {
                top: '55px',
                right: '50%',
                width: '100px'
            },
            [theme.breakpoints.down('md')]: {
                top: '60px',
                right: '38%',
                width: '100px'
            },
            [theme.breakpoints.down('sm')]: {
                position: 'absolute',
                width: '100px',
                top: '50px',
                left: '50%',
                transform: 'translateX(-80%)'
            }
        },
        headContainer: {
            paddingLeft: '185px',
            [theme.breakpoints.down('xl')]: {
                padding: '0 80px !important',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '25px !important',
            }
        },
        blueHeader: {
            color: '#20C2E5',
            fontSize: '20px !important',
            fontWeight: '300 !important',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        header: {
            maxWidth: '594px !important',
            fontSize: '60px !important',
            lineHeight: '100% !important',
            marginTop: '10px !important',
            fontWeight: '500 !important',
            color: 'white !important',
            '& span': {
                color: '#20c2e5 !important'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '45px !important',
                textAlign: 'center'
            }
        },
        divider: {
            margin: '27px 0px !important',
            borderColor: '#20c2e5 !important',
            borderWidth: '2px !important',
            borderStyle: 'solid',
            width: '64px',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        desc: {
            lineHeight: 'normal !important',
            fontSize: '20px !important',
            fontWeight: '300 !important',
            color: '#FFF !important',
            letterSpacing: '0.6px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '13px !important',
                marginTop: '40px !important'
            }
        },
        buttonContainer: {
            marginTop: '60px',
            marginBottom: '10px',
            gap: '17px',
            [theme.breakpoints.down('sm')]: {
                display: 'none !important'
            }
        },
        appButtonContainer: {
            display: 'none !important',
            [theme.breakpoints.down('sm')]: {
                display: 'block !important',
                padding: '48px 26px !important',
                textAlign: 'center',
                marginTop: '-150px'
            }
        },
        appButton: {
            width: '270px !important',
            padding: '18px 24px !important',
            borderRadius: '76px !important',
            border: '1px solid #20C2E5 !important',
            fontSize: '18px !important',
            color: 'white !important',
            [theme.breakpoints.down('sm')]: {
                width: '100% !important'
            },
        },
        kycButton: {
            width: '270px !important',
            padding: '18px 24px !important',
            borderRadius: '76px !important',
            background: '#20C2E5 !important',
            fontSize: '18px !important',
            color: 'black !important',
            [theme.breakpoints.down('sm')]: {
                marginTop: '10px !important',
                width: '100% !important'
            },
        },
        benefitsContainerTitle: {
            padding: '156px 186px',
            paddingBottom: '200px',
            background: '#FFF',
            position: 'relative',
            zIndex: '100',
            [theme.breakpoints.down('xl')]: {
                padding: '156px 140px',
            },
            [theme.breakpoints.down('lg')]: {
                padding: '40px 26px'
            },
            [theme.breakpoints.down('md')]: {
                padding: '60px 26px 40px 26px'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '40px 26px 64px 26px'
            }
        },
        benefitsContainer: {
            padding: '156px 186px',
            paddingBottom: '200px',
            background: '#FFF',
            position: 'relative',
            zIndex: '100',
            [theme.breakpoints.down('lg')]: {
                padding: '40px 26px'
            },
            [theme.breakpoints.down('md')]: {
                padding: '40px 26px 40px 26px'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '40px 26px 64px 26px'
            }
        },
        benefitsContainer2: {
            padding: '156px 186px',
            background: '#FFF',
            position: 'relative',
            zIndex: '100',
            [theme.breakpoints.down('lg')]: {
                padding: '40px 26px'
            },
            [theme.breakpoints.down('md')]: {
                padding: '30px 26px'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '40px 26px 0px'
            }
        },
        padding40: {
            paddingRight: '20px',
            [theme.breakpoints.down('md')]: {
                display: 'flex',
                flexDirection: 'column !important',
                alignItems: 'center'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '0',
                alignItems: 'flex-start'
            }
        },
        benefitHeader: {
            marginTop: '12px !important',
            color: '#16374D',
            fontSize: '60px !important',
            fontWeight: '500 !important',
            lineHeight: '97.523% !important',
            [theme.breakpoints.down('md')]: {
                textAlign: 'center'
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '0 !important',
                fontSize: '50px !important',
                textAlign: 'start'
            }
        },
        smallButtonContainer: {
            marginTop: '25px'
        },
        smallButton: {
            borderRadius: '2px !important',
            background: '#EEE !important',
            padding: '1px 8.3px 2.19px 8px',
            fontSize: '13px !important',
            fontStyle: 'normal !important',
            fontWeight: '300 !important',
            lineHeight: '18.2px !important',
            letterSpacing: ' 0.78px !important',
            marginRight: '15px !important',
            color: '#16374D !important'
        },
        benefitsDetails: {
            marginTop: '40px !important',
            color: '#16374D !important',
            fontSize: '20px !important',
            fontWeight: '300 !important',
            lineHeight: 'normal !important',
            letterSpacing: '0.6px !important',
            [theme.breakpoints.down('md')]: {
                textAlign: 'center',
                marginTop: '36px !important',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px !important',
                textAlign: 'start'
            }
        },
        card: {
            padding: '58px 63px',
            borderRadius: '20px',
            background: 'linear-gradient(180deg, #031420 0%, #0F3249 101.33%, #3E5F75 101.33%)',
            zIndex: 2,
            marginLeft: '140px !important',
            position: 'relative',
            [theme.breakpoints.down('xl')]: {
                marginTop: '2rem',
                marginLeft: '80px !important',
            },
            [theme.breakpoints.down('lg')]: {
                marginLeft: '2rem !important',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '32px 27px !important',
                margin: '60px 0px 0 -26px !important',
                width: '100vw',
                borderRadius: '0',
            }
        },
        cardItemTitle: {
            color: 'white !important',
            fontSize: '17px !important',
            fontWeight: '500 !important',
            linHeight: '23.4px !important',
            letterSpacing: '0.9px !important',
            marginTop: '22px !important',
            [theme.breakpoints.down('sm')]: {
                marginTop: '17px !important',
            }
        },
        cardItemContent: {
            color: '#BCCBD6 !important',
            fontSize: '14.08px !important',
            fontWeight: '300 !important',
            linHeight: '21.12px !important',
            letterSpacing: '0.64px !important'
        },
        typeContainer: {
            width: '100%'
        },
        benefitsBack: {
            position: 'absolute',
            bottom: '95px',
            right: '120px',
            [theme.breakpoints.down('xl')]: {
                left: '184px'
            },
            [theme.breakpoints.down('md')]: {
                bottom: '55px',
            },
            [theme.breakpoints.down('sm')]: {
                bottom: '25%',
                left: '-20px'
            }
        },
        benefitsBack2: {
            position: 'absolute',
            bottom: '50px',
            right: '120px',
            [theme.breakpoints.down('xl')]: {
                left: '0'
            },
            [theme.breakpoints.down('sm')]: {
                right: '-220px',
                width: '100vw',
                bottom: 0,
                top: 0,
                left: '-5%'
            }
        },
        gridMobileBackground: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                position: 'absolute',
                bottom: '10%',
                left: '8px',
                scale: 2.5,
                width: '100%'
            }
        },
        collaboratorContainer: {
            background: 'linear-gradient(180deg, #031420 0%, #0F3249 100%, #3E5F75 100%)',
            backdropFilter: 'blur(83.9669418334961px)',
            padding: '40px 145px',
            color: 'white !important',
            [theme.breakpoints.down('md')]: {
                paddingTop: '50px',
                paddingBottom: '50px'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '32px',
                paddingBottom: '50px'
            }
        },
        collGridContainer: {
            [theme.breakpoints.down('xl')]: {
                gap: '10px',
                textAlign: 'center'
            },
            [theme.breakpoints.down('sm')]: {
                textAlign: 'start'
            }
        },
        collaB: {
            fontSize: '37px !important',
            fontWeight: '300 !important',
            lineHeight: '115.6% !important',
            color: 'white !important',
            paddingRight: '20px',
            [theme.breakpoints.down('md')]: {
                marginBottom: '25px!important'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '25px 0 0 0 !important'
            }
        },
        collaS: {
            fontSize: '20px !important',
            fontWeight: '500 !important',
            lineHeight: 'normal !important',
            color: 'white !important',
            [theme.breakpoints.down('md')]: {
                marginBottom: '42px!important'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px !important',
                fontWeight: '300 !important',
            }
        },
        contactButton: {
            maxWidth: '360px !important',
            width: '100%',
            margin: '0px 20px',
            padding: '18px 24px !important',
            borderRadius: '76px !important',
            background: '#FFF !important',
            fontSize: '18px !important',
            fontWeight: '500 !important',
            color: 'black !important',
            [theme.breakpoints.down('sm')]: {
                margin: '0px !important',
                width: '100% !important'
            },
        },
        tokenContainer: {
            paddingTop: '100px',
            background: '#fff',
            textAlign: 'center',
            color: '#16374D',
            [theme.breakpoints.down('md')]: {
                paddingTop: '60px'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '25px',
                paddingTop: '17px',
                textAlign: 'start',
            },
        },
        tokenTitle: {
            fontSize: '60px !important',
            fontWeight: '500 !important',
            lineHeight: '97.523%',
            [theme.breakpoints.down('sm')]: {
                fontSize: '50px !important',
                padding: '50px 0 !important',
                textAlign: 'start !important',
                lineHeight: '97% !important',
            },
        },
        tokenContent: {
            fontSize: '20px !important',
            fontWeight: '300 !important',
            lineHeight: 'normal',
            letterSpacing: '0.6px',
            padding: '58px 400px',
            marginBottom: '100px !important',
            [theme.breakpoints.down('xl')]: {
                padding: '30px',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '0 !important',
                fontSize: '16px !important',
                marginBottom: '0px !important',
            },
        },
        buttonGroup: {
            paddingTop: '115px',
            paddingBottom: '40px',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'none !important'
            }
        },
        whiteButton: {
            display: 'block',
            width: '270px !important',
            padding: '18px 24px !important',
            borderRadius: '76px !important',
            border: '1px solid #20C2E5 !important',
            fontSize: '18px !important',
            color: '#20C2E5 !important',
            fontWeight: '500 !important',
            textDecoration: 'none !important',
        },
        roadButton: {
            width: '270px !important',
            padding: '18px 24px !important',
            borderRadius: '76px !important',
            background: '#20C2E5 !important',
            fontSize: '18px !important',
            color: 'white !important',
            marginLeft: '13px !important',
            fontWeight: '500 !important',
        },
        graphContainer: {
            color: '#16374D',
            background: 'white',
            textAlign: 'left',
            paddingLeft: '190px',
            paddingRight: '190px',
            [theme.breakpoints.down('xl')]: {
                paddingLeft: '30px',
                paddingRight: '30px'
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none !important'
            }
        },
        graphTitle: {
            paddingTop: '71px !important',
            fontSize: '33px !important',
            fontWeight: '500 !important'
        },
        graphContent: {
            fontSize: '27px !important',
            fontWeight: '400 !important'
        },
        UtilContainer: {
            padding: '0 185px',
            background: '#fff',
            textAlign: 'center',
            color: '#16374D',
            paddingTop: '85px',
            [theme.breakpoints.down('lg')]: {
                padding: '0 30px !important'
            },
            [theme.breakpoints.down('md')]: {
                paddingTop: '70px !important'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '0 !important'
            }
        },
        margin85: {
            marginTop: '85px',
            paddingBottom: '30px',
            flexWrap: 'wrap',
            [theme.breakpoints.down('lg')]: {
                marginTop: '50px !important',
                display: 'flex !important',
                flexWrap: 'nowrap !important',
                overflowX: 'scroll'
            },
            [theme.breakpoints.down('md')]: {
                marginTop: '30px !important'
            }
        },
        utilBox: {
            padding: '7px',
            [theme.breakpoints.down('xl')]: {
                width: '100%'
            }
        },
        utilityContainer: {
            height: '100%',
            padding: '34px 50px',
            color: 'white',
            borderRadius: '20px',
            background: 'linear-gradient(180deg, #031420 0%, #0F3249 101.33%, #3E5F75 101.33%)',
            backdropFilter: 'blur(83.9669418334961px)',
            textAlign: 'left',
            [theme.breakpoints.down('xl')]: {
                minHeight: '380px'
            },
            [theme.breakpoints.down('lg')]: {
                width: '310px',
                padding: '34px 26px',
            },
            [theme.breakpoints.down('sm')]: {
                minHeight: '380px',
                marginBottom: '10px'
            }
        },
        utilityTitle: {
            fontWeight: '700 !important',
            fontSize: '20px !important',
            color: 'white !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px !important',
            }
        },
        utilityContent: {
            fontWeight: '400 !important',
            fontSize: '20px !important',
            color: 'white !important',
            [theme.breakpoints.down('md')]: {
                fontSize: '16px !important',
            }
        },
        shape: {
            position: 'absolute',
            right: 0,
            bottom: 0,
            [theme.breakpoints.down('sm')]: {
              right: '-25px'
            }
        },
        benefitsLi: {
            marginTop: '25px !important',
            color: '#16374D !important',
            fontSize: '20px !important',
            fontWeight: '300 !important',
            lineHeight: 'normal !important',
            letterSpacing: '0.6px !important',
            paddingLeft: '25px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px !important',
                marginTop: '12px !important',
            }
        },
        frame: {
            position: 'absolute',
            bottom: '80px',
            right: '200px'
        },
        framesContainer: {
            [theme.breakpoints.down('xl')]: {
                position: 'relative',
                marginTop: '20px !important',
                width: '100%'
            }
        },
        frame2: {
            position: 'absolute',
            bottom: '80px',
            right: '200px',
            [theme.breakpoints.down('xl')]: {
                position: 'relative',
                left: '0'
            },
            [theme.breakpoints.down('md')]: {
                scale: '0.7',
                bottom: '0px'
            },
            [theme.breakpoints.down('sm')]: {
                bottom: '0px',
                right: '0',
                width: '100%',
                scale: 1,
                left: '-15%'
            }
        },
        certiImg: {
            position: 'absolute',
            bottom: '-80px',
            left: '-30px',
            width: '115%',
            [theme.breakpoints.down('xl')]: {
                position: 'relative',
                left: '0'
            },
            [theme.breakpoints.down('md')]: {
                position: 'absolute',
                bottom: '10%',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none !important'
            }
        },
        auctionBox: {
            padding: '58px 63px',
            borderRadius: '20px',
            background: 'linear-gradient(180deg, #031420 0%, #0F3249 100%, #3E5F75 100%)',
            position: 'relative'
        },
        certificatesContainer: {
            padding: '56px 186px 156px 186px',
            background: '#FFF',
            position: 'relative',
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                padding: '27px'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '27px'
            }
        },
        cerBenefitsGrid: {
            padding: '8px'
        },
        cerBenefitsBox: {
            borderRadius: '23.88px',
            background: 'linear-gradient(180deg, #031420 0%, #0F3249 101.33%, #3E5F75 101.33%)',
            backdropFilter: 'blur(100px)',
            padding: '30px',
            textAlign: 'left',
            minHeight: '400px',
            [theme.breakpoints.down('sm')]: {
                minHeight: 'unset',
                padding: '25px 30px 40px 30px'
            }
        },
        imgContainer: {
            textAlign: 'right',
            '& img': {
                width: '110px',
                [theme.breakpoints.down('sm')]: {
                    width: '95px'
                }
            }
        },
        blueT: {
            color: '#20C2E5 !important',
            fontSize: '16.372px !important',
            fontWeight: '400 !important',
            lineHeight: '120% !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px !important',
            }
        },
        cerGridContainer: {
            marginTop: '60px',
            [theme.breakpoints.down('md')]: {
                marginTop: '40px',
            }
        },
        cerTitle: {
            color: '#FFF !important',
            fontSize: '25px !important',
            fontWeight: '700 !important',
            lineHeight: '120% !important',
            marginTop: '13px!important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '21px !important',
            }
        },
        cerContent: {
            color: 'rgba(255, 255, 255, 0.71) !important',
            fontSize: '19px !important',
            fontWeight: '400 !important',
            lineHeight: '120% !important',
            marginTop: '15px!important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16.7px !important',
            }
        },
        listMargin: {
            marginBottom: '25px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '12px',
            }
        },
        askContainer: {
            marginTop: '12px',
            padding: '30px 45px !important',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: '#FFF',
            boxShadow: '0px -1px 0px 0px #EDF2F6 inset'
        },
        askTitle: {
            fontSize: '18px !important',
            fontWeight: '500 !important',
            color: '#1B1D21'
        },
        plusImg: {
            width: '15px'
        },
        newsContainer: {
            paddingTop: '3rem',
            paddingBottom: '8rem'
        },
        relative: {
            position: 'relative'
        },
        percentGrid: {
            padding: '8px',
            [theme.breakpoints.down('sm')]: {
                padding: '11px 0px',
                width: '100%'
            }
        },
        percentBox: {
            padding: '25px',
            borderRadius: '20px',
            background: 'linear-gradient(180deg, #031420 0%, #0F3249 100%, #3E5F75 100%)',
            backdropFilter: 'blur(83.9669418334961px)',
            color: 'white',
            zIndex: 2,
            position: 'relative',
        },
        percentTitle: {
            fontSize: '38.979px !important',
            fontWeight: '600 !important',
            lineHeight: 'normal !important',
            textTransform: 'capitalize',
            color: 'white',
        },
        percentContent: {
            fontSize: '25px !important',
            fontWeight: '400 !important',
            lineHeight: '21px !important',
            textTransform: 'capitalize',
            color: 'white',
        },
        percentlevel: {
            fontSize: '67px !important',
            fontWeight: '400 !important',
            lineHeight: '60px !important',
            textTransform: 'capitalize',
            marginTop: '13px !important',
            opacity: '0.3',
            color: 'white',
        },
        auctionContainer: {
            paddingLeft: '50px !important',
            [theme.breakpoints.down('md')]: {
                paddingLeft: '0px !important',
                paddingBottom: '36px'
            }
        },
        doughnut: {
            width: '500px',
            [theme.breakpoints.down('sm')]: {
                width: '300px'
            }
        },
        imgContainer2: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex'
            }
        },
        smallIframe: {
            [theme.breakpoints.down('xl')]: {
                width: '100%',
                height: '360px'
            },
            [theme.breakpoints.down('lg')]: {
                width: '440px',
                height: '260px'
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
                height: '400px'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '260px',
                marginRight: '26px'
            }
        }
    })
);

const utilities = [
    {
        header: 'ZAYA LIS',
        body: [
            'Pathology Laboratory Information System',
            'Are you a pathology lab or a hospital that needs a LIS for your pathology department? You may use ZayaAI LIS by paying for it with the $ZAYA token and you could also get rewarded.'
        ]
    },
    {
        header: 'Telepathology',
        body: [
            'Are you a pathology lab or a hospital that needs to outsource pathology diagnosis?',
            'You may use ZayaAI telepathology software by paying for it with the $ZAYA token and you could also get rewarded.'
        ]
    },
    {
        header: 'AI Pathology Diagnosis',
        body: [
            'Are you a pathology lab or hospital that needs to outsource pathology diagnosis?',
            'You may use ZayaAI diagnosis tools and you could also get rewarded.'
        ]
    },
    {
        header: 'Fight cancer with AI prediction technologies',
        body: [
            'Allow your medical data to be used to train AI models and you could also get rewarded with the $ZAYA token.'
        ]
    },
    {
        header: 'Enroll in Zaya medical research',
        body: [
            'Studies and clinical studies as a patient and you could also get rewarded with the $ZAYA token'
        ]
    },
    {
        header: 'The ZayaAI tool for prognostic and genomic prediction-personalized medicine',
        body: [
            'Use the ZayaAI prediction tool in view of determining the most efficient treatment / molecule in specific diseases and you could also get rewarded.'
        ]
    },
    {
        header: 'ZayaAI companion diagnostics',
        body: [
            'For patient selection of pharma companies developing new drugs & therapies.',
            'Are you a pharma company that develops new drugs or therapies? You may use ZayaAI patient selection by paying for it with the $ZAYA token and you could also get rewarded.'
        ]
    },
    {
        header: 'ZayaAI Edu',
        body: [
            'Are you a medical university and need digital pathology software to train your students on real tissue images and cases?',
            'You may use ZayaAI Edu by paying for this service with the $ZAYA token and you could also get rewarded.'
        ]
    },
    {
        header: 'Medical Data',
        body: [
            'Any company or individual who wants access to high level medical metadata for their own research could use the $ZAYA token to pay for access to medical datasets.',
            'Any company and individual who has medical data available to be used in ZayaAI R&D process would be paid with the $ZAYA token.'
        ]
    },
];

const Home = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom' as const,
                align: 'start' as const,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                }
            },
            title: {
                display: true,
            },
        },
    };

    const labels = [
        'Month 0', 'Month 1', 'Month 2', 'Month 3', 'Month 4', 'Month 5', 'Month 6', 'Month 7', 'Month 8', 'Month 9', 'Month 10', 'Month 11', 'Month 12', 'Month 13',
        'Month 14', 'Month 15', 'Month 16', 'Month 17', 'Month 18', 'Month 19', 'Month 20', 'Month 21', 'Month 22', 'Month 23', 'Month 24'
    ];
    const labels2 = [
        'Month 0', 'Month 1', 'Month 2', 'Month 3', 'Month 4', 'Month 5', 'Month 6', 'Month 7', 'Month 8', 'Month 9', 'Month 10', 'Month 11', 'Month 12', 'Month 13',
        'Month 14', 'Month 15', 'Month 16', 'Month 17', 'Month 18', 'Month 19', 'Month 20', 'Month 21', 'Month 22', 'Month 23', 'Month 24', 'Month 25', 'Month 26',
        'Month 27', 'Month 28', 'Month 29', 'Month 30', 'Month 31', 'Month 32', 'Month 33', 'Month 34', 'Month 35', 'Month 36'
    ];

    const data = {
        labels,
        datasets: [
            {
                label: 'Seed Sale Vesting',
                data: [150000, 0, 0, 0, 0, 0, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000, 150000],
                backgroundColor: '#038BA8',
            },
            {
                label: 'Private Sale Vesting',
                data: [600000, 0, 0, 0, 0, 0, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000],
                backgroundColor: '#20C2E5',
            },
            {
                label: 'Public Sale',
                data: [1000000, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                backgroundColor: '#5FE6AE',
            },
        ],
    };

    const data2 = {
        labels: labels2,
        datasets: [
            {
                label: 'Company Tokens',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 800000, 800000, 800000, 800000, 800000, 800000, 800000,
                    800000, 800000, 800000, 800000, 800000, 800000, 800000, 800000, 800000, 800000, 800000, 800000, 800000, 800000, 800000,
                    800000, 800000, 800000, 800000],
                backgroundColor: '#33D2D2',
            },
            {
                label: 'Advisors',
                data: [0, 0, 0, 0, 0, 0, 480000, 480000, 480000, 480000, 480000,
                    480000, 480000, 480000, 480000, 480000,
                    480000, 480000, 480000, 480000, 480000,
                    480000, 480000, 480000, 480000, 480000,
                    480000, 480000, 480000, 480000, 480000,
                    0, 0, 0, 0, 0, 0
                ],
                backgroundColor: '#5D67C0',
            },
            {
                label: 'Community',
                data: [250000, 0, 0, 0, 0, 0, 370000, 370000, 370000, 370000, 370000, 370000, 370000, 370000, 370000, 370000, 370000, 370000, 370000, 0,
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                backgroundColor: '#83C9FD',
            },
            {
                label: 'Marketing',
                data: [300000, 0, 0, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000
                    , 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000, 160000],
                backgroundColor: '#2086E5',
            },
        ],
    };
    useEffect(() => {
        if (location.hash) {
            window.location.href = `/${location.hash}`;
        }
    }, [location]);

    return (
        <Box className={classes.root}>
            <Box className={classes.landing}>
                <img src={Wave} className={classes.wave} />
                <Grid container>
                    <Grid item xl={6} md={6}>
                        <Box className={classes.headContainer}>
                            <Typography className={classes.blueHeader}>
                                Zaya Web3
                            </Typography>
                            <div className={classes.header}>
                                Introduction to <span style={{ color: '#20C2E5', display: 'inline-flex'}}>Zaya &nbsp;</span>
                                and its services
                                <Box className={classes.divider} />
                                <p className={classes.desc}>
                                    ZayaAI is a comprehensive ecosystem designed to fight cancer and promote healthy living through the use of cutting-edge AI technologies and advanced medical screening techniques.
                                </p>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xl={6} md={6} className = {classes.backImgContainer}>
                        <Box className={classes.light} />
                        <img src={Hand} className={classes.shutter} />
                        <img src={Ball} className={classes.ball} />
                    </Grid>
                    <Grid container className={classes.appButtonContainer}>
                        <Grid item xl={6} lg={6}>
                            <Button className={classes.appButton} onClick={e => navigate('/token')}>Launch App</Button>
                        </Grid>
                        <Grid item xl={6} lg={6}>
                            <Button className={classes.kycButton} onClick={e => window.location.href = 'https://zaya.synaps.me/signup'}>Begin KYC Process</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.benefitsContainerTitle}>
                <Grid container>
                    <Grid item xl={6} className={classes.padding40}>
                        <Typography className={classes.benefitHeader}>
                            ZAYA Token overview and benefits
                        </Typography>
                        <Typography className={classes.benefitsDetails} style={{ marginBottom: '30px'}}>
                            The token offers letious use cases, including rewards payments, staking and other functionalities meant to offer access,
                            in particular conditions, to products and services within the ecosystem. This whitepaper aims to outline the ZayaAI ecosystem,
                            its internalized components, and the utility of the $ZAYA token.
                            Details regarding the letious sales round and their related specifics will be available soon
                        </Typography>
                        <Box sx={{ zIndex: 2, position: 'relative', display: { xs: 'none', xl: 'block'} }}>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/c5fPAEbcpmU?si=XlVJkRXKulbZruAw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>                    
                        </Box>
                    </Grid>
                    <Grid item xl={6} className={classes.typeContainer}>
                        <Box sx={{ display: 'grid', gridTemplateColumns: {xs: '1fr', md: '1fr 1fr', xl: '1fr' } , height: '100%', width: '100%' }}>
                            <Box sx={{ zIndex: 2, position: 'relative', display: { xs: 'flex', xl: 'none'}, alignItems: 'center' }}>
                                <iframe width="480" height="300" className={classes.smallIframe} src="https://www.youtube.com/embed/c5fPAEbcpmU?si=XlVJkRXKulbZruAw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>                    
                            </Box>
                            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                <Box className={classes.card}>
                                    <Typography className={classes.cardItemTitle}>
                                        Maximum Supply
                                    </Typography>
                                    <Typography className={classes.cardItemContent}>
                                        100,000,000 Tokens
                                    </Typography>
                                    <Typography className={classes.cardItemTitle}>
                                        Available for purchase
                                    </Typography>
                                    <Typography className={classes.cardItemContent}>
                                        16,864,781 Tokens
                                    </Typography>
                                    <Typography className={classes.cardItemTitle}>
                                        Public Sale price per token
                                    </Typography>
                                    <Typography className={classes.cardItemContent}>
                                        0.15 USD per 1 $ZAYA token - 8,000,000 token allocation
                                    </Typography>
                                    <Typography className={classes.cardItemTitle}>
                                        ICO seed round sale period
                                    </Typography>
                                    <Typography className={classes.cardItemContent}>
                                        Completed
                                    </Typography>
                                    <Typography className={classes.cardItemTitle}>
                                        Private round sale period
                                    </Typography>
                                    <Typography className={classes.cardItemContent}>
                                        Completed
                                    </Typography>
                                    <Typography className={classes.cardItemTitle}>
                                        Public round sale period
                                    </Typography>
                                    <Typography className={classes.cardItemContent}>
                                        TBA
                                    </Typography>
                                    <Box className={classes.shape}>
                                        <img src='/assets/img/shapes.svg' alt='shape' />
                                    </Box>
                                </Box>
                            </div>
                        </Box>
                        <img src={BenefitBack} className={classes.benefitsBack} style={{ marginBottom: '6%', zIndex: 1 }} />
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.collaboratorContainer}>
                <Grid container className = {classes.collGridContainer}>
                    <Grid item xl={4} lg = {12} alignItems='center'>
                        <Typography className={classes.collaB}>
                            <span style={{ fontWeight: 600}}>Collaborate with Zaya for dataset annotations</span>
                            <br />
                            (Only for Pathologists)
                        </Typography>
                    </Grid>
                    <Grid item xl={4} alignItems='center'>
                        <Typography className={classes.collaS}>
                            ZayaAI is a ground-breaking ecosystem that aims to combat cancers by leveraging the latest AI technologies and medical screening procedures to provide early detection, prevention, and treatment.
                        </Typography>
                    </Grid>
                    <Grid item xl={4} display='flex' justifyContent='center' alignItems='center' width='100%'>
                        <Button className={classes.contactButton} onClick={() => navigate('contact')}>
                            Contact Us
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.tokenContainer} id='token'>
                <Typography className={classes.tokenTitle}>
                    ZAYA Token
                </Typography>
                <Typography className={classes.tokenContent}>
                    The $ZAYA utility token is the central element to the ZayaAI ecosystem, built on blockchain. It serves multiple purposes, including rewards, payments, staking, while not neglecting participation in pathology research projects. The token’s functionalities can be adapted or extended based on the ecosystem’s evolution.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                    <Box className={classes.doughnut}>
                        {/* <Doughnut
                            data={{
                                datasets: [{
                                    label: 'My First Dataset',
                                    data: [3, 12, 6, 20, 12, 5, 18, 18, 6],
                                    backgroundColor: [
                                        '#2EE6FF',
                                        '#6BD2E9',
                                        '#51ABBE',
                                        '#1691AC',
                                        '#168097',
                                        '#038BA8',
                                        '#00D2FF',
                                        '#20C2E5',
                                        '#1EB1D1'
                                    ],
                                    borderColor: '#FFF',
                                    borderWidth: 0.5,
                                    hoverOffset: 4
                                }],
                                labels: [

                                ]
                            }}
                        /> */}
                        {/* <DoughnutChart /> */}
                        <TokenChart />
                    </Box>
                </Box>

                <Box className={classes.buttonGroup}>
                    <a className={classes.whiteButton} href='/whitepaper-zaya.pdf' target='_blank'>Whitepaper</a>
                    <Button className={classes.roadButton} sx={{ textTransform: 'capitalize !important'}} onClick={e => navigate('roadmap')}>Roadmap</Button>
                </Box>
            </Box>
            <Box className={classes.graphContainer}>
                <Typography className={classes.graphTitle}>
                    $ZAYA Vesting Periods
                </Typography>
                <Typography className={classes.graphContent}>
                    Major Sales Rounds
                </Typography>
                <Bar options={options} data={data} />
                <Typography className={classes.graphTitle}>
                    $ZAYA Vesting Periods
                </Typography>
                <Typography className={classes.graphContent}>
                    Remaining Token Allocations
                </Typography>
                <Bar options={options} data={data2} />
            </Box>
            <Box className={classes.UtilContainer} id='utility'>
                <Typography className={classes.tokenTitle} sx={{ width: {xs: '70%', md: '100%'}, paddingLeft: {xs: '26px !important', md: 0}}}>
                    Token Utilities
                </Typography>

                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Grid container className={classes.margin85} >
                        {
                            utilities.map((item, index) => (
                                <Grid item key={index} lg={6} xl={4} className={classes.utilBox}>
                                    <Box className={classes.utilityContainer}>
                                        <Typography className={classes.utilityTitle}>
                                            {item.header}
                                        </Typography>
                                        {
                                            item.body.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <br />
                                                    <Typography className={classes.utilityContent}>
                                                        {item}
                                                    </Typography>
                                                </React.Fragment>
                                            ))
                                        }
                                    </Box>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>

                <Box sx={{ display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none', xl: 'none' }, overflowX: 'scroll', width: 'auto', height: 'auto' }}>
                    {
                        utilities.map((item, index) => (
                            <Box key={index} className={classes.utilityContainer} sx={{ width: '300px', height: '350px', flexShrink: 0, padding: '30px', marginRight: '7px' }}>
                                <Typography className={classes.utilityTitle}>
                                    {item.header}
                                </Typography>
                                {
                                    item.body.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <br />
                                            <Typography className={classes.utilityContent}>
                                                {item}
                                            </Typography>
                                        </React.Fragment>
                                    ))
                                }
                            </Box>
                        ))
                    }
                </Box>

            </Box>

            <Box className={classes.benefitsContainer2} id='stakingreward'>
                <Grid container>
                    <Grid item xl={6} style={{ paddingRight: '40px' }}>
                        <Typography className={classes.benefitHeader}>
                            Staking and Rewards
                        </Typography>
                        <Typography className={classes.benefitsDetails}>
                            <span style={{ fontWeight: 'bold', marginBottom: 0 }}>Staking benefits</span>
                            The $ZAYA token enables users to participate in different staking mechanisms. This provides an incentive for users to hold and stake their $ZAYA tokens, supporting the long-term growth and stability of the ecosystem.
                            <br />
                        </Typography>
                        <ul className={classes.benefitsLi}>
                            <li className={classes.listMargin}>
                                <span style={{ fontWeight: 'bold' }}>Classic Staking</span> - In this staking model, users who stake their $ZAYA tokens receive periodic
                                rewards from the tokens allocated during the initial token distribution. This allows token holders to earn staking benefits
                                while gaining exposure to the entire ecosystem.
                            </li>
                            <li>
                                <span style={{ fontWeight: 'bold' }}>Reward Staking</span> - ZayaAI provides tokens that will in turn be distributed to Certificate holders according to their levels,
                                offering a tiered reward system based on the percentage of the Rewards Pool allocated to each level
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xl={6} className = {classes.framesContainer}>
                        <img src={Frame} className={classes.frame2} />
                        <img src={BenefitBack} className={classes.benefitsBack2} />
                    </Grid>
                </Grid>
            </Box>

            <Box className={classes.benefitsContainer} sx={{ paddingBottom: {xs: '3px', md: '40px', xl: '156px'}}}>
                <Grid container>
                    <Box sx={{ order: { xs: 2, md: 1 }, display: 'flex', width: {xs: '100%', lg: '50%'}, flexDirection: 'column', justifyContent: 'center'}}>
                        <Grid item md={12} xl={6} sx={{ position: 'relative', minWidth: '100%', paddingBottom: { xs: '100px', lg: '0'} }}>
                            <Grid container sx={{ justifyContent: {xs: 'center', lg: 'flex-start'}}}>
                                <Grid item md={6} className={classes.percentGrid}>
                                    <Box className={classes.percentBox}>
                                        <Typography className={classes.percentTitle}>
                                            10%
                                        </Typography>
                                        <Typography className={classes.percentContent}>
                                            of the ZAYA main Pool
                                        </Typography>
                                        <Typography className={classes.percentlevel}>
                                            LVL 1
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={6} className={classes.percentGrid}>
                                    <Box className={classes.percentBox}>
                                        <Typography className={classes.percentTitle}>
                                            20%
                                        </Typography>
                                        <Typography className={classes.percentContent}>
                                            of the ZAYA main Pool
                                        </Typography>
                                        <Typography className={classes.percentlevel}>
                                            LVL 2
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ justifyContent: {xs: 'center', lg: 'flex-start'}}}>
                                <Grid item md={6} className={classes.percentGrid}>
                                    <Box className={classes.percentBox} alignItems='end'>
                                        <Typography className={classes.percentTitle}>
                                            30%
                                        </Typography>
                                        <Typography className={classes.percentContent}>
                                            of the ZAYA main Pool
                                        </Typography>
                                        <Typography className={classes.percentlevel}>
                                            LVL 3
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={6} className={classes.percentGrid}>
                                    <Box className={classes.percentBox}>
                                        <Typography className={classes.percentTitle}>
                                            40%
                                        </Typography>
                                        <Typography className={classes.percentContent}>
                                            of the ZAYA main Pool
                                        </Typography>
                                        <Typography className={classes.percentlevel}>
                                            LVL 4
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <img src={BenefitBack} className={classes.certiImg} />
                            <img src={BenefitBack} className={classes.gridMobileBackground} />
                        </Grid>
                    </Box>
                    <Grid item xl={6} className={classes.auctionContainer} order={{ xs: 1, md: 2 }}>
                        <Typography className={classes.benefitHeader}>
                            Certificates & Auctions
                        </Typography>
                        <div className={classes.benefitsDetails}>
                            <p style={{ fontWeight: 'bold' }}>Presentation of ZayaAI certificates</p>
                            Zaya is going to issue a series of certificates, structured to 4 different levels. Zaya ecosystem certificates will be tiered as follows:
                            <br />

                            <ul className={classes.benefitsLi}>
                                <li>
                                    <span style={{ fontWeight: 'bold' }}>LVL 1 Certificate</span>
                                </li>
                                <li>
                                    <span style={{ fontWeight: 'bold' }}>LVL 2 Certificate</span>
                                </li>
                                <li>
                                    <span style={{ fontWeight: 'bold' }}>LVL 3 Certificate</span>
                                </li>
                                <li>
                                    <span style={{ fontWeight: 'bold' }}>LVL 4 Certificate</span>
                                </li>
                            </ul>
                            Certificates holders will receive rewards from the “Classic Staking Pool”, equivalent to the rewards generated through $ZAYA tokens used on purchasing the certificates. In addition to this benefit, certificates holders may periodically receive a share of another specially created pool, the Zaya Main Pool. Find in the left side chart reward generation for this pool:
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.certificatesContainer} id='certificates'>
                <Typography className={classes.benefitHeader}>
                    Certificates benefits
                </Typography>
                <Grid container className={classes.cerGridContainer}>
                    <Grid item xl={3} sm={6} xs={12} className={classes.cerBenefitsGrid}>
                        <Box className={classes.cerBenefitsBox}>
                            <Box className={classes.imgContainer}>
                                <img src={Cer1} />
                            </Box>
                            <Typography className={classes.blueT}>
                                Benefit 1
                            </Typography>
                            <Typography className={classes.cerTitle}>
                                Enchanced Utility
                            </Typography>
                            <Typography className={classes.cerContent}>
                                Staking Certificates enhances token's value and applicability within our ecosystem.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={3} sm={6} xs={12} className={classes.cerBenefitsGrid}>
                        <Box className={classes.cerBenefitsBox}>
                            <Box className={classes.imgContainer}>
                                <img src={Cer2} />
                            </Box>
                            <Typography className={classes.blueT}>
                                Benefit 2
                            </Typography>
                            <Typography className={classes.cerTitle}>
                                Token Scarcity
                            </Typography>
                            <Typography className={classes.cerContent}>
                                Token burning through Certificates mechanism amplifies scarcity, raising its value
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={3} sm={6} xs={12} className={classes.cerBenefitsGrid}>
                        <Box className={classes.cerBenefitsBox}>
                            <Box className={classes.imgContainer}>
                                <img src={Cer3} />
                            </Box>
                            <Typography className={classes.blueT}>
                                Benefit 3
                            </Typography>
                            <Typography className={classes.cerTitle}>
                                Engaging Rewards
                            </Typography>
                            <Typography className={classes.cerContent}>
                                Our tiered staking motivates users to keep high-level Certificates, boosting token usage.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={3} sm={6} xs={12} className={classes.cerBenefitsGrid}>
                        <Box className={classes.cerBenefitsBox}>
                            <Box className={classes.imgContainer}>
                                <img src={Cer4} />
                            </Box>
                            <Typography className={classes.blueT}>
                                Benefit 4
                            </Typography>
                            <Typography className={classes.cerTitle}>
                                Access to Platinum Club
                            </Typography>
                            <Typography className={classes.cerContent}>
                                Certificates facilitate your access to the Platinum Club. In addition to other benefits, you can own a share of Zaya AI.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <FAQ />
            <Blog />
        </Box>
    );
};

export { Home };
