import BigNumber from 'bignumber.js';
import { claimTokens } from './claimTokens';
import { getClaimableAmount } from './getClaimableAmount';
import { ParsedSchedule, getInvestorData } from './getInvestorData';
import { getIsInMaintenance } from './getIsInMaintenance';
import { bigNumberToFloat } from './utils';

type InvestorInfo = {
  claimable: number;
  vested: number;
  locked: number;
  claimed: number;
};
function parseInvestorData(data: ParsedSchedule[]): {
  vested: number;
  locked: number;
  claimed: number;
} {
  const stats = {
    vested: 0,
    locked: 0,
    claimed: 0
  };

  data.map((schedule) => {
    const vested = bigNumberToFloat(
      BigNumber(schedule.stats.vested.toString()),
      18
    );
    const claimed = bigNumberToFloat(
      BigNumber(schedule.stats.claimed.toString()),
      18
    );

    stats.vested += vested;
    stats.claimed += claimed;
    stats.locked += vested - claimed;
  });

  return stats;
}

const sharedConfig = {
  contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS as string,
  networkProviderUrl: 'https://api.multiversx.com',
  chainId: '1',
  gasLimit: 50000000
};

export async function getInvestorInfo(
  walletAddress: string
): Promise<InvestorInfo> {
  const claimable = await getClaimableAmount({
    config: {
      ...sharedConfig
    },
    investorAddress: walletAddress
  });

  const userData = await getInvestorData({
    config: {
      ...sharedConfig
    },
    investorAddress: walletAddress
  });

  const stats = parseInvestorData(userData);

  return {
    claimable: claimable,
    vested: stats.vested,
    locked: stats.locked,
    claimed: stats.claimed
  };
}

export async function claim(walletAddress: string) {
  await claimTokens({
    config: {
      ...sharedConfig,
      callerAddress: walletAddress
    }
  });
}

export async function getMaintenanceStatus() {
  return getIsInMaintenance({
    config: {
      ...sharedConfig
    }
  });
}
