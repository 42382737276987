import { Box, Typography } from '@mui/material';
import styles from './styles.module.css';

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        pt: { xs: '150px', lg: '200px' },
        px: { xs: '26px', md: '50px', lg: '100px' }
      }}
    >
      <Typography
        variant='h2'
        sx={{
          fontSize: { xs: '40px', md: '50px' },
          fontWeight: '600 !important',
          textAlign: 'center'
        }}
      >
        PRIVACY NOTICE
      </Typography>
      <Typography
        variant='h2'
        sx={{
          fontSize: { xs: '35px', md: '40px' },
          fontWeight: '500 !important',
          textAlign: 'center'
        }}
      >
        ZAYA APP or ZAYA PLATFORM
      </Typography>
      <p style={{ textIndent: '0pt', textAlign: 'left' }}>
        <br />
      </p>
      <p
        style={{
          paddingTop: '9pt',
          paddingLeft: '6pt',
          textIndent: '0pt',
          textAlign: 'justify'
        }}
      >
        The following Privacy Notice was designed for Zaya App/Zaya Platform and
        all the subsequent web pages, web forms and other kind of research and
        enquiries of data and it will be reviewed and updated periodically
        according to all applicable laws and regulations.
      </p>
      <p
        style={{
          paddingTop: '6pt',
          paddingLeft: '6pt',
          textIndent: '0pt',
          textAlign: 'justify'
        }}
      >
        The purpose of this Privacy Notice is to easily inform you about:
      </p>
      <ol id='l1' style={{ marginTop: '30px'}}>
        <li data-list-text='0.'>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '24pt',
              textIndent: '-18pt',
              textAlign: 'left'
            }}
          >
            0. The definitions of the terms provided by the GDPR
          </h2>
        </li>
        <li data-list-text='1.'>
          <h2
            style={{
              paddingTop: '5pt',
              paddingLeft: '24pt',
              textIndent: '-18pt',
              textAlign: 'left'
            }}
          >
            1. Who is Zaya App
          </h2>
        </li>
        <li data-list-text='2.'>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '24pt',
              textIndent: '-18pt',
              textAlign: 'left'
            }}
          >
            2. Where can you find us and how can you contact us
          </h2>
        </li>
        <li data-list-text='3.'>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '24pt',
              textIndent: '-18pt',
              textAlign: 'left'
            }}
          >
            3. What Personal Data Zaya may process about you, how your Personal
            Data are processed via Zaya App, the purpose, legal basis and period
            of the processing
          </h2>
        </li>
        <li data-list-text='4.'>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '24pt',
              textIndent: '-18pt',
              textAlign: 'left'
            }}
          >
            4. The disclosure of your Personal Data to third parties
          </h2>
        </li>
        <li data-list-text='5.'>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '24pt',
              textIndent: '-18pt',
              textAlign: 'left'
            }}
          >
            5. Which are your rights and how can you effectively exercise them
          </h2>
        </li>
        <li data-list-text='6.'>
          <h2
            style={{
              paddingTop: '5pt',
              paddingLeft: '24pt',
              textIndent: '-18pt',
              textAlign: 'left'
            }}
          >
            6. Children’s Personal Data
            <span className={styles.h1}>
              – we do not process data for children under 16 years old
            </span>
          </h2>
        </li>
        <li data-list-text='7.'>
          <h2
            style={{
              paddingTop: '5pt',
              paddingLeft: '24pt',
              textIndent: '-18pt',
              textAlign: 'left'
            }}
          >
            7. What security precautions does Zaya App have in place to protect
            your Personal Data
          </h2>
        </li>
        <li data-list-text='8.'>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '24pt',
              textIndent: '-18pt',
              textAlign: 'left'
            }}
          >
            8. Links to other websites
          </h2>
        </li>
        <li data-list-text='9.'>
          <h2
            style={{
              paddingTop: '5pt',
              paddingLeft: '24pt',
              textIndent: '-18pt',
              textAlign: 'left'
            }}
          >
            9. Updates to the privacy policy
          </h2>
        </li>
        <li data-list-text='10.'>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '24pt',
              textIndent: '-18pt',
              textAlign: 'left'
            }}
          >
            10. Information concerning Data Protection Supervisory Authority
          </h2>
        </li>
      </ol>
      <p style={{ textIndent: '0pt', textAlign: 'left' }}>
        <br />
      </p>
      <ol id='l2'>
        <li data-list-text='0.'>
          <h2
            style={{
              paddingTop: '9pt',
              paddingLeft: '33pt',
              textIndent: '-28pt',
              textAlign: 'justify',
            }}
          >
           0. Definitions according to the GDPR
          </h2>
          <h1
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify',
            }}
          >
            NSAPDP
            <p>
              represents The National Supervisory Authority for Personal Data
              Processing, the Romanian independent public authority responsible
              for the compliance with the protection of Personal Data
              requirements;
            </p>
          </h1>
          <h1
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Personal Data
            <p>
              represents any information relating to an identified or
              identifiable natural person (‘data subject’); an identifiable
              natural person is one who can be identified, directly or
              indirectly, in particular by reference to an identifier such as a
              name, an identification number, location data, an online
              identifier or to one or more factors specific to the physical,
              physiological, genetic, mental, economic, cultural or social
              identity of that natural person;
            </p>
          </h1>
          <h1
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Processing
            <p>
              represents any operation or set of operations which is performed
              on Personal Data or on sets of Personal Data, whether or not by
              automated means, such as collection, recording, organization,
              structuring, storage, adaptation or alteration, retrieval,
              consultation, use, disclosure by transmission, dissemination or
              otherwise making available, alignment or combination, restriction,
              erasure or destruction;
            </p>
          </h1>
          <h1
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Restriction of processing
            <p>
              represents the marking of stored Personal Data with the aim of
              limiting their processing in the future;
            </p>
          </h1>
          <h1
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Controller
            <p>
              represents the natural or legal person, public authority, agency
              or other body which, alone or jointly with others, determines the
              purposes and means of the processing of Personal Data; where the
              purposes and means of such processing are determined by the
              European Union or Member State law, the controller or the specific
              criteria for its nomination may be provided for by Union or Member
              State law;
            </p>
          </h1>
          <h1
            style={{
              paddingTop: '4pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Processor
            <p>
              represents a natural or legal person, public authority, agency or
              other body which processes Personal Data on behalf of the
              controller;
            </p>
          </h1>
          <h1
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Recipient
            <p>
              represents a natural or legal person, public authority, agency or
              another body, to which the Personal Data are disclosed, whether it
              is a third party or not. However, public authorities which may
              receive Personal Data in the framework of a particular inquiry in
              accordance with the European Union or Member State law shall not
              be regarded as recipients; the processing of those data by those
              public authorities shall be in compliance with the applicable data
              protection rules according to the purposes of the processing;
            </p>
          </h1>
          <h1
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Third party
            <p>
              a natural or legal person, public authority, agency or body other
              than the data subject, controller, processor and persons who,
              under the direct authority of the controller or processor, are
              authorized to process Personal Data;
            </p>
          </h1>
          <h1
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Data Breach
            <p>
              represents a breach of security leading to the accidental or
              unlawful destruction, loss, alteration, unauthorized disclosure
              of, or access to, Personal Data. This means that a breach is more
              than just losing Personal Data.
            </p>
          </h1>
        </li>
        <li data-list-text='1.'>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '-28pt',
              textAlign: 'justify'
            }}
          >
            1. Who is Zaya App or the Platform
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            ZAYA BLOCKCHAIN S.R.L. (hereinafter referred to as “we”, “our”,
            „Zaya”) is the owner of the web application titled “Zaya App”
            available at the following address: https://zayablockchain.com/ (”
            <b>Zaya App</b>” or the ”<b>Platform</b>)”.
          </p>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            According to the GDPR Regulation, considering the Personal Data
            Processed through our Platform, we are considered data Controller
            and our visitors and users are considered data subjects (“
            <b>Data Subject</b>”).
          </p>
          <p
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            We are fully committed to observe the confidentiality and security
            of your Personal Data by ensuring that all Personal Data are
            processed only for specific, explicit and legal purposes, according
            with the principles and provisions of the GDPR.
          </p>
        </li>
        <li data-list-text='2.'>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '-28pt',
              textAlign: 'justify'
            }}
          >
            2. Where can you find us and how you can contact us
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            <a
              href='mailto:contact@zaya.ai'
              className={styles.s1}
              target='_blank'
            >
              With respect to any information regarding your Personal Data that
              we Process, feel free to contact us via email{' '}
            </a>
            <a
              href='mailto:contact@zaya.ai'
              className={styles.a}
              target='_blank'
            >
              contact@zaya.ai
            </a>
            <a
              href='mailto:contact@zaya.ai'
              className={styles.s1}
              target='_blank'
            >
              .
            </a>
          </p>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            What Personal Data Zaya may Process about you, how your Personal
            Data are Processed via Zaya App, the purpose, legal basis and the
            period of the Processing
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            At Zaya, we place a high priority on protecting your rights over the
            Personal Data, thus in addition to complying with all relevant
            regulations, we have adopted industry-leading best practices for
            Processing of Personal Data. Our approach involves collecting
            Personal Data directly from you - which gives you greater control
            over the information you desire to provide - and any Personal Data
            that we collect to be processed only for the specific purposes
            outlined in this policy.
          </p>
          <p
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Furthermore, we have implemented rigorous security measures to
            safeguard your Personal Data and prevent unauthorized access or
            unintentional disclosure.
          </p>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Please bear in mind that Personal Data is considered any data that
            could directly or indirectly identify you, as a person. Although a
            Personal Data such as your professional skills/information related
            to your education are not considered, per se, Personal Data because
            it could not lead directly to your identification, such data,
            combined with other data (your name, username, e-mail address, the
            description provided on your profile) could eventually identify you.
          </p>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            To easily identify the Personal Data we Process, we have combined
            them in several categories according to the purpose of Processing,
            as follows:
          </p>
          <p style={{ textIndent: '0pt', textAlign: 'left' }}>
            <br />
          </p>
          <h2
            style={{
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            CATEGORY 1 – USING ZAYA APP AS A VISITOR
          </h2>
          <h2
            style={{
              paddingTop: '4pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Personal Data
            <p>
              – your IP address, type and version of your Internet browser,
              operating system used, the page accessed, the site accessed before
              visiting the site (referrer URL), date and time of the visit,
              Social Media preferences, ads that raised your interest;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The purpose of Processing
            <p>
              – the Personal Data are Processed with the purpose of offering you
              the best experience with us and with our Platform;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The legal Basis
            <p>
              – art. 6 para. 1 letter b) of the GDPR Regulation, which allows us
              to process Personal Data, when necessary, for the performance of a
              contract;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The collection method
            <p>- directly from you by accessing our Platform;</p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The retention period
            <p>
              – usually, the retention period for these Personal Data depends on
              each type of cookie deployed on the Platform, as per our Cookie
              Policy;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'left'
            }}
          >
            CATEGORY 2 – ACQUIRING ZAYA TOKENS VIA THE PLATFORM
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Personal Data
            <p>
              – data processed for KYC approval: first name, last name, date and
              place of birth, nationality, place of residence, ID type, ID
              number and series, photograph, e-mail address; approved KYC
              status, your public address related with your electronic wallet,
              the purchased number of tokens;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Indirect Data
            <p>
              – following the interaction with our Platform and given our
              contractual relationship, we come into contact with a range of
              data that could indirectly lead to your identification such us
              your interest for Web 3 field or your financial status, given the
              value and number of the purchased tokens but also your other
              assets from your electronic wallet;
            </p>
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'left'
            }}
          >
            TO AVOID ANY MISUNDERSTANDING, PERSONAL DATA INDIRECTLY COLLECTED
            MAY RESULT FROM YOUR ACTIVITY AND WE DO NOT SPECIFICALLY INTERROGATE
            YOUR WALLET;
          </p>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The purpose of Processing
            <p>
              – the Personal Data are Processed for allowing you to purchase the
              desired quantity of $ZAYA Tokens;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The legal Basis
            <p>
              – art. 6 para. 1 letter b) of the GDPR Regulation, which allows us
              to process Personal Data, when necessary, for the performance of a
              contract;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The collection method
            <p>
              - directly from you, filling in the data necessary for KYC
              approval when hitting the “Begin KYC process” button, and also
              when you go through the token purchase process available on the
              Platform;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The retention period
            <p>
              – your Personal Data is retained and Processed for a period of
              approximately 5 years as it necessary to manage our contractual
              relationship;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'left'
            }}
          >
            CATEGORY 3 – SOCIAL MEDIA PLATFORMS
          </h2>
          <h2
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Personal Data
            <p>
              – related to each user, such as: user’s social media accounts; any
              other information users decide to provide us with when they
              contact us on the social media platforms; any other information
              users decide to provide us with when they contact us by e-mail;
              comments and/or posts on our profiles;
            </p>
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Given that the internet is not a safe space, please do not send us
            or limit, as much as possible, the Personal Data communicated
            through social platforms or e-mail;
          </p>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The purpose of Processing
            <p>
              – the Personal Data mentioned above are Processed for customer
              support purposes;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The legal Basis:
            <p>
              Art. 6 para. 1 letter b) of the GDPR Regulation, which allows us
              to Process Personal Data when necessary, for performance of a
              contract or for the steps prior to its conclusion;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The collection method
            <p>
              – Personal Data are collected directly from users when they decide
              to contact us through social media platforms;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '4pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The retention period:
            <p>
              Personal Data are stored for the purpose of proving the fulfilment
              of contractual obligations between the parties for a period
              between 30 days and 1 year, depending on the nature of the request
              (complaint, request for guarantee, contractual request, general
              request, etc.);
            </p>
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Generally, Personal Data are kept for a limited period according to
            the purpose of the Processing and the legal provisions applicable to
            each category of data;
          </p>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            CATEGORY 4 – SUBSCRIBE TO OUR NEWSLETTER
          </h2>
          <h2
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Personal Data <p>– e-mail address;</p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The purpose of Processing
            <p>
              – the Personal Data are Processed for the purpose of allowing you
              to be up to date with the Zaya’s activity and news and to manage
              your subscription, including to send you personalised information
              about our products or services;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The legal basis
            <p>
              – art. 6 para. 1 letter a) of the GDPR Regulation, which allows us
              to process Personal Data based on your consent given to us;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              lineHeight: '14pt',
              textAlign: 'justify'
            }}
          >
            The collection method
            <p>
              - directly from you, when you fill in your e-mail address and
              check the box
            </p>
          </h2>
          <p
            style={{
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            “Subscribe” in the section “Subscribe to our newsletter” available
            on our Platform;
          </p>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The retention period
            <p>
              – as a rule we try to keep less data as possible, and in this
              case, we keeping your Personal Data as long as you are subscribed
              to the newsletter.
            </p>
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Please note that if you have opted in for our newsletter you can
            unsubscribe from the newsletter either by pressing the unsubscribe
            button included in our e-mails or you can contact us at the e-mail
            address included in this Privacy Policy.
          </p>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Generally, Personal Data are kept for a limited period according to
            the purpose of the Processing and the legal provisions applicable to
            each category of data.
          </p>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            CATEGORY 5 – CONTACT US
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Personal Data
            <p>
              – first name, last name, e-mail address and any other Personal
              Data that you may include in your message;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The purpose of processing
            <p>– the Personal Data are Processed for user support purposes;</p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The legal Basis
            <p>
              – art. 6 para. 1 letter b) of the GDPR Regulation, which allows us
              to Process Personal Data, when necessary, for the performance of a
              contract;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The collection method
            <p>
              - directly from the recipients by submitting the message through
              our contact form available on our Platform;
            </p>
          </h2>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            The retention period
            <p>
              – Personal Data are stored for the purpose of proving the
              fulfilment of contractual obligations between the parties for a
              period between 30 days and 1 year, depending on the nature of the
              request (complaint, information request, contractual request,
              general request, etc.);
            </p>
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Generally, Personal Data are kept for a limited period according to
            the purpose of the processing and the legal provisions applicable to
            each category of data.
          </p>
        </li>
        <li data-list-text='3.'>
          <h2
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '-28pt',
              textAlign: 'justify'
            }}
          >
            3. The disclosure of your Personal Data to third - parties
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Throughout our normal course of business, we will not disclose or
            transfer, for direct marketing purposes, your Personal Data to third
            parties, regardless if such parties are located in Romania, in EU or
            outside EU.
          </p>
          <p
            className={styles.s2}
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Our employees
          </p>
          <p
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Zaya’s employees having access to Personal Data have been trained to
            observe the security and confidentiality of the Personal Data they
            have access to in performing the business activity. Zaya’s
            employees’ access to Personal Data is limited to the information
            required in performing their specific tasks.
          </p>
          <p
            className={styles.s2}
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'left'
            }}
          >
            Suppliers
          </p>
          <p
            style={{
              paddingTop: '4pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            We perform our daily activities at the highest standards thus
            sometimes we chose to cooperate with other companies in order to
            facilitate several technical or administrative processes such as:
            e-mail hosting services, storing data, sever hosting, legal services
            etc.
          </p>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Thus, we may engage in contractual relationships with suppliers that
            are not established in the European Economic Area (EEA) and such
            circumstances could entail the disclosure of your Personal Data
            outside EEA.
          </p>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            However, we are fully committed to safeguarding the confidentiality
            and security of your Personal Data. In cases where we need to
            disclose your Personal Data to third-party suppliers, we will verify
            if each supplier complies with the GDPR Regulation and has
            implemented sufficient measures to protect the Personal Data that
            they may receive from us.
          </p>
          <p
            className={styles.s2}
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Legal requirements
          </p>
          <p
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Your Personal Data may be communicated to governmental authorities
            and/or law enforcement agencies if required by the applicable law.
          </p>
        </li>
        <li data-list-text='4.'>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '-28pt',
              textAlign: 'justify'
            }}
          >
            4, Which are your rights and how can you effectively exercise them
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Zaya as data controller, ensures technical and organizational
            measures to be sure that your rights (as a data subject) are
            observed:
          </p>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Right of access
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            You have the right to obtain the confirmation as to whether or not
            Personal Data concerning you are being processed by us, and, where
            that is the case, access to your Personal Data and information on
            how they are processed.
          </p>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Right to data portability
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            You have the right to receive some of your Personal Data, which you
            have provided to us, in a structured, commonly used and
            machine-readable format and you have also the right to transmit
            those data to another controller without hindrance from us, where
            technically feasible.
          </p>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Right to object
          </h2>
          <p
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            You have the right to object to processing of your Personal Data,
            when processing is necessary for the performance of a task carried
            out in the public interest or for the purposes of the legitimate
            interests pursued by us. You have the right to object at any time if
            your Personal Data are being processed for direct marketing
            purposes.
          </p>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Right to rectification
          </h2>
          <p
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            You have the right to obtain from us without undue delay the
            rectification of inaccurate Personal Data concerning you. The
            rectification shall be communicated to each recipient to whom the
            data was sent unless this proves impossible or involves
            disproportionate (demonstrable) efforts.
          </p>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Right to erasure (‘right to be forgotten’)
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            You have the right to obtain from us the erasure of Personal Data
            concerning you without undue delay and we have the obligation to
            erase your Personal Data without undue delay where one of the
            following grounds applies: your Personal Data are no longer
            necessary in relation to the purposes for which they were collected
            or otherwise processed; you withdraws consent on which the
            processing is based and there is no other legal ground for the
            processing; you objects to the processing and there are no
            overriding legitimate grounds for the processing; your Personal Data
            have been unlawfully processed; your Personal Data have to be erased
            for compliance with a legal obligation; your Personal Data have been
            collected in relation to the offer of information society services.
          </p>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Right to restriction of processing
          </h2>
          <p
            style={{
              paddingTop: '4pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            You have the right to obtain from us restriction of processing where
            one of the following applies: you contest the accuracy of your
            Personal Data, for a period enabling us to verify the accuracy of
            your Personal Data; the processing is unlawful and you oppose the
            erasure of your Personal Data and request the restriction of their
            use instead; we no longer need your Personal Data for the purposes
            of the processing, but they are required by you for the
            establishment, exercise or defence of legal claims; you has objected
            to processing pending the verification whether the legitimate
            grounds of the controller override those of the data subject.
          </p>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            Right not to be subject to a decision based solely on automated
            processing
          </h2>
          <p
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            You have the right not to be subject to a decision solely based on
            automated processing, including profiling, which produces legal
            effects concerning the data subject or similarly affects the data
            subject in a significant manner. Therefore, we hereby state that
            Zaya does not use applications, algorithms, artificial intelligence
            or automatic process to make automatic decisions (without human
            intervention) that produces legal effects.
          </p>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            <a
              href='mailto:contact@zaya.ai'
              className={styles.s1}
              target='_blank'
            >
              The exercising of the above rights may be performed at any time.
              For using these rights, we encourage you to submit your written
              request (together with your contact details) in electronic format
              by mail at contact@zaya.ai
            </a>
          </p>
        </li>
        <li data-list-text='5.'>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '-28pt',
              textAlign: 'justify'
            }}
          >
            5. Children’s Personal Data
          </h2>
          <p
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              lineHeight: '144%',
              textAlign: 'justify'
            }}
          >
            Zaya does not collect any Personal Data from children under the age
            of 16. So, if you are under 16 please do not submit to us any
            Personal Data.
          </p>
        </li>
        <li data-list-text='6.'>
          <h2
            style={{
              paddingLeft: '33pt',
              textIndent: '-28pt',
              textAlign: 'justify'
            }}
          >
            6. What security precautions does Zaya takes to protect your Personal
            Data
          </h2>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            We have assumed the responsibility to implement proper technical and
            organizational measures regarding the protection of privacy and
            security of your Personal Data. We have taken all reasonable
            measures to protect your Personal Data from damage, loss, misuse,
            unauthorized access, alteration, destruction, or disclosure, as
            following:
          </p>
          <ol id='l3'>
            <li data-list-text='a.'>
              <p
                style={{
                  paddingTop: '6pt',
                  paddingLeft: '56pt',
                  textIndent: '-18pt',
                  textAlign: 'justify'
                }}
              >
                People who have access to our filing system are only those
                nominated by Zaya. To accesses the system, they use individual
                accounts and passwords which are changed periodically.
              </p>
            </li>
            <li data-list-text='b.'>
              <p
                style={{
                  paddingTop: '6pt',
                  paddingLeft: '56pt',
                  textIndent: '-18pt',
                  textAlign: 'justify'
                }}
              >
                All our employees, collaborators and service providers who are
                in contact with Personal Data must act in accordance with the
                principles and policies regarding to the processing of Personal
                Data. They were informed and they have assumed to respect of the
                GDPR by signing the Data Processing Agreements or as an effect
                of the law.
              </p>
            </li>
            <li data-list-text='c.'>
              <p
                style={{
                  paddingTop: '6pt',
                  paddingLeft: '56pt',
                  textIndent: '-18pt',
                  textAlign: 'justify'
                }}
              >
                our employees and collaborators access Personal Data for the
                performance of their professional duties and only in accordance
                with the stated purpose of data collection.
              </p>
            </li>
            <li data-list-text='d.'>
              <p
                style={{
                  paddingTop: '6pt',
                  paddingLeft: '56pt',
                  textIndent: '-18pt',
                  textAlign: 'justify'
                }}
              >
                Computers from which the filing system is accessed are
                password-protected and have antivirus, antispam and firewall
                security updates.
              </p>
            </li>
            <li data-list-text='e.'>
              <p
                style={{
                  paddingTop: '6pt',
                  paddingLeft: '56pt',
                  textIndent: '-18pt',
                  textAlign: 'justify'
                }}
              >
                Personal Data is printed only by authorized users, if it is
                necessary to perform our activity or to fulfil our legal
                obligations.
              </p>
              <p
                style={{
                  paddingTop: '6pt',
                  paddingLeft: '56pt',
                  textIndent: '-18pt',
                  textAlign: 'justify'
                }}
              >
                Please also select carefully what Personal Data do you choose to
                submit thinking that the internet or e-mails are not
                impenetrable spaces, and a technical error can cause an unhappy
                event anytime with respect to your Personal Data.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text='7.'>
          <h2
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '-28pt',
              textAlign: 'justify'
            }}
          >
            7. Links to other websites
          </h2>
          <p
            style={{
              paddingTop: '5pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            On our website you may find links to other organizations. This
            Privacy Notice do not cover the Personal Data processed by them.
          </p>
          <p
            style={{
              paddingTop: '6pt',
              paddingLeft: '33pt',
              textIndent: '0pt',
              textAlign: 'justify'
            }}
          >
            If you decide to access other organization’s links, we encourage you
            to carefully read their Privacy Notices which should be found on
            their websites. In general, the Privacy Notice may be accessed on
            the bottom section of the website.
          </p>
        </li>
        <li data-list-text='8.'>
          <h2
            style={{
              paddingTop: '4pt',
              paddingLeft: '33pt',
              textIndent: '-28pt',
              textAlign: 'justify'
            }}
          >
            8. Updates to the Privacy Policy
          </h2>
        </li>
      </ol>
      <div style={{ paddingLeft: '40px'}}>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '33pt',
            textIndent: '0pt',
            textAlign: 'justify'
          }}
        >
          Believing that we are constantly developing our services, we are
          confident that our Platform may soon have new functions, so our Privacy
          Notice will be updated accordingly.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '33pt',
            textIndent: '0pt',
            textAlign: 'justify'
          }}
        >
          In order to keep you informed, we always publish the latest version of
          the Privacy Notice on our website, without any specific notice in this
          respect.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '33pt',
            textIndent: '0pt',
            textAlign: 'justify'
          }}
        >
          We assure you that the way we collect and process your Personal Data is
          in accordance with the provisions of the GDPR Regulation.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '33pt',
            textIndent: '0pt',
            textAlign: 'justify'
          }}
        >
          We encourage you to constantly review this Privacy Policy in order to be
          constantly informed with respect to the categories, purposes and manners
          Zaya processes your Personal Data.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '33pt',
            textIndent: '0pt',
            textAlign: 'justify'
          }}
        >
          <a href='mailto:contact@zaya.ai' className={styles.s1} target='_blank'>
            If you have any questions about our Privacy Policy, please contact to
            us at{' '}
          </a>
          <a href='mailto:contact@zaya.ai' target='_blank'>
            contact@zaya.ai
          </a>
        </p>
        <h2
          style={{
            paddingTop: '6pt',
            paddingLeft: '33pt',
            textIndent: '0pt',
            textAlign: 'justify'
          }}
        >
          Information concerning Data Protection Supervisory Authority
        </h2>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '33pt',
            textIndent: '0pt',
            textAlign: 'justify'
          }}
        >
          If you consider that your rights provided by Regulation no 679/2016 have
          been violated, you can address directly to us or to our Data Protection
          Supervisory Authority: National Authority for the Supervision of the
          Processing of Personal Data (Autoritatea Naţională de Supraveghere a
          Prelucrării Datelor cu Caracter Personal) ”ANSPDC” by submitting a
          complaint.
        </p>
        <h2
          style={{
            paddingTop: '6pt',
            paddingLeft: '33pt',
            textIndent: '0pt',
            textAlign: 'justify'
          }}
        >
          Contact details of the authority:
        </h2>
        <p
          className={styles.s3}
          style={{
            paddingTop: '6pt',
            paddingLeft: '33pt',
            textIndent: '0pt',
            lineHeight: '144%',
            textAlign: 'left'
          }}
        >
          <a
            href='https://www.dataprotection.ro/?page=Plangeri_pagina_principala'
            className={styles.s1}
            target='_blank'
          >
            Link for compliances:{' '}
          </a>
          <a
            href='https://www.dataprotection.ro/?page=Plangeri_pagina_principala'
            className={styles.a}
            target='_blank'
          >
            https://www.dataprotection.ro/?page=Plangeri_pagina_principala
          </a>
          <br />
          <a
            href='https://www.dataprotection.ro/?page=contact&amp;lang=ro'
            className={styles.s1}
            target='_blank'
          >
            Contact link:{' '}
          </a>
          <a
            href='https://www.dataprotection.ro/?page=contact&amp;lang=ro'
            target='_blank'
          >
            https://www.dataprotection.ro/?page=contact&amp;lang=ro
          </a>
        </p>
        <p style={{ paddingLeft: '33pt', textIndent: '0pt', textAlign: 'left' }}>
          <a
            href='https://www.dataprotection.ro/'
            className={styles.s1}
            target='_blank'
          >
            Website:{' '}
          </a>
          <a href='https://www.dataprotection.ro/' target='_blank'>
            https://www.dataprotection.ro/
          </a>
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '33pt',
            textIndent: '0pt',
            textAlign: 'left'
          }}
        >
          Address: B-dul G-ral. Gheorghe Magheru 28-30, Sector 1, cod postal
          010336, Bucuresti, Romania
        </p>
      </div>
    </Box>
  );
};

export { PrivacyPolicy };
