import { Address, SmartContract } from '@multiversx/sdk-core';

import { ContractScheduleRelease, ContractVestingSchedule } from './contractTypes';
import { queryContract, loadAbi, ZayaContracts } from './utils';

export type ParsedSchedule = {
  schedule_type: string,
  stats: {
    claimed: string,
    vested: string
  },
  releases: {
    when: string,
    amount: string,
    claimed: string
  }[]
}

export async function getInvestorData (props: {config: {
    contractAddress: string,
    networkProviderUrl: string
  }, investorAddress: string}): Promise<ParsedSchedule[]> {
  const { config, investorAddress } = props;
  const {contractAddress, networkProviderUrl} = config;

  const abi = await loadAbi({contractType: ZayaContracts.VestingContract});

  const contract = new SmartContract({
    address: Address.fromBech32(contractAddress),
    abi
  });

  const res = await queryContract({
    contract,
    method: 'getInvestorData',
    args: [investorAddress],
    networkProviderUrl: networkProviderUrl
  });

  const schedules = res.firstValue?.valueOf() as ContractVestingSchedule[];
  const parsedSchedules = schedules.map((schedule) => {
    return {
      schedule_type: schedule.schedule_type.toString(),
      stats: {
        claimed: schedule.stats.claimed.toString(),
        vested: schedule.stats.vested.toString()
      },
      releases: schedule.releases.map((release: ContractScheduleRelease) => {
        return {
          when: release.when.toString(),
          amount: release.amount.toString(),
          claimed: release.claimed.toString()
        };
      })
    };
  });

  return parsedSchedules;
}