import React, { } from 'react';
import {
    Box, Container, Grid, Typography
} from '@mui/material';


import {
    createStyles, makeStyles
} from '@mui/styles';

import Bellow from '../../assets/img/About/bellow.png';


const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            padding: '2rem 4rem',
            width: '100%',
            background: '#20C2E5',
            borderRadius: '20px',
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
            marginTop: '1rem',
            height: '240px',
            color: 'white',
            position: 'relative'
        },
        header: {
            fontSize: '25px !important',
            fontWeight: '600 !important',
            marginLeft: '1rem !important'
        },
        content: {
            fontSize: '15px !important',
            color: 'white',
            fontWeight: '400 !important',
            marginTop: '1.5rem !important'
        },
        img: {
            width: '40px'
        },
        imgContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        underbox: {
            width: '80%',
            height: '30px',
            background: 'white',
            position: 'absolute',
            bottom: '0px',
            left: '50%',
            transform: 'translateX(-50%)',
            borderRadius: '20px 20px 0 0'
        }
    })
);

interface Props {
    iamgeSRC: string,
    header: string,
    content: string
}

const Step = ({

    iamgeSRC, header, content

}: Props) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Box className={classes.imgContainer}>
                <img src={iamgeSRC} className={classes.img} />
                <Typography className={classes.header}>
                    {header}
                </Typography>
            </Box>
            <Typography className={classes.content}>
                {content}
            </Typography>
            <Box className = {classes.underbox}>
            </Box>
        </Box>
    );
};

export { Step };
