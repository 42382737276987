import React, { } from 'react';
import { Box } from '@mui/material';
import {
    createStyles, makeStyles
} from '@mui/styles';

import { format } from 'date-fns';
import parse from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            position: 'relative',
            padding: '1.5rem',
            margin: '1rem',
            borderRadius: '20px',
            background: '#FFF',
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
            height: '95%',
            [theme.breakpoints.down('sm')]: {
                minWidth: '340px',
                margin: 0,
                height: '100%',
            }
        },
        back: {
            borderRadius: '10px',
            height: '265px',
            width: '100%',
            objectFit: 'scale-down'
        },
        header: {
            color: '#16374D',
            fontSize: '20px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal'
        },
        title: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
            marginBottom: '5px'
        },
        desc: {
            fontSize: '0.9rem',
            fontWeight: '400 !important',
            marginBottom: '0 !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
            }
        },
        img: {
            width: '100%'
        },
        textBody: {
            textAlign: 'left',
            padding: '1rem'
        },
        iconImg: {
            marginTop: '20px',
            width: '25px',
            height: '25px'
        },
        read: {
            color: '#20C2E5',
            textDecoration: 'none !important',
            '&:hover': {
                color: '#20C2E5 !important'
            }
        },
        arrow: {
            width: '15px',
            marginLeft: '15px'
        },
        date: {
            color: '#A4A4A4',
            marginTop: '1rem'
        }
    })
);
interface Props {
    date: string,
    header: string,
    content: string,
    url: string,
    image: string
}

const New = ({
    date, header, content, url, image
}: Props) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <img src = {image}  className={classes.back} />
            <Box sx = {{ height: 'calc(100% - 265px)', color: 'black', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <p className={classes.date}>{format(new Date(date), 'MMMM d, yyyy')}</p>
                <div className={classes.title}>{parse(DOMPurify.sanitize(header))}</div>
                <div className={classes.desc}>{parse(DOMPurify.sanitize(content))}</div>
                <a href={url} target='_blank' className={classes.read}>
                    Read More
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={classes.arrow}>
                        <path d="M5 13.0002L16.86 13.0002L13.23 17.3602C13.0603 17.5644 12.9786 17.8277 13.003 18.0921C13.0274 18.3566 13.1558 18.6005 13.36 18.7702C13.5642 18.94 13.8275 19.0216 14.0919 18.9972C14.3563 18.9729 14.6003 18.8444 14.77 18.6402L19.77 12.6402C19.8036 12.5925 19.8337 12.5424 19.86 12.4902C19.86 12.4402 19.86 12.4102 19.93 12.3602C19.9753 12.2456 19.9991 12.1235 20 12.0002C19.9991 11.8769 19.9753 11.7549 19.93 11.6402C19.93 11.5902 19.93 11.5602 19.86 11.5102C19.8337 11.4581 19.8036 11.4079 19.77 11.3602L14.77 5.36021C14.676 5.24733 14.5582 5.15655 14.4252 5.09433C14.2921 5.03211 14.1469 4.99997 14 5.00021C13.7663 4.99976 13.5399 5.08113 13.36 5.23021C13.2587 5.31416 13.175 5.41726 13.1137 5.53361C13.0523 5.64996 13.0145 5.77726 13.0025 5.90824C12.9904 6.03922 13.0043 6.17129 13.0433 6.29689C13.0824 6.4225 13.1458 6.53916 13.23 6.64021L16.86 11.0002L5 11.0002C4.73478 11.0002 4.48043 11.1056 4.29289 11.2931C4.10536 11.4806 4 11.735 4 12.0002C4 12.2654 4.10536 12.5198 4.29289 12.7073C4.48043 12.8949 4.73478 13.0002 5 13.0002Z" fill="#20C2E5"/>
                    </svg>
                </a>
            </Box>
        </Box>
    );
};

export { New };
