import {
  Address,
  ITokenTransfer,
  SmartContract,
  TokenTransfer
} from '@multiversx/sdk-core';

import BigNumber from 'bignumber.js';
import {
  queryContract,
  loadAbi,
  interactWithContract,
  ZayaContracts
} from './utils';

export interface UserStake {
  staked_at: number;
  restaked_at: number;
  claimable_at: number;
  initial_amount: BigNumber;
  current_amount: BigNumber;
  auto_restake: boolean;
  claimed: boolean;
  stake_type_id: number;
}

export interface UserStakeWithClaimAmount {
  stake: UserStake;
  will_claim: BigNumber;
}

export interface UserData {
  stakes: UserStakeWithClaimAmount[];
  total_staked: BigNumber;
  total_claimable: BigNumber;
  stake_count: number;
  current_timestamp: number;
}

export interface StakeType {
  period: number;
  reward_rate: number;
  max_auto_restakes: number;
  is_enabled: boolean;
}

// As const enum for the methods
const enum StakingContractMethods {
  Stake = 'stake',
  Claim = 'claim',
  Restake = 'restake',
  EnableAutoRestake = 'enableAutoRestake',
  DisableAutoRestake = 'disableAutoRestake',
  GetStakingPeriod = 'getStakingPeriod',
  GetRewardPercentage = 'getRewardPercentage',
  GetTokenIdentifier = 'getTokenIdentifier',
  GetStakeTypesLen = 'getStakeTypesLen',
  GetStakeTypes = 'getStakeTypes',
  GetCanClaim = 'getCanClaim',
  GetClaimableAmount = 'getClaimableAmount',
  GetTimestamp = 'getTimestamp',
  GetUserStake = 'getUserStake',
  GetUserStakeCount = 'getUserStakeCount',
  GetUserData = 'getUserData'
}

async function writeMethod(props: {
  config: {
    contractAddress: string;
    callerAddress: string;
    chainId: string;
    gasLimit: number;
    networkProviderUrl: string;
  };
  method: StakingContractMethods;
  args: any[];
  valueTransfer?: ITokenTransfer[];
}) {
  const { config, method, args, valueTransfer } = props;
  const {
    contractAddress,
    callerAddress,
    chainId,
    gasLimit,
    networkProviderUrl
  } = config;

  const abi = await loadAbi({ contractType: ZayaContracts.StakingContract });

  const contract = new SmartContract({
    address: Address.fromBech32(contractAddress),
    abi
  });

  await interactWithContract({
    contract,
    method: method,
    args: args,
    callerAddress: callerAddress,
    chainId: chainId,
    gasLimit: gasLimit,
    multiTransfer: valueTransfer,
    networkProviderUrl: networkProviderUrl
  });
}

async function viewMethod(props: {
  config: {
    contractAddress: string;
    networkProviderUrl: string;
  };
  method: StakingContractMethods;
  args: any[];
}) {
  const { config, method, args } = props;
  const { contractAddress, networkProviderUrl } = config;

  const abi = await loadAbi({ contractType: ZayaContracts.StakingContract });

  const contract = new SmartContract({
    address: Address.fromBech32(contractAddress),
    abi
  });

  const res = await queryContract({
    contract,
    method: method,
    args: args,
    networkProviderUrl: networkProviderUrl
  });

  // Leave the parsing to the caller function
  return res.firstValue?.valueOf();
}

async function stake(props: {
  config: {
    contractAddress: string;
    callerAddress: string;
    chainId: string;
    gasLimit: number;
    networkProviderUrl: string;
  };
  amount: number;
  tokenIdentifier: string;
  stake_type_id: number;
}) {
  const { config, amount, tokenIdentifier, stake_type_id } = props;
  const transferValue = TokenTransfer.fungibleFromAmount(
    tokenIdentifier,
    amount,
    18
  );

  await writeMethod({
    config: config,
    method: StakingContractMethods.Stake,
    args: [stake_type_id],
    valueTransfer: [transferValue]
  });
}

async function claim(props: {
  config: {
    contractAddress: string;
    callerAddress: string;
    chainId: string;
    gasLimit: number;
    networkProviderUrl: string;
  };
  stakeId: number;
}) {
  const { config, stakeId } = props;

  await writeMethod({
    config: config,
    method: StakingContractMethods.Claim,
    args: [stakeId]
  });
}

async function restake(props: {
  config: {
    contractAddress: string;
    callerAddress: string;
    chainId: string;
    gasLimit: number;
    networkProviderUrl: string;
  };
  stakeId: number;
}) {
  const { config, stakeId } = props;

  await writeMethod({
    config: config,
    method: StakingContractMethods.Restake,
    args: [stakeId]
  });
}

async function enableAutoRestake(props: {
  config: {
    contractAddress: string;
    callerAddress: string;
    chainId: string;
    gasLimit: number;
    networkProviderUrl: string;
  };
  stakeId: number;
  restake_count: number;
}) {
  const { config, stakeId, restake_count } = props;

  await writeMethod({
    config: config,
    method: StakingContractMethods.EnableAutoRestake,
    args: [stakeId, restake_count]
  });
}

async function disableAutoRestake(props: {
  config: {
    contractAddress: string;
    callerAddress: string;
    chainId: string;
    gasLimit: number;
    networkProviderUrl: string;
  };
  stakeId: number;
}) {
  const { config, stakeId } = props;

  await writeMethod({
    config: config,
    method: StakingContractMethods.DisableAutoRestake,
    args: [stakeId]
  });
}

async function getTokenIdentifier(props: {
  config: {
    contractAddress: string;
    networkProviderUrl: string;
  };
}): Promise<string> {
  const res = await viewMethod({
    config: props.config,
    method: StakingContractMethods.GetTokenIdentifier,
    args: []
  });

  return res;
}

async function getCanClaim(props: {
  config: {
    contractAddress: string;
    networkProviderUrl: string;
  };
  user: string;
  stakeId: number;
}): Promise<boolean> {
  const res = await viewMethod({
    config: props.config,
    method: StakingContractMethods.GetCanClaim,
    args: [props.user, props.stakeId]
  });

  return new Boolean(res).valueOf();
}

async function getClaimableAmount(props: {
  config: {
    contractAddress: string;
    networkProviderUrl: string;
  };
  user: string;
  stakeId: number;
}): Promise<BigNumber> {
  const res = await viewMethod({
    config: props.config,
    method: StakingContractMethods.GetClaimableAmount,
    args: [props.user, props.stakeId]
  });

  return BigNumber(res.toString());
}

async function getTimestamp(props: {
  config: {
    contractAddress: string;
    networkProviderUrl: string;
  };
}): Promise<number> {
  const res = await viewMethod({
    config: props.config,
    method: StakingContractMethods.GetTimestamp,
    args: []
  });

  return parseInt(res);
}

async function getUserStake(props: {
  config: {
    contractAddress: string;
    networkProviderUrl: string;
  };
  user: string;
  stakeId: number;
}): Promise<UserStake> {
  const res = await viewMethod({
    config: props.config,
    method: StakingContractMethods.GetUserStake,
    args: [props.user, props.stakeId]
  });

  return {
    staked_at: parseInt(res.staked_at),
    restaked_at: parseInt(res.restaked_at),
    claimable_at: parseInt(res.claimable_at),
    initial_amount: BigNumber(res.initial_amount.toString()),
    current_amount: BigNumber(res.current_amount.toString()),
    auto_restake: new Boolean(res.auto_restake).valueOf(),
    claimed: new Boolean(res.claimed).valueOf(),
    stake_type_id: parseInt(res.stake_type_id)
  };
}

async function getUserStakeCount(props: {
  config: {
    contractAddress: string;
    networkProviderUrl: string;
  };
  user: string;
}): Promise<number> {
  const res = await viewMethod({
    config: props.config,
    method: StakingContractMethods.GetUserStakeCount,
    args: [props.user]
  });

  return parseInt(res);
}

async function getUserData(props: {
  config: {
    contractAddress: string;
    networkProviderUrl: string;
  };
  user: string;
}): Promise<UserData> {
  const res = await viewMethod({
    config: props.config,
    method: StakingContractMethods.GetUserData,
    args: [props.user]
  });

  return {
    stakes: res.stakes.map(({ stake: _stake, will_claim }: any) => {
      return {
        stake: {
          staked_at: parseInt(_stake.staked_at),
          restaked_at: parseInt(_stake.restaked_at),
          claimable_at: parseInt(_stake.claimable_at),
          initial_amount: BigNumber(_stake.initial_amount.toString()),
          current_amount: BigNumber(_stake.current_amount.toString()),
          auto_restake: new Boolean(_stake.auto_restake).valueOf(),
          claimed: new Boolean(_stake.claimed).valueOf(),
          restake_count: parseInt(_stake.restake_count),
          stake_type_id: parseInt(_stake.stake_type_id)
        },
        will_claim: BigNumber(will_claim.toString())
      };
    }),
    total_staked: BigNumber(res.total_staked.toString()),
    total_claimable: BigNumber(res.total_claimable.toString()),
    stake_count: parseInt(res.stake_count),
    current_timestamp: parseInt(res.current_timestamp)
  };
}

async function getStakeTypesLen(props: {
  config: {
    contractAddress: string;
    networkProviderUrl: string;
  };
}): Promise<number> {
  const res = await viewMethod({
    config: props.config,
    method: StakingContractMethods.GetStakeTypesLen,
    args: []
  });

  return parseInt(res);
}

async function getStakeTypes(props: {
  config: {
    contractAddress: string;
    networkProviderUrl: string;
  };
}): Promise<StakeType[]> {
  const res = await viewMethod({
    config: props.config,
    method: StakingContractMethods.GetStakeTypes,
    args: []
  });

  return res.map((stake_type: any): StakeType => {
    return {
      'is_enabled': new Boolean(stake_type.is_enabled).valueOf(),
      'reward_rate': parseInt(stake_type.reward_rate),
      'period': parseInt(stake_type.period),
      'max_auto_restakes': parseInt(stake_type.max_auto_restakes)
    };
  });
}

export const StakingMethods = {
  stake,
  claim,
  restake,
  enableAutoRestake,
  disableAutoRestake,
  getTokenIdentifier,
  getCanClaim,
  getClaimableAmount,
  getTimestamp,
  getUserStake,
  getUserStakeCount,
  getUserData,
  getStakeTypesLen,
  getStakeTypes
};
