import React, { useState } from 'react';

import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  TextField
} from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';

import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import LogoIMG from '../../assets/img/footer_logo.png';
import Linkedin from '../../assets/img/social/LinkedIn.svg';
import telegram from '../../assets/img/social/Telegram.svg';
import Twitter from '../../assets/img/social/x.svg';
import youTube from '../../assets/img/social/YouTube.svg';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: '5rem',
      [theme.breakpoints.down('sm')]: {
        padding: '27px',
        paddingTop: '150px'
      }
    },
    alignLeftContainer: {
      textAlign: 'left'
    },
    container: {
      minHeight: '400px',
      textAlign: 'center'
    },
    logo: {
      width: '400px',
      height: '77px',
      marginTop: '12rem',
      [theme.breakpoints.down('md')]: {
        marginTop: '6rem'
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
        width: '100%',
        height: 'auto'
      }
    },
    copyright: {
      marginTop: '1rem !important',
      fontSize: '18px !important',
      color: '#6F6C90'
    },
    footerHeader: {
      marginTop: '10rem !important',
      marginBottom: '2rem !important',
      fontWeight: 'bold !important',
      fontSize: '1.1rem !important',
      [theme.breakpoints.down('sm')]: {
        marginTop: '33px !important',
        marginBottom: '40px !important'
      }
    },
    footerItem: {
      marginTop: '1rem !important',
      fontSize: '18px !important',
      color: '#6F6C90',
      lineHeight: '20px !important',
      fontWeight: '400 !important',
      '&:hover': {
        textDecoration: 'none',
        color: '#6F6C90'
      }
    },
    emailBtn: {
      width: '100%',
      maxWidth: '300px !important',
      borderRadius: '108px !important',
      border: '1px solid var(--neutral-400, #D9DBE9)',
      background: 'var(--neutral-100, #FFF)',
      boxShadow: '0px 2px 12px 0px rgba(20, 20, 43, 0.08)',
      marginTop: '2rem !important',
      color: 'var(--neutral-600, #6F6C90) !important',
      fontSize: '0.8rem !important',
      textAlign: 'center',
      '& fieldset': {
        border: 'none !important'
      },
      '& input': {
        textAlign: 'center'
      }
    },
    subscribeBtn: {
      width: '100%',
      maxWidth: '300px !important',
      paddingTop: '1.2rem !important',
      paddingBottom: '1.2rem !important',
      borderRadius: '108px !important',
      border: '1px solid var(--neutral-400, #D9DBE9)',
      background: '#219ED3 !important',
      boxShadow: '0px 2px 12px 0px rgba(20, 20, 43, 0.08)',
      marginTop: '2rem !important',
      color: 'white !important',
      fontSize: '0.8rem !important',
    },
    social: {
      margin: '19px 11px !important',
      cursor: 'pointer !important',
      height: '19px',
      width: '19px'
    },
    socialContainer: {
      marginTop: '1rem !important'
    }
  })
);

const Footer = () => {
    const classes = useStyles();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const testEmailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const handleSubmit = async () => {
        if(!email)
            return;

    const isEmailValid = testEmailFormat.test(email);

    if (!isEmailValid) {
      toast.error('Email is not valid');
      return;
    }

    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_BASE_URL}/user/subscribe`,
        method: 'post',
        data: { email }
      });
      response.data.hasSubscribed &&
        toast.success('Your data has been sent successfully') &&
        setEmail('');
    } catch (error: any) {
      toast.error(error.response.data.error);
    }
  };

  return (
    <Box className={classes.root} style={{background: location.pathname.includes('vesting') || location.pathname.includes('staking') || location.pathname.includes('token') ? 'linear-gradient(140deg, #E7F0FF 0%, rgba(232, 241, 255, 0.47) 100%)' : 'white'}}>
      <Divider sx={{ display: { xs: 'none', md: 'block' } }} />
      <Grid container className={classes.container}>
        <Grid
          item
          xl={5}
          lg={4}
          xs={12}
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            alignItems: 'center',
            pr: '30px'
          }}
        >
          <img src={LogoIMG} className={classes.logo} />
          <Typography className={classes.copyright}>
            Copyright © 2023 ZAYA | All Rights Reserved
          </Typography>
          <Box className={classes.socialContainer}>
            <a href='https://twitter.com/ZayaAI_PathDx' target='_blank'>
              <img src={Twitter} className={classes.social} />
            </a>
            <a
              href='https://www.linkedin.com/company/zaya-artificial-intelligence'
              target='_blank'
            >
              <img src={Linkedin} className={classes.social} />
            </a>
            <a href='https://t.me/ZayaAI_EN' target='_blank'>
              <img src={telegram} className={classes.social} />
            </a>
            <a href='https://www.youtube.com/channel/UCaDLw2NE2UmaD3kWIr1hLcg' target='_blank'>
              <img src={youTube} className={classes.social} />
            </a>
          </Box>
        </Grid>
        <Grid
          item
          xl={2}
          lg={2}
          sm={4}
          xs={12}
          className={classes.alignLeftContainer}
        >
          <Typography className={classes.footerHeader}>Token</Typography>
          <a
            href='/pitch.pdf'
            target='_blank'
            className={classes.footerItem}
            style={{ textDecoration: 'none' }}
          >
            Pitch Deck
          </a> <br />
          <div className={classes.footerItem}>
            <a
              href='/whitepaper-zaya.pdf'
              target='_blank'
              className={classes.footerItem}
              style={{ textDecoration: 'none' }}
            >
              Whitepaper
            </a>
          </div>
          <Typography
            className={classes.footerItem}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              window.location.href = '/#utility';
            }}
          >
            Token Utilities
          </Typography>
          <Typography
            className={classes.footerItem}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              window.location.href = '/#stakingreward';
            }}
          >
            Staking & Rewards
          </Typography>
          <Typography
            className={classes.footerItem}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              window.location.href = '/#faq';
            }}
          >
            FAQs
          </Typography>
        </Grid>
        <Grid
          item
          xl={2}
          lg={2}
          sm={4}
          xs={12}
          className={classes.alignLeftContainer}
        >
          <Typography className={classes.footerHeader}>
            ZAYA Ecosystem
          </Typography>
          <Typography
            className={classes.footerItem}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              window.location.href = 'https://www.zaya.ai/';
            }}
          >
            ZayaAI
          </Typography>
	   <Typography 
            className={classes.footerItem} 
            sx={{ cursor: 'pointer' }} 
            onClick={() => { 
              window.location.href = 'https://zayapathology.health/'; 
            }} 
          > 
            Zaya Pathology Lab 
          </Typography> 
          <Typography 
            className={classes.footerItem} 
            sx={{ cursor: 'pointer' }} 
          > 
            Zaya Medical Clinic 
          </Typography> 
          <Typography 
            className={classes.footerItem} 
            sx={{ cursor: 'pointer' }} 
          > 
            Zaya Blockchain 
          </Typography>
        </Grid>
        <Grid
          item
          xl={3}
          lg={4}
          sm={4}
          xs={12}
          className={classes.alignLeftContainer}
        >
          <Typography className={classes.footerHeader}>
            Subscribe to our newsletter
          </Typography>
          <Typography
            className={classes.footerItem}
            sx={{ maxWidth: '300px', lineHeight: '30px !important' }}
          >
            Receive all the latest Zaya news and updates straight to your inbox
          </Typography>
          <TextField
            id='email-input'
            variant='outlined'
            placeholder='Enter your email'
            value={email}
            className={classes.emailBtn}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            className={classes.subscribeBtn}
            onClick={() => handleSubmit()}
          >
            Subscribe
          </Button>
        </Grid>
        <Box
          sx={{
            display: {
              xs: 'block',
              sm: 'none',
              md: 'none',
              lg: 'none',
              xl: 'none'
            }
          }}
        >
          <Grid item xl={4} lg={4} xs={12} sx={{ marginTop: '3rem' }}>
            <img src={LogoIMG} className={classes.logo} />
            <Typography className={classes.copyright}>
              Copyright © 2023 ZAYA | All Rights Reserved
            </Typography>
            <Box className={classes.socialContainer}>
              <img
                src={Twitter}
                className={classes.social}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  (window.location.href = 'https://twitter.com/ZayaAI_PathDx')
                }
              />
              <img
                src={Linkedin}
                className={classes.social}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  (window.location.href =
                    'https://www.linkedin.com/company/zaya-artificial-intelligence')
                }
              />
            
              <img
                src={telegram}
                className={classes.social}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  (window.location.href = 'https://t.me/ZayaAI_EN')
                }
              />

              <img
                src={youTube}
                className={classes.social}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  (window.location.href = 'https://www.youtube.com/channel/UCaDLw2NE2UmaD3kWIr1hLcg')
                }
              />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export { Footer };
