import React, { } from 'react';
import {
    Box, Container, Grid
} from '@mui/material';


import {
    createStyles, makeStyles
} from '@mui/styles';

import BgImg from '../../assets/img/background.png';
import Logo from '../../assets/img/logo.png';
import TitleImg from '../../assets/img/title.png';
import RecAIImg from '../../assets/img/rec_ai.png';
import RecBlockchainImg from '../../assets/img/rec_blockchain.png';
import RecMedicalImg from '../../assets/img/rec_medical.png';
import RecPathologyImg from '../../assets/img/rec_pathology.png';
import { Content } from 'components/common';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            paddingTop: '125px',
            background: 'linear-gradient(51deg, #E7F0FF 0%, rgba(232, 241, 255, 0.47) 100%)',
            minHeight: '100vh'
        },
        bg: {
            width: '100%',
            transform: 'rotate(165.72)',
            position: 'fixed'
        },
        logo: {
            width: '60px'
        },
        title: {
            width: '200px',
            marginLeft: '10px'
        },
        header: {
            textAlign: 'center'
        },
        desc: {
            fontSize: '20px',
            marginTop: '40px'
        },
        grid: {
            marginTop: '65px',
        }
    })
);


const Welcome = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <img src={BgImg} className={classes.bg} />
            <Container maxWidth = 'xl'>
                <Box className={classes.header}>
                    <img src={Logo} className={classes.logo} />
                    <img src={TitleImg} className={classes.title} />
                    <div className={classes.desc}>
                        Please choose what would you like to explorer
                    </div>
                    <Grid container className = {classes.grid}>
                        <Grid item lg = {3} md = {6} sm = {12} xs = {12}>
                            <Content
                                iamgeSRC={RecAIImg}
                                header='ZAYA AI'
                                content='ZayaAI is a ground-breaking ecosystem that aims to combat cancers by leveraging the latest AI technologies and medical screening procedures to provide early detection, prevention, and treatment.'
                            />
                        </Grid>
                        <Grid item lg = {3} md = {6} sm = {12} xs = {12}>
                            <Content
                                iamgeSRC={RecPathologyImg}
                                header='ZAYA AI'
                                content='ZayaAI is a ground-breaking ecosystem that aims to combat cancers by leveraging the latest AI technologies and medical screening procedures to provide early detection, prevention, and treatment.'
                            />
                        </Grid>
                        <Grid item lg = {3} md = {6} sm = {12} xs = {12}>
                            <Content
                                iamgeSRC={RecMedicalImg}
                                header='ZAYA AI'
                                content='ZayaAI is a ground-breaking ecosystem that aims to combat cancers by leveraging the latest AI technologies and medical screening procedures to provide early detection, prevention, and treatment.'
                            />
                        </Grid>
                        <Grid item lg = {3} md = {6} sm = {12} xs = {12}>
                            <Content
                                iamgeSRC={RecBlockchainImg}
                                header='ZAYA AI'
                                content='ZayaAI is a ground-breaking ecosystem that aims to combat cancers by leveraging the latest AI technologies and medical screening procedures to provide early detection, prevention, and treatment.'
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export { Welcome };
