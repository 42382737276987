import React, { } from 'react';
import {
    Box, Button, Typography
} from '@mui/material';


import {
    createStyles, makeStyles
} from '@mui/styles';


const useStyles = makeStyles((theme: any) =>
    createStyles({
        seeingContainer: {
            margin: 'auto !important',
            marginTop: '3rem !important',
            width: '90% !important',
            textAlign: 'center',
            background: 'url("assets/img/cloud.png"), linear-gradient(136deg, #15B8EA 0%, #1DA0C9 100%)',
            strokeWidth: '10px',
            height: '500px',
            stroke: ' #EBF7FC',
            borderRadius: '0.5rem'
        },
        believeTitle: {
            color: 'white',
            fontSize: '70px !important',
            fontWeight: '700 !important',
            paddingTop: '8rem !important'
        },
        believeContent: {
            marginTop: '1rem !important',
            fontSize: '18px !important',
            fontWeight: '400 !important'
        },
        bookButton: {
            width: '200px !important',
            paddingTop: '1rem !important',
            paddingBottom: '1rem !important',
            borderRadius: '15px !important',
            border: '1px solid #FFF',
            background: '#FAFCFD !important',
            color: 'black !important',
            marginTop: '3rem !important',
            fontSize: '0.8rem !important',
            [theme.breakpoints.down('sm')]: {
                // fontSize: '14px !important',
            },
            '&:hover': {
                // background: 'transparent linear-gradient(90deg, #FF8913 0%, #FF5F07 50%, #D02D02 100%) 0% 0% no-repeat padding-box !important',
                // boxShadow: '0px 0px 10px #FF891326'
            },
        },
    })
);


const SeeingIsBelieveing = () => {
    const classes = useStyles();
    return (
        <Box className={classes.seeingContainer}>
            <Typography className={classes.believeTitle}>
                Seeing is believing
            </Typography>
            <Typography className={classes.believeContent}>
                Book a free demonstration
            </Typography>
            <Button className={classes.bookButton}>Book a demo</Button>
        </Box>
    );
};

export { SeeingIsBelieveing };
