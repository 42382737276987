import React, { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  Modal,
  TextField,
  Typography,
  Checkbox
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import wave from '../../assets/img/blockchain/wave.svg';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { TokenForm } from 'components/TokenForm/TokenForm';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      background:
        'linear-gradient(51deg, #E7F0FF 0%, rgba(232, 241, 255, 0.47) 100%)',
      paddingTop: '10rem',
      color: '#16374D !important',
      position: 'relative'
    },
    main: {
      padding: '185px',
      position: 'relative',
      zIndex: '1000',
      [theme.breakpoints.down('lg')]: {
        padding: '5rem'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '1rem'
      }
    },
    gridContainer: {
      paddingRight: '40px'
    },
    title: {
      fontSize: '71px !important',
      fontWeight: '500 !important',
      lineHeight: '129% !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '51px !important'
      }
    },
    blueT: {
      color: '#20C2E5 !important'
    },
    content: {
      marginTop: '25px !important',
      fontSize: '20px !important',
      fontWeight: '300 !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important'
      }
    },
    footer: {
      marginTop: '25px !important',
      fontSize: '18px !important',
      textDecoration: 'underline',
      fontWeight: '500 !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px !important'
      }
    },
    gridStakeContainer: {
      padding: '0 3rem',
      [theme.breakpoints.down('sm')]: {
        padding: '0 1rem',
        marginTop: '1rem !important'
      }
    },
    stakeBox: {
      borderRadius: '20px',
      background:
        'linear-gradient(180deg, #031420 0%, #0F3249 100%, #3E5F75 100%)',
      padding: '50px',
      [theme.breakpoints.down('lg')]: {
        marginTop: '2rem'
        // marginLeft: '-25px !important',
        // marginRight: '-25px !important',
        // borderRadius: '0px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '26px'
        // marginLeft: '-25px !important',
        // marginRight: '-25px !important',
        // borderRadius: '0px',
      }
    },
    stakeTitle: {
      color: '#FFF !important',
      fontSize: '28px !important',
      fontWeight: '700 !important',
      lineHeight: 'normal !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px !important'
      }
    },
    buyTitle: {
      color: '#FFF !important',
      fontSize: '28px !important',
      fontWeight: '700 !important',
      lineHeight: 'normal !important',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px !important'
      }
    },
    buyField: {
      marginTop: '38px !important'
    },
    maxBtn: {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer'
    },
    buyTextField: {
      borderRadius: '10px',
      background: '#f7f7f7',
      '& fieldset': {
        border: 'none !important'
      }
    },
    buyButton: {
      width: '100% !important',
      textAlign: 'center',
      borderRadius: '85px !important',
      background: '#20C2E5 !important',
      fontSize: '16px !important',
      color: 'black !important',
      marginLeft: '5px !important',
      marginTop: '17px !Important',
      padding: '18px 24px !important',
      [theme.breakpoints.down('sm')]: {
        padding: '12px 18px !important',
        fontSize: '10px !important'
        // fontSize: '14px !important',
      },
      '&:hover': {
        // background: 'transparent linear-gradient(90deg, #FF8913 0%, #FF5F07 50%, #D02D02 100%) 0% 0% no-repeat padding-box !important',
        // boxShadow: '0px 0px 10px #FF891326'
      }
    },
    priceText: {
      fontSize: '12px !important',
      fontWeight: '400 !important',
      textAlign: 'right',
      color: 'white',
      marginRight: '10px !important',
      marginTop: '8px !important'
    },
    stakeContent: {
      fontSize: '12px !important',
      fontWeight: '400 !important',
      color: '#FFF !important',
      marginTop: '2rem !important',
      textAlign: 'center'
    },
    subStakeBox: {
      borderRadius: '20px !important',
      background: '#FFF !important',
      marginTop: '2rem !important',
      padding: '18px !important'
    },
    subStakeTitle: {
      color: '#1B2B38',
      fontSize: '21.184px !important',
      fontWeight: '500 !important',
      lineHeight: '23.832px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px !important'
      }
    },
    infoContainer: {
      marginTop: '2rem !important'
    },
    infoTitle: {
      fontSize: '18px !important',
      fontWeight: '500 !important',
      lineHeight: '26px !important',
      color: '#8B8B8B !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px !important'
      }
    },
    infoContent: {
      fontSize: '18px !important',
      fontWeight: '500 !important',
      lineHeight: '26px !important',
      color: '#1B2B38 !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px !important'
      }
    },
    buttonGroup: {
      textAlign: 'right',
      marginTop: '2rem !important'
    },
    blueButton: {
      width: '100px !important',
      textAlign: 'center',
      borderRadius: '85px !important',
      background: '#20C2E5 !important',
      fontSize: '13px !important',
      color: 'black !important',
      marginLeft: '5px !important',
      [theme.breakpoints.down('sm')]: {
        width: '80px !important',
        fontSize: '10px !important'
        // fontSize: '14px !important',
      },
      '&:hover': {
        // background: 'transparent linear-gradient(90deg, #FF8913 0%, #FF5F07 50%, #D02D02 100%) 0% 0% no-repeat padding-box !important',
        // boxShadow: '0px 0px 10px #FF891326'
      }
    },
    blueButtonw100: {
      width: '100px !important',
      textAlign: 'center',
      borderRadius: '85px !important',
      background: '#20C2E5 !important',
      fontSize: '13px !important',
      color: 'black !important',
      marginLeft: '5px !important',
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        fontSize: '16px !important',
        padding: '10px !important',
        marginTop: '0.5rem !important',
        marginLeft: '0px !important'
        // fontSize: '14px !important',
      },
      '&:hover': {
        // background: 'transparent linear-gradient(90deg, #FF8913 0%, #FF5F07 50%, #D02D02 100%) 0% 0% no-repeat padding-box !important',
        // boxShadow: '0px 0px 10px #FF891326'
      }
    },
    whiteButton: {
      width: '100px !important',
      textAlign: 'center',
      borderRadius: '85px !important',
      border: '0.56px solid #20C2E5 !important',
      background: '#FFF !important',
      fontSize: '13px !important',
      color: '#16374D !important',
      marginLeft: '5px !important',
      [theme.breakpoints.down('sm')]: {
        width: '80px !important',
        fontSize: '10px !important'
        // fontSize: '14px !important',
      },
      '&:hover': {
        // background: 'transparent linear-gradient(90deg, #FF8913 0%, #FF5F07 50%, #D02D02 100%) 0% 0% no-repeat padding-box !important',
        // boxShadow: '0px 0px 10px #FF891326'
      }
    },
    whiteButtonw100: {
      width: '100px !important',
      textAlign: 'center',
      borderRadius: '85px !important',
      border: '0.56px solid #20C2E5 !important',
      background: '#FFF !important',
      fontSize: '13px !important',
      color: '#16374D !important',
      marginLeft: '5px !important',
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        fontSize: '16px !important',
        marginTop: '0.5rem !important',
        marginLeft: '0px !important',
        padding: '10px !important'
        // fontSize: '14px !important',
      },
      '&:hover': {
        // background: 'transparent linear-gradient(90deg, #FF8913 0%, #FF5F07 50%, #D02D02 100%) 0% 0% no-repeat padding-box !important',
        // boxShadow: '0px 0px 10px #FF891326'
      }
    },

    tableContainer: {
      borderRadius: '12px !important',
      marginTop: '150px !important',
      [theme.breakpoints.down('sm')]: {
        marginTop: '5rem !important'
        // fontSize: '14px !important',
      }
    },
    table: {
      minWidth: 700,
      borderRadius: '10px',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'unset !important'
      }
    },
    head: {
      background: '#E9E9E9'
    },
    rowHighlight: {
      background: 'rgba(32, 194, 229, 0.44)'
    },
    rowNormal: {
      background: '#FFF'
    },
    cell: {
      borderBottom: '1px light gray !important'
    },
    cellBold: {
      fontWeight: 'bold !important'
    },
    wave: {
      position: 'absolute',
      width: '100%',
      top: '40rem',
      [theme.breakpoints.down('sm')]: {
        display: 'none !important'
      }
    },

    textField: {
      borderRadius: '10px',
      background: '#f7f7f7',
      margin: '1rem !important',
      '& fieldset': {
        border: 'none !important'
      }
    },
    agreeFont: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important'
      }
    },
    mt15: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '15px !important'
      }
    }
  })
);

const Token = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img src={wave} className={classes.wave} />
      <Box className={classes.main}>
        <Grid container className={classes.gridContainer}>
          <Grid item xl={6} lg={6} md={12}>
            <Typography className={classes.title}>
                Private Sale Round is {' '}
              <span
                className={classes.blueT}
                style={{ cursor: 'pointer' }}
              >
                Open
              </span>
            </Typography>
            <Typography className={classes.content}>
              ZayaAI is a comprehensive ecosystem designed to fight cancer and
              promote healthy living through the use of cutting-edge AI
              technologies and advanced medical screening techniques.
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={12}>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY as string}
                scriptProps={{
                    async: false, // optional, default to false,
                    defer: false, // optional, default to false
                    appendTo: 'head', // optional, default to "head", can be "head" or "body",
                    nonce: undefined, // optional, default undefined
                }}
            >
                <TokenForm />
            </GoogleReCaptchaProvider>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export { Token };
