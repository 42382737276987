import React, { } from 'react';
import {
    Box, Divider, Grid, Typography
} from '@mui/material';

import {
    createStyles, makeStyles
} from '@mui/styles';
import { Blog } from 'components/common/Blog';
import { FAQ } from 'components/common/Faq';
import { CustomTimeline } from './CustomTimeline';


const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            background: 'linear-gradient(180deg, #031420 0%, #0F3249 100%, #3E5F75 100%)',
            paddingTop: '10rem',
            color: '#FFF !important',
            position: 'relative'
        },
        main: {
            padding: '150px',
            position: 'relative',
            [theme.breakpoints.down('lg')]: {
                padding: '5rem 3rem 3rem 3rem'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '1rem'
            },
        },
        mainTitle: {
            fontSize: '60px !important',
            fontWeight: '500 !important',
            lineHeight: '100% !important',
            color: 'white',
            [theme.breakpoints.down('sm')]: {
                fontSize: '40px !important'
            },
        },
        divider: {
            width: '64px',
            height: '2px',
            margin: '25px 0px !important',
            background: '#20C2E5 !important'
        },
        mainContent: {
            fontSize: '30px !important',
            fontWeight: '200 !important',
            lineHeight: 'normal !important',
            color: 'white',
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px !important'
            },
        },
        timeline: {
            marginTop: '5rem',
            [theme.breakpoints.down('sm')]: {
                marginTop: '2rem'
            },
        },
        con: {
            padding: '60px',
            fontSize: '20px !important',
            lineHeight: 'normal !important',
            color: 'white',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px !important',
                padding: '30px'
            },
        }
    })
);

const Roadmap = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.main}>
                <Grid container>
                    <Grid item xl={6}>
                        <Typography className={classes.mainTitle}>
                            ZAYA AI Roadmap: Milestones & Timeline
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Typography className={classes.mainContent}>
                    To achieve our vision of revolutionizing the healthcare industry with AI-driven solutions and services, we have outlined a roadmap with key milestones and target timelines. This roadmap serves as a strategic guide for our team and stakeholders as we work together to advance healthcare through cutting-edge technologies.
                </Typography>
                <Box className={classes.timeline}>
                    <CustomTimeline />
                </Box>
                <Typography className={classes.con}>
                    In conclusion, our roadmap represents our time-framed vision regarding the delivery of ZayaAI ecosystem’s cutting-edge AI solutions and services expected to transform the healthcare landscape. We look forward to sharing our progress and achievements as we continue to advance our mission of revolutionizing healthcare through AI-driven innovation.
                </Typography>
            </Box>

            <FAQ />

            <Blog />
        </Box>
    );
};

export { Roadmap };
