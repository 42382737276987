export const VestingContractAbi = {
    'buildInfo': {
        'rustc': {
            'version': '1.75.0-nightly',
            'commitHash': '7adc89b69b941afceadcf8609dd6b2999353e550',
            'commitDate': '2023-11-07',
            'channel': 'Nightly',
            'short': 'rustc 1.75.0-nightly (7adc89b69 2023-11-07)'
        },
        'contractCrate': {
            'name': 'contract',
            'version': '0.0.0'
        },
        'framework': {
            'name': 'multiversx-sc',
            'version': '0.46.1'
        }
    },
    'docs': [
        'An empty contract. To be used as a template when starting a new contract from scratch.'
    ],
    'name': 'Contract',
    'constructor': {
        'inputs': [],
        'outputs': []
    },
    'endpoints': [
        {
            'name': 'setMaintenanceMode',
            'onlyOwner': true,
            'mutability': 'mutable',
            'inputs': [
                {
                    'name': 'is_in_maintenance',
                    'type': 'bool'
                }
            ],
            'outputs': []
        },
        {
            'name': 'setVestedTokenIdentifier',
            'onlyOwner': true,
            'mutability': 'mutable',
            'inputs': [
                {
                    'name': 'token_id',
                    'type': 'TokenIdentifier'
                }
            ],
            'outputs': []
        },
        {
            'name': 'fund',
            'onlyOwner': true,
            'mutability': 'mutable',
            'payableInTokens': [
                '*'
            ],
            'inputs': [],
            'outputs': []
        },
        {
            'name': 'addVestedTokens',
            'onlyOwner': true,
            'mutability': 'mutable',
            'inputs': [
                {
                    'name': 'investor',
                    'type': 'Address'
                },
                {
                    'name': 'schedule_type',
                    'type': 'u8'
                },
                {
                    'name': 'releases',
                    'type': 'List<ScheduleRelease>'
                }
            ],
            'outputs': []
        },
        {
            'name': 'withdrawFund',
            'onlyOwner': true,
            'mutability': 'mutable',
            'inputs': [
                {
                    'name': 'opt_token_id',
                    'type': 'optional<EgldOrEsdtTokenIdentifier>',
                    'multi_arg': true
                },
                {
                    'name': 'opt_token_nonce',
                    'type': 'optional<u64>',
                    'multi_arg': true
                },
                {
                    'name': 'opt_token_amount',
                    'type': 'optional<BigUint>',
                    'multi_arg': true
                }
            ],
            'outputs': [],
            'allow_multiple_var_args': true
        },
        {
            'name': 'claimTokens',
            'mutability': 'mutable',
            'inputs': [],
            'outputs': []
        },
        {
            'name': 'getIsInMaintenance',
            'mutability': 'readonly',
            'inputs': [],
            'outputs': [
                {
                    'type': 'bool'
                }
            ]
        },
        {
            'name': 'getVestedTokenId',
            'mutability': 'readonly',
            'inputs': [],
            'outputs': [
                {
                    'type': 'TokenIdentifier'
                }
            ]
        },
        {
            'name': 'getInvestorData',
            'mutability': 'readonly',
            'inputs': [
                {
                    'name': 'investor',
                    'type': 'Address'
                }
            ],
            'outputs': [
                {
                    'type': 'List<VestingSchedule>'
                }
            ]
        },
        {
            'name': 'getInvestorSpecificSchedule',
            'mutability': 'readonly',
            'inputs': [
                {
                    'name': 'investor',
                    'type': 'Address'
                },
                {
                    'name': 'schedule_id',
                    'type': 'u32'
                }
            ],
            'outputs': [
                {
                    'type': 'VestingSchedule'
                }
            ]
        },
        {
            'name': 'getClaimableAmount',
            'mutability': 'readonly',
            'inputs': [
                {
                    'name': 'investor',
                    'type': 'Address'
                }
            ],
            'outputs': [
                {
                    'type': 'BigUint'
                }
            ]
        }
    ],
    'events': [
        {
            'identifier': 'ChangedMaintenanceMode',
            'inputs': [
                {
                    'name': 'new_value',
                    'type': 'bool'
                },
                {
                    'name': 'timestamp',
                    'type': 'u64',
                    'indexed': true
                }
            ]
        },
        {
            'identifier': 'VestedTokensClaim',
            'inputs': [
                {
                    'name': 'investor',
                    'type': 'Address',
                    'indexed': true
                },
                {
                    'name': 'amount',
                    'type': 'BigUint'
                },
                {
                    'name': 'timestamp',
                    'type': 'u64',
                    'indexed': true
                }
            ]
        },
        {
            'identifier': 'NewVestedTokens',
            'inputs': [
                {
                    'name': 'investor',
                    'type': 'Address',
                    'indexed': true
                },
                {
                    'name': 'schedule_type',
                    'type': 'u8',
                    'indexed': true
                },
                {
                    'name': 'amount',
                    'type': 'BigUint'
                },
                {
                    'name': 'timestamp',
                    'type': 'u64',
                    'indexed': true
                }
            ]
        }
    ],
    'esdtAttributes': [],
    'hasCallback': false,
    'types': {
        'ScheduleRelease': {
            'type': 'struct',
            'fields': [
                {
                    'name': 'when',
                    'type': 'u64'
                },
                {
                    'name': 'amount',
                    'type': 'BigUint'
                },
                {
                    'name': 'claimed',
                    'type': 'BigUint'
                }
            ]
        },
        'ScheduleStats': {
            'type': 'struct',
            'fields': [
                {
                    'name': 'claimed',
                    'type': 'BigUint'
                },
                {
                    'name': 'vested',
                    'type': 'BigUint'
                }
            ]
        },
        'VestingSchedule': {
            'type': 'struct',
            'fields': [
                {
                    'name': 'schedule_type',
                    'type': 'u8'
                },
                {
                    'name': 'stats',
                    'type': 'ScheduleStats'
                },
                {
                    'name': 'releases',
                    'type': 'List<ScheduleRelease>'
                }
            ]
        }
    }
};
