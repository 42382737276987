import React, { useEffect, useState } from 'react';
import {
  Menu as MenuIcon
} from '@mui/icons-material';

import {
  AppBar, Box, Drawer,
  IconButton, List,
  ListItem, ListItemButton,
  ListItemText, Toolbar,
  Typography, Button, Fade, Menu, MenuItem, Collapse
} from '@mui/material';

import {
  createStyles, makeStyles
} from '@mui/styles';

import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils/logout';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthenticatedRoutesWrapper } from 'components';
import { routes, routeNames } from 'routes';


import Buger from '../../assets/img/blockchain/buger.svg';
import Logo from '../../assets/img/blockchain/logo.png';
import LogoExtra from '../../assets/img/nav_logo.png';
import { Footer } from './Footer';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      minHeight: '100vh'
    },
    navbar: {
      backgroundColor: 'unset !important',
      background: 'transparent',
      boxShadow: 'none !important',
      padding: '67px 155px',
      color: 'black !important',
      position: 'absolute',
      [theme.breakpoints.down('xl')]: {
        padding: '40px',
      },
      [theme.breakpoints.down('md')]: {
        padding: '40px 30px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '37px 22px',
      }
    },
    logo: {
      width: '185px',
      [theme.breakpoints.down('sm')]: {
        width: '132px',
      }
    },
    titleBox: {
      fontSize: '35px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
      display: 'flex',
      alignItems: 'center'
    },
    container: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important'
    },
    toolbar: {
      justifyContent: 'space-between',
      [theme.breakpoints.down('xl')]: {
        paddingRight: '0 !important'
      },
    },
    gap: {
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1
      }
    },
    navItem: {
      color: 'white !important',
      padding: '7px 20px !important',
      margin: '5px 3px !important',
      fontSize: '18px !important',
      fontWeight: '500 !important',
      borderRadius: '18px !important',
      letterSpacing: '0',
      '&:hover': {
        color: '#1B2B38 !important',
        fontWeight: '500 !important',
        background: '#FFF !important',
      },
      '&:focus': {
        color: '#1B2B38 !important',
        fontWeight: '500 !important',
        background: '#FFF !important',
      },
      [theme.breakpoints.down('xl')]: {
        padding: '7px 14px !important',
      },
      [theme.breakpoints.down('lg')]: {
        margin: '3px 3px !important',
      }
    },
    cycleNav: {
      fontFamily: 'OpenSans_Regular !important',
      fontSize: '14px !important'
    },
    walletButton: {
      background: 'transparent linear-gradient(90deg, #D02D02 0%, #FF5F07 50%, #FF8913 100%) 0% 0% no-repeat padding-box',
      borderRadius: '6px !important',
      opacity: 1,
      marginLeft: '1rem !important',
      width: '160px',
      height: '50px',
      '&:hover': {
        background: 'transparent linear-gradient(90deg, #FF8913 0%, #FF5F07 50%, #D02D02 100%) 0% 0% no-repeat padding-box !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100px !important',
      }
    },
    walletText: {
      fontSize: '14px !important',
      color: 'white !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px !important'
      }
    },
    drawerContainer: {
      textAlign: 'left',
      background: 'linear-gradient(rgb(3, 20, 32) 0%, rgb(15, 50, 73) 100%, rgb(62, 95, 117) 100%) !important',
      height: '100%',
      padding: '26px'
    },
    navHeader: {
      my: 2,
      color: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 0'
    },
    backdrop: {
      '& div:nth-child(3)': {
        backdropFilter: 'blur(10px)'
      }
    }
  })
);


export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { search } = useLocation();

  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [currentNav, setCurrentNav] = useState<string>('');

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const isLoggedIn = useGetIsLoggedIn();

  useEffect(() => {
    setCurrentNav(location.pathname.slice(1));
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState);
  };

  const handleClick = () => {
    const redirectTo = location.pathname.slice(1);
    if (isLoggedIn) logout('/');
    else navigate(`/wallets?redirectTo=/${redirectTo}`);
  };


  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl2);
  const handleClickAnchor2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseAnchor2 = () => {
    setAnchorEl2(null);
  };

  const [open, setOpen] = React.useState(true);

  const handleClickResource = () => {
    setOpen(!open);
    setMobileOpen(true);
  };

  const drawer = (
    <Box
      className={classes.drawerContainer}
    >
      <Typography variant='h6' className={classes.navHeader}>
        <img src={Logo} width={132} style={{ marginBottom: '6px', marginTop: '6px' }} />
        <img src={Buger} style={{ width: '13px', height: '13px' }} onClick={handleDrawerToggle} />
      </Typography>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'left' }}
            onClick={e => {
              navigate('/');
              handleDrawerToggle();
            }}
          >
            <ListItemText
              primary={'Home'}
              sx={{
                color: 'white',
                display: 'flex',
                fontSize: '18px',
                '& span': {
                  background: currentNav == '' ? 'white' : 'unset',
                  color: currentNav == '' ? 'black' : 'unset',
                  borderRadius: currentNav == '' ? '16px' : 'unset',
                  padding: currentNav == '' ? '4px 13px' : 'unset'
                }
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'left' }}
            onClick={e => {
              window.location.href = '/vesting';
              handleDrawerToggle();
            }}
          >
            <ListItemText
              primary={'Vesting'}
              sx={{
                color: 'white',
                display: 'flex',
                '& span': {
                  background: currentNav == 'vesting' ? 'white' : 'unset',
                  color: currentNav == 'vesting' ? 'black' : 'unset',
                  borderRadius: currentNav == 'vesting' ? '16px' : 'unset',
                  padding: currentNav == 'vesting' ? '4px 13px' : 'unset'
                }
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'left' }}
            onClick={e => {
              window.location.href = '/#token';
              handleDrawerToggle();
            }}
          >
            <ListItemText
              primary={'Token'}
              sx={{
                color: 'white',
                display: 'flex',
                fontSize: '18px',
                '& span': {
                  background: currentNav == 'token' ? 'white' : 'unset',
                  color: currentNav == 'token' ? 'black' : 'unset',
                  borderRadius: currentNav == 'token' ? '16px' : 'unset',
                  padding: currentNav == 'token' ? '4px 13px' : 'unset'
                }
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'left' }}
            onClick={e => {
              window.location.href = '/staking';
              handleDrawerToggle();
            }}
          >
            <ListItemText
              primary={'Staking'}
              sx={{
                color: 'white',
                display: 'flex',
                fontSize: '18px',
                '& span': {
                  background: currentNav == 'staking' ? 'white' : 'unset',
                  color: currentNav == 'staking' ? 'black' : 'unset',
                  borderRadius: currentNav == 'staking' ? '16px' : 'unset',
                  padding: currentNav == 'staking' ? '4px 13px' : 'unset'
                }
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'left' }}
            onClick={(e) => {
              window.location.href = '/#certificates';
              handleDrawerToggle();
            }}
          >
            <ListItemText
              primary={'Certificates'}
              sx={{
                color: 'white',
                display: 'flex',
                fontSize: '18px',
                '& span': {
                  background: currentNav == 'certificates' ? 'white' : 'unset',
                  color: currentNav == 'certificates' ? 'black' : 'unset',
                  borderRadius: currentNav == 'certificates' ? '16px' : 'unset',
                  padding: currentNav == 'certificates' ? '4px 13px' : 'unset'
                }
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'left' }}
            onClick={handleClickResource}
          >
            <ListItemText primary={'Resources'} sx={{ color: 'white', fontSize: '18px' }} />
          </ListItemButton>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ color: 'white !important' }} onClick={handleDrawerToggle}>
          <List component="div" disablePadding sx={{ marginLeft: '3rem' }}>
            <ListItemButton
              sx={{ textAlign: 'left' }}
              onClick={(e) => {
                window.open('/whitepaper-zaya.pdf', '_blank');
              }}
            >
              <ListItemText
                primary={'Whitepaper'}
                sx={{
                  color: 'white',
                  display: 'flex',
                  fontSize: '18px',
                  '& span': {
                    background: currentNav == 'whitepaper' ? 'white' : 'unset',
                    color: currentNav == 'whitepaper' ? 'black' : 'unset',
                    borderRadius: currentNav == 'whitepaper' ? '16px' : 'unset',
                    padding: currentNav == 'whitepaper' ? '4px 13px' : 'unset'
                  }
                }}
              />
            </ListItemButton>
          </List>
          <List component="div" disablePadding sx={{ marginLeft: '3rem' }}>
            <ListItemButton
              sx={{ textAlign: 'left' }} onClick={e => navigate('/roadmap')}
            >
              <ListItemText
                primary={'Roadmap'}
                sx={{
                  color: 'white',
                  display: 'flex',
                  fontSize: '18px',
                  '& span': {
                    background: currentNav == 'roadmap' ? 'white' : 'unset',
                    color: currentNav == 'roadmap' ? 'black' : 'unset',
                    borderRadius: currentNav == 'roadmap' ? '16px' : 'unset',
                    padding: currentNav == 'roadmap' ? '4px 13px' : 'unset'
                  }
                }}
              />
            </ListItemButton>
          </List>
          <List component="div" disablePadding sx={{ marginLeft: '3rem' }}>
            <ListItemButton
              sx={{ textAlign: 'left' }} onClick={e => window.location.href = '/#faq'}
            >
              <ListItemText
                primary={'FAQ\'s'}
                sx={{
                  color: 'white',
                  display: 'flex',
                  fontSize: '18px',
                  '& span': {
                    background: currentNav == 'faq' ? 'white' : 'unset',
                    color: currentNav == 'faq' ? 'black' : 'unset',
                    borderRadius: currentNav == 'faq' ? '16px' : 'unset',
                    padding: currentNav == 'faq' ? '4px 13px' : 'unset'
                  }
                }}
              />
            </ListItemButton>
          </List>
          <List component="div" disablePadding sx={{ marginLeft: '3rem' }}>
            <ListItemButton
              sx={{ textAlign: 'left' }} onClick={e => window.location.href = '/#news'}
            >
              {/* <ListItemText primary="News" /> */}
              <ListItemText
                primary={'News'}
                sx={{
                  color: 'white',
                  display: 'flex',
                  fontSize: '18px',
                  '& span': {
                    background: currentNav == 'news' ? 'white' : 'unset',
                    color: currentNav == 'news' ? 'black' : 'unset',
                    borderRadius: currentNav == 'news' ? '16px' : 'unset',
                    padding: currentNav == 'news' ? '4px 13px' : 'unset'
                  }
                }}
              />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton 
            sx={{ textAlign: 'left' }}>
            <ListItemText
              primary={!isLoggedIn ? 'Connect Wallet' : 'Disconnect'}
              sx={{ color: 'white' }}
              onClick={e => {
                handleClick();
                handleDrawerToggle();
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <Box className={classes.root}>
      <Box
        sx={{ display: 'flex' }}
      >
        <AppBar component='nav' className={classes.navbar} style={{ position: 'absolute', zIndex: 10 }}>
          <Box className={classes.container}>
            <Toolbar className={classes.toolbar}>
              <Box className={classes.titleBox}>
                {
                  location.pathname == '/' || location.pathname == '/roadmap' ?
                    <img src={Logo} className={classes.logo} />
                    :
                    <img src={LogoExtra} className={classes.logo} />
                }
              </Box>
              <Typography
                variant='h4'
                component='div'
                className={classes.gap}
              >
              </Typography>
              <Box sx={{ display: { xs: 'none', sm: 'none', lg: 'block' }, justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                  className={classes.navItem}
                  sx={{
                    textTransform: 'none',
                    textUnderlineOffset: '5px',
                    color: location.pathname == '/' || location.pathname == '/roadmap' ? ('' == currentNav ? 'black !important' : 'unset') : 'black !important',
                    background: '' == currentNav ? 'white !important' : 'unset',
                  }}
                  onClick={() => navigate('/')}
                >
                  Home
                </Button>
                <Button
                  className={classes.navItem}
                  sx={{
                    textTransform: 'none',
                    textUnderlineOffset: '5px',
                    color: location.pathname == '/' || location.pathname == '/roadmap' ? ('' == currentNav && window.location.hash == 'token' ? 'white !important' : 'unset') : 'black !important',
                    background: 'vesting' == currentNav ? '#20C2E5 !important' : 'unset',
                  }}
                  onClick={() => {
                    window.location.href = '/vesting';
                  }}
                >
                  Vesting
                </Button>
                <Button
                  className={classes.navItem}
                  sx={{
                    textTransform: 'none',
                    textUnderlineOffset: '5px',
                    color: location.pathname == '/' || location.pathname == '/roadmap' ? ('' == currentNav && window.location.hash == 'token' ? 'white !important' : 'unset') : 'black !important',
                    background: 'Token' == currentNav ? '#20C2E5 !important' : 'unset',
                  }}
                  onClick={() => {
                    window.location.href = '/#token';
                  }}
                >
                  Token
                </Button>
                <Button
                  className={classes.navItem}
                  // disabled
                  sx={{
                    textTransform: 'none',
                    textUnderlineOffset: '5px',
                    color: location.pathname == '/' || location.pathname == '/staking' || location.pathname == '/roadmap' ? ('' == currentNav && window.location.hash == 'staking' ? 'white !important' : 'unset') : 'black !important',
                    background: 'staking' == currentNav ? '#20C2E5 !important' : 'unset',
                  }}
                  onClick={() => {
                    window.location.href = '/staking';
                  }}
                >
                  Staking
                </Button>
                <Button
                  className={classes.navItem}
                  sx={{
                    textTransform: 'none',
                    textUnderlineOffset: '5px',
                    color: location.pathname == '/' || location.pathname == '/roadmap' ? ('Home' == currentNav ? 'white !important' : 'unset') : 'black !important',
                    background: 'Home' == currentNav ? '#20C2E5 !important' : 'unset',
                  }}
                  onClick={() => window.location.href = '/#certificates'}
                >
                  Certificates
                </Button>
                <Button
                  className={classes.navItem}
                  sx={{
                    textTransform: 'none',
                    textUnderlineOffset: '5px',
                    color: location.pathname == '/' || location.pathname == '/roadmap' ? ('Resources' == currentNav ? 'white !important' : 'unset') : 'black !important',
                    background: 'Resources' == currentNav ? '#20C2E5 !important' : 'unset',
                  }}
                  onClick={handleClickAnchor2}
                >
                  Resources
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl2}
                  open={open2}
                  onClose={handleCloseAnchor2}
                  TransitionComponent={Fade}
                  className={classes.backdrop}
                >
                  <MenuItem onClick={() => {
                    handleCloseAnchor2();
                    window.open('/pitch.pdf', '_blank');
                  }}
                    sx={{ 
                      fontSize: '18px', 
                      py: '10px',
                      color: location.pathname == '/' || location.pathname == '/roadmap' ? ('' == currentNav && window.location.hash == 'token' ? 'white !important' : 'unset') : 'black !important',
                    }}
                  >Pitch Deck</MenuItem>
                  <MenuItem onClick={() => {
                    handleCloseAnchor2();
                    window.open('/whitepaper-zaya.pdf', '_blank');
                  }}
                    sx={{ 
                      fontSize: '18px', 
                      py: '10px',
                      color: location.pathname == '/' || location.pathname == '/roadmap' ? ('' == currentNav && window.location.hash == 'token' ? 'white !important' : 'unset') : 'black !important',
                    }}
                  >Whitepaper</MenuItem>
                  <MenuItem onClick={() => {
                    handleCloseAnchor2();
                    navigate('/team');
                  }}
                    sx={{ 
                      fontSize: '18px', 
                      py: '10px',
                      color: location.pathname == '/' || location.pathname == '/team' || location.pathname == '/roadmap'  ? ('' == currentNav && window.location.hash == 'team' ? 'black !important' : (!window.location.hash && location.pathname == '/team' ? 'black' : 'unset')) : 'black !important',
                    }}
                  >Team</MenuItem>
                  <MenuItem onClick={() => {
                    handleCloseAnchor2();
                    navigate('/roadmap');
                  }}
                    sx={{ 
                      fontSize: '18px', 
                      py: '10px',
                      color: location.pathname == '/' || location.pathname == '/roadmap' ? ('' == currentNav && window.location.hash == 'token' ? 'white !important' : 'unset') : 'black !important',
                    }}
                  >Roadmap</MenuItem>
                  <MenuItem onClick={() => {
                    handleCloseAnchor2();
                    window.location.href = '/#faq';
                  }}
                    sx={{ 
                      fontSize: '18px', 
                      py: '10px',
                      color: location.pathname == '/' || location.pathname == '/roadmap' ? ('' == currentNav && window.location.hash == 'token' ? 'white !important' : 'unset') : 'black !important',
                    }}
                  >FAQ’s</MenuItem>
                  <MenuItem onClick={() => {
                    handleCloseAnchor2();
                    navigate('/news');
                  }}
                    sx={{ 
                      fontSize: '18px', 
                      py: '10px',
                      color: location.pathname == '/' || location.pathname == '/roadmap' ? ('' == currentNav && window.location.hash == 'token' ? 'white !important' : 'unset') : 'black !important',
                    }}
                  >News</MenuItem>
                </Menu>
                <Button
                  className={classes.navItem}
                  sx={{
                    textTransform: 'none',
                    textUnderlineOffset: '5px',
                    color: location.pathname == '/' || location.pathname == '/roadmap' ? ('Contact' == currentNav ? 'white !important' : 'unset') : 'black !important',
                    background: 'Contact' == currentNav ? '#20C2E5 !important' : 'unset',
                  }}
                  onClick={() => navigate('/contact')}
                >
                  Contact
                </Button>
                {
                  !isLoggedIn ?
                    <Button
                      className={classes.navItem}
                      sx={{
                        textTransform: 'none',
                        textUnderlineOffset: '5px',
                        color: location.pathname == '/' || location.pathname == '/roadmap' ? ('connect' == currentNav ? 'white !important' : 'unset') : 'black !important',
                        background: 'connect' == currentNav ? '#20C2E5 !important' : 'unset',
                      }}
                      onClick={handleClick}
                    >
                      Connect Wallet
                    </Button>
                    :
                    <Button
                      className={classes.navItem}
                      sx={{
                        textTransform: 'none',
                        textUnderlineOffset: '5px',
                        color: location.pathname == '/' || location.pathname == '/roadmap' ? ('connect' == currentNav ? 'white !important' : 'unset') : 'black !important',
                        background: 'connect' == currentNav ? '#20C2E5 !important' : 'unset',
                      }}
                      onClick={handleClick}
                    >
                      Disconnect Wallet
                    </Button>
                }
              </Box>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='start'
                onClick={handleDrawerToggle}
                sx={{ display: { lg: 'none' }, color: location.pathname == '/' || location.pathname == '/roadmap' ? 'white' : 'black' }}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </Box>
        </AppBar>
        <Box component="nav">
          <Drawer
            anchor='right'
            container={document.body}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true
            }}
            sx={{
              display: { xs: 'block', lg: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' }
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      <main className='d-flex flex-column flex-grow-1'>
        <AuthenticatedRoutesWrapper
          routes={routes}
          unlockRoute={`${routeNames.unlock}${search}`}
        >
          <Box
            component='main'
            sx={{ display: 'block' }}
          >
            {children}
          </Box>
        </AuthenticatedRoutesWrapper>
        <Footer />
      </main>
    </Box>
  );
};
