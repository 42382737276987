import React, { useState, useEffect } from 'react';
import {
  Typography,
  FormControl,
  TextField,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import emailjs from 'emailjs-com';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    submitBtn: {
      width: '100% !important',
      margin: '1rem !important',
      paddingTop: '1rem !important',
      paddingBottom: '1rem !important',
      borderRadius: '108px !important',
      border: '1px solid var(--neutral-400, #D9DBE9)',
      background: '#20C2E5 !important',
      boxShadow: '0px 2px 12px 0px rgba(20, 20, 43, 0.08)',
      marginTop: '1rem !important',
      color: 'white !important',
      fontSize: '1.2rem !important',
      [theme.breakpoints.down('sm')]: {
        margin: '0 !important'
      }
    },
    gridPaddingLeft: {
      paddingLeft: '3rem',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0',
        marginTop: '90px !important'
      }
    },
    textField: {
      borderRadius: '10px',
      background: '#f7f7f7',
      margin: '1rem 0 !important',
      '& fieldset': {
        border: 'none !important'
      }
    },
    subscription: {
      margin: '1rem !important',
      fontSize: '15px !important'
    }
  })
);

const ContactForm = () => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [message, setMessage] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_KEY as string);
  }, []);

  const verifyUser = async (token: string) => {
    const response = await axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/user/verify-user`,
      method: 'post',
      data: { token },
    });

    return response.data.isTokenValid;
  };

  const handleSubmit = async (e: any) => {
    let token = '';

    if (!name) {
      toast.warn('Name is required');
      return;
    }
    if (!email) {
      toast.warn('Email is required');
      return;
    }
    if (!privacyCheck) {
      toast.warn('You must agree to our privacy policy');
      return;
    }

    let isUserValid = false;

    if (executeRecaptcha) {
      token = await executeRecaptcha('contact');
      isUserValid = await verifyUser(token);
    } else console.log('Execute recaptcha not yet available');

    if (isUserValid) {
      try {
        emailjs.send(process.env.REACT_APP_SERVICE_ID as string, process.env.REACT_APP_TEMPLATE_ID as string, {
         from_name: name,
         to_name: 'Zaya',
         email: email,
         message
       });
      } catch (e) {
        console.log(e);
      }

      toast.success('Your data has been sent successfully');
      setName('');
      setEmail('');
      setPrivacyCheck(false);
      setMessage('');
    }
  };

  return (
    <>
      <div>Name *</div>
      <FormControl fullWidth variant='standard'>
        <TextField
          id='standard-adornment-amount'
          variant='outlined'
          value={name}
          className={classes.textField}
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>
      <div>Email *</div>
      <FormControl fullWidth variant='standard'>
        <TextField
          id='standard-adornment-amount'
          variant='outlined'
          value={email}
          className={classes.textField}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <div>Send us a Message</div>
      <TextField
        id='outlined-multiline-static'
        multiline
        fullWidth
        rows={12}
        defaultValue=''
        className={classes.textField}
        onChange={(e) => setMessage(e.target.value)}
        value={message}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={privacyCheck}
            onChange={() => setPrivacyCheck(!privacyCheck)}
          />
        }
        label='I have read the privacy policy'
      />
      <Typography className={classes.subscription}>
        Zaya AI values your privacy. To learn more, read our{' '}
        <a href='/privacy-policy' target='_blank' style={{ color: '#20c2e5' }}>Privacy Policy.</a>
      </Typography>

      <Button className={classes.submitBtn} onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
};

export { ContactForm };
