import { dAppName } from 'config';
import { RouteType } from 'types';
import { withPageTitle } from './components/PageTitle';

import {Home,  SignMessage, Statistics , Wallets  , Welcome , Token , Roadmap , Contact, Vesting, Staking, Team, News, PrivacyPolicy, Luna, Oddiyana } from './pages';

export const routeNames = {
  home: '/',
  statistics: '/statistics',
  signMessage: '/sign-message',
  unlock: '/unlock',
  wallets: '/wallets',
  about: '/about',
  token: '/token',
  roadmap: '/roadmap',
  team: '/team',
  contact: '/contact',
  vesting: '/vesting',
  staking: '/staking',
  privacyPolicy: '/privacy-policy',
  news: '/news',
  luna: '/articles/luna',
  oddiyana: '/articles/oddiyana'
};

interface RouteWithTitleType extends RouteType {
  title: string;
}

export const routes: RouteWithTitleType[] = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.token,
    title: 'Token',
    component: Token
  },
  {
    path: routeNames.roadmap,
    title: 'Roadmap',
    component: Roadmap
  },
  {
    path: routeNames.contact,
    title: 'Contact',
    component: Contact
  },
  {
    path: routeNames.wallets,
    title: 'Wallets',
    component: Wallets
  },
  {
    path: routeNames.statistics,
    title: 'Statistics',
    component: Statistics,
    authenticatedRoute: true
  },
  {
    path: routeNames.signMessage,
    title: 'Sign Message',
    component: SignMessage,
    authenticatedRoute: true
  },
  {
    path: routeNames.vesting,
    title: 'Vesting',
    component: Vesting
  },
  {
    path: routeNames.staking,
    title: 'Staking',
    component: Staking
  },
  {
    path: routeNames.team,
    title: 'Team',
    component: Team
  },
  {
    path: routeNames.news,
    title: 'News',
    component: News
  },
  {
    path: routeNames.luna,
    title: 'Luna',
    component: Luna
  },
  {
    path: routeNames.oddiyana,
    title: 'Oddiyana',
    component: Oddiyana
  },
  {
    path: routeNames.privacyPolicy,
    title: 'Privacy Policy',
    component: PrivacyPolicy
  }
];

export const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • MultiversX ${dAppName}`
    : `MultiversX ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});
