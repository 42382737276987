import { Address, SmartContract } from '@multiversx/sdk-core';

import { queryContract, loadAbi, ZayaContracts } from './utils';

export async function getIsInMaintenance (props: {config: {
  contractAddress: string,
  networkProviderUrl: string
}}) {
  const { config } = props;
  const {contractAddress, networkProviderUrl} = config;

  const abi = await loadAbi({contractType: ZayaContracts.VestingContract});

  const contract = new SmartContract({
    address: Address.fromBech32(contractAddress),
    abi
  });

  const res = await queryContract({
    contract,
    method: 'getIsInMaintenance',
    args: [],
    networkProviderUrl: networkProviderUrl
  });

  const val = res.firstValue?.valueOf() as boolean;

  return val;
}