import { AbiRegistry } from '@multiversx/sdk-core/out';
import privateVestingAbiJson from './assets/abi/private-vesting-sc.abi.json';
import sftMintAbiJson from './assets/abi/sft-mint.abi.json';
import vestingAbiJson from './assets/abi/vesting-sc.abi.json';
import stakingAbiJson from './assets/abi/zaya_staking.abi.json';

const SftMintAbi = AbiRegistry.create(sftMintAbiJson as any);
const VestingAbi = AbiRegistry.create(vestingAbiJson as any);
const StakingAbi = AbiRegistry.create(stakingAbiJson as any);
const PrivateVestingAbi = AbiRegistry.create(privateVestingAbiJson as any);

export const contractAddress =
  'erd1qqqqqqqqqqqqqpgqmhrlwpw7crgg85n0u65t7ckkvn7l6jhrt95skawnf7';

export const network = {
  id: '1',
  name: 'Devnet',
  egldLabel: 'xEGLD',
  walletAddress: 'https://devnet-wallet.multiversx.com',
  apiAddress: 'https://devnet-api.multiversx.com',
  gatewayAddress: 'https://devnet-gateway.multiversx.com',
  explorerAddress: 'http://devnet-explorer.multiversx.com',
  graphQlAddress: 'https://devnet-exchange-graph.multiversx.com/graphql'
};

export const contractName = 'ZayaStakig';

export const tokenName = 'ZAYA-fce4d7';
export const tokenDecimal = 18;

export const dAppName = 'Zaya';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';

export const apiTimeout = 10000;
export const transactionSize = 15;
export const TOOLS_API_URL = 'https://tools.multiversx.com';
/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];

export const isDev = false;

export const UsdcTokenId = isDev ? 'USDC-8d4068' : 'USDC-c76f1f';
export const InvestorNft = isDev ? 'CITY-47279e' : 'SEEDINV-9e0663';
export const ZAYA_TOKEN_ID = isDev ? 'SVEN-de92d2' : 'ZAYA-024622';
export const contracts = {
  SeedInvestorsSftMint: {
    address: isDev
      ? 'erd1qqqqqqqqqqqqqpgqd52kvh6df0y3mq44y77e89w3mtvdqkf7kqyscds9h3'
      : 'erd1qqqqqqqqqqqqqpgqt4c6ar722k64mdmlv82z5a2khymwy25vkqysh0rqm8',
    abi: SftMintAbi
  },
  ZayaVesting: {
    address: isDev
      ? 'erd1qqqqqqqqqqqqqpgqhcmyquh29mydm9lz3ju23m65r22kdupr5zvsfv6wlf'
      : 'erd1qqqqqqqqqqqqqpgq9drrzr9fgtnf08r7kuw0ntrmzlp3vm6nuslsw5gxeu',
    abi: VestingAbi
  },
  ZayaPrivateVesting: {
    address: isDev
      ? 'erd1qqqqqqqqqqqqqpgq4px2fcgqwef0fjcz6wpqf7txa0m8tkk95zvsekjuhd'
      : '',
    abi: PrivateVestingAbi
  },
  ZayaStaking: {
    address: isDev
      ? 'erd1qqqqqqqqqqqqqpgqqxzcpvk24w5sm0dzrrdt3zhmfd2t74x4t95s8pj8dg'
      : '',
    abi: StakingAbi
  }
};

// export const x = emailjs.send("service_ktyh0a6","template_d3xtzgm",{
//   from_name: "test_from_name",
//   to_name: "test_to_name",
//   message: "test_message",
//   reply_to: "test_reply_totest_replllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllly_",
//   });

export const emailjsConfig = {
  userId: 'rjwtnLYL0ZKa0Tqp8',
  serviceId: 'service_ktyh0a6',
  templateId: 'template_d3xtzgm'
};
