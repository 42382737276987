import React, { Dispatch, SetStateAction , KeyboardEvent } from 'react';

import {
    Box, Typography
} from '@mui/material';

import {
    createStyles, makeStyles
} from '@mui/styles';

interface Props {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    placeholder: string,
    error?: string,
    focusHandle?: () => void | undefined,
    isOnlyInteger?: boolean,
    type?: string
}

const useStyles = makeStyles((theme: any) =>
    createStyles({
        textField: {
            width: '100%',
            background: '#1C1F26 0% 0% no-repeat padding-box',
            border: '1px solid #A8B3CF33',
            borderRadius: '6px',
            marginTop: '0.5rem',
            height: '50px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        input: {
            width: '80%',
            background: 'none',
            border: 'none',
            padding: '1rem',
            color: 'white',
            outline: 'none'
        },
    })
);

const CustomInput = ({
    onChange,
    placeholder,
    error = '',
    focusHandle = undefined,
    isOnlyInteger = false,
    type = 'number'
}: Props) => {
    const classes = useStyles();
    const preventCharacters = (e: KeyboardEvent<HTMLInputElement>) => {
        if(type == 'number'){
            if(!isOnlyInteger && ['e' , '-'].includes(e.key)) e.preventDefault();
            if(isOnlyInteger && ['e' , '.' , '-'].includes(e.key)) e.preventDefault();
        }
    };
    return (
        <React.Fragment>
            <Box className={classes.textField}>
                <input
                    type={type}
                    onKeyDown={preventCharacters}
                    className={classes.input}
                    placeholder={placeholder}
                    onChange={onChange}
                    onFocus={() => {
                        if(focusHandle != undefined) focusHandle();
                    }}
                />
                <Typography
                    sx={{ fontSize: '12px', padding: '0.8rem' }}
                >
                    {/* max */}
                </Typography>
            </Box>
            {
                error.length > 0 ?
                    <small style={{ color: 'red', fontWeight: '900' }}>{error}</small>: ''
            }
        </React.Fragment>
    );
};


export { CustomInput };
