import React, { } from 'react';
import {
    Box, Container
} from '@mui/material';


import {
    createStyles, makeStyles
} from '@mui/styles';

import BgImg from '../../assets/img/background.png';
import Logo from '../../assets/img/logo.png';
import TitleImg from '../../assets/img/title.png';
import IconImg from '../../assets/img/logo.png';

import Arrow from '../../assets/img/Home/product_arrow.png';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            textAlign: 'center',
            position: 'relative',
            padding: '5rem'
        },
        body: {
            border: '0.84px solid rgba(255, 255, 255, 0.51)',
            backdropFilter: 'blur(83.9669418334961px)',
            background: 'rgba(255, 255, 255, 0.60)',
            position: 'relative',
            top: '-150px',
            maxWidth: '100%',
            margin: 'auto',
            borderBottomRightRadius: '50px',
            borderBottomLeftRadius: '50px',
            display: 'flex',
            gap: 20,
            padding: '10px'
        },
        header: {
            color: '#16374D',
            fontSize: '20px',
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal'
        },
        title: {
            fontSize: '20px',
            fontWeight: 'bold',
            marginBottom: '5px'
        },
        desc: {
            fontSize: '16px',
            fontWeight: '400 !important',
        },
        img: {
            width: '100%'
        },
        textBody: {
            textAlign: 'left',
            padding: '1rem'
        },
        iconImg: {
            marginTop: '20px',
            width: '25px',
            height: '25px'
        },
        read: {
            textAlign: 'right'
        },
        arrow: {
            width: '15px'
        }
    })
);

interface Props {
    iamgeSRC: string,
    header: string,
    content: string
}

const Product = ({

    iamgeSRC, header, content

}: Props) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <img src={iamgeSRC} className={classes.img} />
            <Box className={classes.body}>
                <Box className={classes.textBody}>
                    <p className={classes.title}>{header}</p>
                    <p className={classes.desc}>{content}</p>
                    <p className={classes.read}>
                        Read More
                        <img src = {Arrow} className={classes.arrow}/>
                    </p>
                </Box>
            </Box>
        </Box>
    );
};

export { Product };
