import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import Plus from '../../assets/img/blockchain/plus.png';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    faqContainer: {
      background: '#FFF',
      [theme.breakpoints.down('sm')]: {
        display: 'none !important'
      }
    },
    faqGrid: {
      background:
        'linear-gradient(180deg, rgba(247, 252, 255, 0) 0%, rgba(235,	250, 255, 0.6) 100%)',
      padding: '102px 185px',
      [theme.breakpoints.down('lg')]: {
        padding: '30px'
      }
    },
    faqLeft: {
      padding: '100px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '50%',
      maxWidth: '50% !important',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: '100% !important',
        textAlign: 'center',
        padding: '10px 50px 100px 50px'
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'start'
      }
    },
    faqTitle: {
      fontSize: '60px !important',
      fontWeight: '500 !important',
      color: '#1B1D21 !important',
      lineHeight: '65.5px !important'
    },
    faqDesc: {
      marginTop: '12px !important',
      color: 'black !important',
      fontSize: '16px !important',
      fontWeight: '400 !important',
      lineHeight: '28px !important'
    },
    shape: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      [theme.breakpoints.down('sm')]: {
        right: '-25px'
      }
    },
    blueLight: {
      position: 'absolute',
      bottom: '-70px',
      left: 0,
      right: 0,
      margin: 'auto',
      [theme.breakpoints.down('md')]: {
        bottom: '-20px'
      }
    },
    redLight: {
      position: 'absolute',
      top: '30%',
      right: '20%',
      zIndex: 0
    },
    questionAccordion: {
      padding: '25px 45px !important',
      marginTop: '12px !important',
      position: 'relative',
      zIndex: 2,
      boxShadow: '0px -1px 0px 0px #EDF2F6 inset !important',
      '&:before': {
        background: 'none !important',
        boxShadow: 'none !important'
      }
    },
    plusImg: {
      width: '15px'
    }
  })
);

const FAQ = () => {
  const classes = useStyles();
  return (
    <Box className={classes.faqContainer}>
      <Grid container className={classes.faqGrid} id='faq'>
        <Grid
          item
          xl={12}
          className={classes.faqLeft}
          sx={{ flexDirection: 'column', position: 'relative' }}
        >
          <Typography className={classes.faqTitle}>
            Frequently ask question
          </Typography>
          <Typography className={classes.faqDesc}>
            Everything you need to know right here at your fingertips. Ask
            questions and browse around for answers
          </Typography>
          <img
            src='/assets/img/blue-shape.svg'
            alt='blue shape'
            className={classes.blueLight}
          />
        </Grid>
        <Grid item xl={6} style={{ position: 'relative' }}>
          <Accordion className={classes.questionAccordion}>
            <AccordionSummary
              expandIcon={<img src={Plus} className={classes.plusImg} />}
              aria-controls='panel1a-content'
              id='panel1a-header'
              sx={{
                padding: '0 !important',
                '& .MuiAccordionSummary-content': { margin: '0 !important' }
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px !important',
                  color: '#1B1D21 !important',
                  fontWeight: '500 !important'
                }}
              >
                What is a cryptocurrency wallet?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ lineHeight: '24px !important', fontSize: '16px' }}>
                A crypto wallet is a place where you can store your
                cryptocurrency. There are many different types of crypto
                wallets, each with its own advantages and disadvantages.
                Custodial wallets are the most convenient type of wallet, but
                they give up some control over your funds to a third party.
                Non-custodial wallets give you more control over
                your funds, but they can be more difficult to use. Hardware
                wallets are the most secure type of wallet, but they can be
                expensive. The best type of crypto wallet for you depends on
                your individual needs and preferences. If you are looking for a
                convenient way to store your crypto, a custodial wallet may be a
                good option. If you are looking for more control over your
                funds, a non-custodial wallet, may be a better
                choice.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.questionAccordion}>
            <AccordionSummary
              expandIcon={<img src={Plus} className={classes.plusImg} />}
              aria-controls='panel1a-content'
              id='panel1a-header'
              sx={{
                padding: '0 !important',
                '& .MuiAccordionSummary-content': { margin: '0 !important' }
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px !important',
                  color: '#1B1D21 !important',
                  fontWeight: '500 !important'
                }}
              >
                Why does Zaya need a blockchain component?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ lineHeight: '24px !important', fontSize: '16px' }}>
                <p>
                  Zaya needs a blockchain component because it will operate
                  through a decentralized application (dApp). dApps are
                  applications that run on a blockchain network, and they are
                  not controlled by any single entity. This makes them more
                  secure and transparent than traditional applications, which
                  are often controlled by centralized organizations.
                </p>
                <p style={{ marginTop: '10px' }}>
                  The blockchain component of the Zaya ecosystem will allow it
                  to store and manage user data in a secure and transparent way.
                  This data could include things like user profiles, transaction
                  history, and other sensitive medical diagnostic information.
                  The blockchain will also allow Zaya to track and verify
                  transactions in a secure and tamper-proof way.
                </p>
                <div>
                  Here are some of the specific benefits for Zaya using blockchain:
                  <ul style={{ paddingLeft: '24px' }}>
                    <li>
                      Security: The blockchain is a secure and tamper-proof way
                      to store data. This makes it ideal for storing sensitive
                      information.
                    </li>
                    <li>
                      Transparency: The blockchain is a transparent way to track
                      and verify transactions. This makes it possible for users
                      to see exactly how their data is being used and who has
                      access to it.
                    </li>
                    <li>
                      Decentralization: The blockchain is a decentralized
                      platform, which means that it is not controlled by any
                      single entity. This makes it more secure and resistant to
                      censorship.
                    </li>
                    <li>
                      Scalability: Blockchain can be scaled to
                      support a large number of users and transactions. This
                      makes it ideal for dApps that need to handle a high volume
                      of traffic.
                    </li>
                  </ul>
                </div>
                Overall, the blockchain component in the Zaya ecosystem will
                make it a more secure, transparent, and decentralized entity.
                This will make it a more attractive platform for medical
                affiliates and practitioners who are looking for a secure and
                trustworthy way to store and manage their data.
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.questionAccordion}>
            <AccordionSummary
              expandIcon={<img src={Plus} className={classes.plusImg} />}
              aria-controls='panel1a-content'
              id='panel1a-header'
              sx={{
                padding: '0 !important',
                '& .MuiAccordionSummary-content': { margin: '0 !important' }
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px !important',
                  color: '#1B1D21 !important',
                  fontWeight: '500 !important'
                }}
              >
                Why will AI technology improve cancer diagnosis and pathology?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ lineHeight: '24px !important', fontSize: '16px' }}>
                <p>
                  Artificial intelligence (AI) technology has the potential to
                  improve cancer diagnosis and pathology in a number of ways,
                  including:
                </p>
                <div>
                  <ul style={{ paddingLeft: '24px' }}>
                    <li>
                      Increased accuracy: AI algorithms can be trained on large
                      datasets of images and other data to identify cancer with
                      greater accuracy than humans. This is especially important
                      for early-stage cancers, which can be difficult to detect.
                    </li>
                    <li>
                      Reduced time to diagnosis: AI algorithms can process
                      images and data much faster than humans, which can help to
                      reduce the time it takes to diagnose cancer. This is
                      important because early diagnosis and treatment can
                      improve the chances of survival.
                    </li>
                    <li>
                      Improved patient outcomes: AI algorithms can be used to
                      identify patients who are at high risk of developing
                      cancer, and they can also be used to personalize treatment
                      plans for each individual patient.
                    </li>
                    <li>
                      Reduced costs: AI algorithms can automate many of the
                      tasks that are currently performed by pathologists, which
                      can help to reduce the cost of cancer diagnosis and
                      treatment.
                    </li>
                  </ul>
                </div>
                <p style={{ marginTop: '10px' }}>
                  In addition to these benefits, AI technology is also being
                  used to develop new cancer treatments, such as targeted
                  therapies and immunotherapy. These treatments are more
                  effective than traditional chemotherapy and radiation therapy,
                  and they have fewer side effects.
                </p>
                Overall, AI technology has the potential to revolutionize cancer
                diagnosis and pathology. It can help to improve accuracy, reduce
                time to diagnosis, improve patient outcomes, and reduce costs.
                As AI technology continues to develop, it is likely to play an
                increasingly important role in the fight against cancer.
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.questionAccordion}>
            <AccordionSummary
              expandIcon={<img src={Plus} className={classes.plusImg} />}
              aria-controls='panel1a-content'
              id='panel1a-header'
              sx={{
                padding: '0 !important',
                '& .MuiAccordionSummary-content': { margin: '0 !important' }
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px !important',
                  color: '#1B1D21 !important',
                  fontWeight: '500 !important'
                }}
              >
                How will cross boarder collaboration and AI technology aid
                medical diagnosis?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ lineHeight: '24px !important' }}>
                <p>
                  Cross-border collaboration and AI technology can aid medical
                  diagnosis in a number of ways:
                </p>
                <div>
                  <ul style={{ paddingLeft: '24px', fontSize: '16px' }}>
                    <li>
                      Access to more data: AI algorithms are trained on large
                      datasets of medical images, patient records, and other
                      data. By collaborating with researchers and healthcare
                      providers in other countries, AI developers can access
                      more data to train their algorithms, which can lead to
                      improved accuracy and performance.
                    </li>
                    <li>
                      Expertise sharing: AI technology can be used to share
                      expertise between healthcare providers in different
                      countries. For example, an AI algorithm can be used to
                      analyze a patient's medical records and recommend the best
                      course of treatment. This can be especially helpful in
                      cases where there is no local expertise available.
                    </li>
                    <li>
                      Early detection: AI algorithms can be used to analyze
                      medical data to detect diseases at an earlier stage. This
                      can lead to improved patient outcomes, as early diagnosis
                      and treatment can often be more effective than treatment
                      that is delayed.
                    </li>
                    <li>
                      Personalized treatment: AI algorithms can be used to
                      personalize treatment plans for each individual patient.
                      This can be especially helpful for patients with rare
                      diseases or complex medical conditions.
                    </li>
                    <li>
                      Cost savings: AI technology can be used to automate many
                      of the tasks that are currently performed by healthcare
                      providers. This can help to reduce costs and free up time
                      for providers to focus on more complex cases.
                    </li>
                  </ul>
                </div>
                Overall, cross-border collaboration and AI technology have the
                potential to revolutionize medical diagnosis. By working
                together, healthcare providers and Zaya AI can develop new and
                innovative ways to diagnose diseases, improve patient outcomes,
                and reduce costs.
              </div>
            </AccordionDetails>
          </Accordion>
          <img
            src='/assets/img/red-shape.svg'
            alt='red shape'
            className={classes.redLight}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export { FAQ };
