import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import axios from 'axios';
import { New } from 'components/common';
import Left from '../../assets/img/Home/left.png';
import Right from '../../assets/img/Home/right.png';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    newContainer: {
      paddingTop: '150px',
      background: 'white',
      [theme.breakpoints.down('md')]: {
        paddingTop: '60px'
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '10px'
      }
    },
    newTitleContainer: {
      marginLeft: '15rem !important',
      marginRight: '15rem !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('lg')]: {
        marginLeft: '26px !important',
        marginRight: '26px !important'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '26px !important',
        marginRight: '26px !important',
        display: 'flex'
      }
    },
    newTitle: {
      fontSize: '35px !important',
      fontWeight: '600 !important'
    },
    imgContainer2: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex'
      }
    },
    left: {
      padding: '0.5rem',
      width: '35px',
      background: 'white',
      borderRadius: '30px',
      border: '2px solid #20C2E5'
    },
    right: {
      padding: '0.5rem',
      width: '35px',
      background: '#20C2E5',
      borderRadius: '30px',
      marginLeft: '1.2rem'
    },
    newsBox: {
      width: '480px',
      minHeight: '630px',
      flexShrink: 0,
      paddingTop: '30px',
      marginRight: '7px',
      [theme.breakpoints.down('sm')]: {
          padding: '30px'
      }
    }
  })
);

const Blog = () => {
  const classes = useStyles();
  const [blogPosts, setBlogPosts] = useState<any[]>([]);
  const newReleases = [
    {
      date: '2024-05-20T11:19:14',
      description: '<p>Renowned for their commitment to advancing the decentralization movement, Oddiyana Ventures stands as a beacon in the investment space. They have successfully collaborated with over 150 esteemed venture partners worldwide, invested in over 100 pioneering projects, and nurtured the growth of over 15 ventures. With Oddiyana Ventures on board as our partner, ZayaAI is set for an exciting future!</p>',
      image: '/news/1.jpeg',
      link: '/articles/oddiyana',
      title: 'We are pleased to announce our partnership with Oddiyana!',
      id: '1'
    },
    {
      date: '2024-05-15T11:19:14',
      description: '<p>Big things are happening! We have teamed up with Skynet Trading, a licensed boutique firm deeply focused on developing trading strategies for the #cryptocurrency market! Skynet Trading has been a designated Market Maker and Advisory firm for #digitalassets and exchanges for *over 4 years*. Their team provides institutional-grade liquidity solutions and quantitative trading strategies through their in-house built, non-custodial trading software.</p>',
      image: '/news/2.jpeg',
      link: '',
      title: 'We have teamed up with Skynet Trading!',
      id: '2'
    },
    {
      date: '2024-05-15T11:19:14',
      description: '<p>ZayaAI, a comprehensive ecosystem dedicated to combating cancer using advanced artificial intelligence (AI), is pleased to announce the appointment of Luna PR, an acclaimed agency recognized for its expertise in Web3 and emerging technologies. This collaboration marks a significant milestone for ZayaAI, leveraging Luna PR\'s proficiency to amplify visibility and communicate the company\'s mission and competencies across both Web2 and Web3 platforms.</p>',
      image: '/news/3.png',
      link: '/articles/luna',
      title: 'ZayaAI Appoints Luna PR to Lead Web2-Web3 Integration',
      id: '3'
    }
  ];

  const getBlogPosts = async () => {
    const response = await axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/posts/get-posts`,
      method: 'get'
    });
    const posts = response.data.posts;
    setBlogPosts(posts);
  };

  useEffect(() => {
    getBlogPosts();
  }, []);

  return (
    <Box className={classes.newContainer} id='news'>
      <Box className={classes.newTitleContainer}>
        <Typography className={classes.newTitle}>Company News</Typography>
        <Box className={classes.imgContainer2}>
          <img
            src={Left}
            className={classes.left}
            onClick={() => {
              const x = document.getElementById('new');
              if (x) x.scrollLeft -= 500;
            }}
          />
          <img
            src={Right}
            className={classes.right}
            onClick={() => {
              const x = document.getElementById('new');
              if (x) x.scrollLeft += 500;
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          overflowX: 'scroll',
          width: 'auto',
          height: 'auto',
          paddingLeft: { xs: '10px', sm: '30px', lg: '180px' }
        }}
        id='new'
      >
        {
          newReleases?.map((item, index) => (
            <Box key={index} className={classes.newsBox}>
              <New
                date={item.date}
                header={item.title}
                content={item.description}
                url={item.link}
                image={item.image}
              />
            </Box>
          ))
        }
        {blogPosts?.map((item, index) => (
          <Box key={index} className={classes.newsBox}>
            <New
              date={item.date}
              header={item.title}
              content={item.description}
              url={item.link}
              image={item.image}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export { Blog };
