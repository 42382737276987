import React from 'react';
import {
    Container, Box, Typography,
} from '@mui/material';
import {
    createStyles, makeStyles
} from '@mui/styles';
import {

    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton

} from '@multiversx/sdk-dapp/UI';
import { useLocation, useSearchParams } from 'react-router-dom';
import { walletConnectV2ProjectId } from 'config';
import { routeNames } from 'routes';
import { AuthRedirectWrapper } from '../../components';







const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            color: '#16374D !important',
            display: 'flex !important',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 64px)',
            maxWidth: '100vw !important',
            [theme.breakpoints.down('sm')]: {
                padding: '0 !important'
            },
            background: 'linear-gradient(51deg, #E7F0FF 0%, rgba(232, 241, 255, 0.47) 100%)'
        },
        dialog: {
            width: '550px',
            height: 'auto',
            borderRadius: '10px',
            marginTop: '5rem',
            opacity: 0.8,
            padding: '40px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: 'linear-gradient(51deg, #E7F0FF 0%, rgba(232, 241, 255, 0.47) 100%)',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '1rem !important',
                paddingRight: '1rem !important'
            }
        },
        title: {
            fontFamily: 'OpenSans_Bold !important',
            color: '#16374D',
            fontSize: '24px !important',
            fontWeigh: '900 !important',
            opacity: 1,
            marginBottom: '16px !important',
            paddingBottom: '0.5rem !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px !important',
            },
            textAlign: 'center'
        },
        btn: {
            width: '270px !important',
            padding: '18px 24px !important',
            borderRadius: '76px !important',
            background: '#20C2E5 !important',
            fontSize: '18px !important',
            color: 'white !important',
            marginLeft: '13px !important',
            fontWeight: '500 !important',
            border: 'none'
        }
    })
);


const Wallets = () => {
    const [searchParams] = useSearchParams();
    const redirect = searchParams.get('redirectTo');

    console.log({searchParams});
    const commonProps = {
        callbackRoute: `${redirect}`,
        nativeAuth: true, // optional,
    };

    const classes = useStyles();
    return (
        <AuthRedirectWrapper>
            <Container className={classes.root}>
                <Box className={classes.dialog}>
                    <Typography className={classes.title}>
                        Wallet Connects
                    </Typography>
                    <ExtensionLoginButton
                        loginButtonText='MultiversX Extension'
                        {...commonProps}
                        className={classes.btn}
                    />
                    <WebWalletLoginButton
                        loginButtonText='Web wallet'
                        {...commonProps}
                        className={classes.btn}
                    />
                    <LedgerLoginButton
                        loginButtonText='Ledger'
                        className={classes.btn}
                        {...commonProps}
                    />
                    <WalletConnectLoginButton
                        loginButtonText='xPortal'
                        {...commonProps}
                        {...(walletConnectV2ProjectId
                            ? {
                                isWalletConnectV2: true
                            }
                            : {})}
                        className={classes.btn}
                    />
                </Box>
            </Container>
        </AuthRedirectWrapper>
    );
};


export { Wallets };
