import { Address, SmartContract } from '@multiversx/sdk-core';

import BigNumber from 'bignumber.js';
import { queryContract, loadAbi, bigNumberToFloat, ZayaContracts } from './utils';

export async function getClaimableAmount (props: {config: {
  contractAddress: string,
  networkProviderUrl: string
}, investorAddress: string}) {
  const { config, investorAddress } = props;
  const {contractAddress, networkProviderUrl} = config;

  const abi = await loadAbi({contractType: ZayaContracts.VestingContract});

  const contract = new SmartContract({
    address: Address.fromBech32(contractAddress),
    abi
  });

  const res = await queryContract({
    contract,
    method: 'getClaimableAmount',
    args: [investorAddress],
    networkProviderUrl: networkProviderUrl
  });

  const amount = (res.firstValue?.valueOf() as bigint).toString();

  return bigNumberToFloat(BigNumber(amount), 18);
}