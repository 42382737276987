import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {
  AxiosInterceptorContext, // using this is optional
  DappProvider,
  Layout
} from 'components';
import {
  TransactionsToastList,
  NotificationModal,
  SignTransactionsModals
} from 'components';
import {
  apiTimeout,
  walletConnectV2ProjectId,
  sampleAuthenticatedDomains,
  isDev
} from 'config';
import { PageNotFound, Unlock } from 'pages';
import { routeNames } from 'routes';
import { routes } from 'routes';
import { EnvironmentsEnum } from 'types';

import 'react-toastify/dist/ReactToastify.css';


import {
  CssBaseline
} from '@mui/material';

import {
  createStyles, makeStyles
} from '@mui/styles';

import {
  ThemeProvider, createTheme
} from '@mui/material/styles';


const theme = createTheme({
  typography: {
    fontFamily: 'Manrope !important'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 980,
      lg: 1200,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      minHeight: '100vh',
      fontWeight: 'Bold'
    }
  })
);

export const App = () => {
  const classes = useStyles();
  return (
    <AxiosInterceptorContext.Provider>
      <AxiosInterceptorContext.Interceptor
        authenticatedDomanis={sampleAuthenticatedDomains}
      >
        <Router>
          <DappProvider
            environment={isDev ? EnvironmentsEnum.devnet : EnvironmentsEnum.mainnet}
            customNetworkConfig={{
              name: 'zaya-dapp',
              apiTimeout,
              walletConnectV2ProjectId
            }}
          >
            <ThemeProvider theme={theme}>
              <div className={classes.root}>
                <CssBaseline />
                <Layout>
                  <ToastContainer />
                  <AxiosInterceptorContext.Listener />
                  <TransactionsToastList />
                  <NotificationModal />
                  <SignTransactionsModals className='custom-class-for-modals' />
                  <Routes>
                    <Route path={routeNames.unlock} element={<Unlock />} />
                    {routes.map((route, index) => (
                      <Route
                        path={route.path}
                        key={'route-key-' + index}
                        element={<route.component />}
                      />
                    ))}
                    <Route path='*' element={<PageNotFound />} />
                  </Routes>
                </Layout>
              </div>
            </ThemeProvider>
          </DappProvider>
        </Router>
      </AxiosInterceptorContext.Interceptor>
    </AxiosInterceptorContext.Provider>
  );
};
