import {
    Box, Typography, Grid, Container
} from '@mui/material';

import {
    createStyles, makeStyles
} from '@mui/styles';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ContactForm } from 'components/ContactForm/ContactForm';
import Wave from '../../assets/img/telepathology_dx/wave.png';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            paddingTop: '125px',
            minHeight: '100vh',
            height: 'auto',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                paddingTop: '75px'
            },
            position: 'relative',
            overflowX: 'hidden'
        },
        wave: {
            position: 'absolute',
            top: '10rem',
            width: '100%'
        },
        container: {
            marginTop: '50px',
            [theme.breakpoints.down('sm')]: {
                padding: '26px'
            },
        },
        gridPadding: {
            paddingRight: '3rem',
            [theme.breakpoints.down('sm')]: {
                paddingRight: '0'
            },
        },
        gridPaddingLeft: {
            paddingLeft: '3rem',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '0',
                marginTop: '90px !important'
            },
        },
        title: {
            fontSize: '50px !important',
            fontWeight: '400 !important',
            marginTop: '5rem !important',
            [theme.breakpoints.down('sm')]: {
                marginTop: '0 !important',
                fontSize: '30px !important',
                textAlign: 'center',
                fontWeight: '500 !important',
            },
        },
        desc: {
            fontSize: '20px !important',
            fontWeight: '400 !important',
            marginTop: '3rem !important',
            [theme.breakpoints.down('sm')]: {
                marginTop: '20px !important',
                fontSize: '16px !important'
            },
        },
        subTitle: {
            marginTop: '3rem !important',
            fontSize: '30px !important',
            fontWeight: '400 !important',
            [theme.breakpoints.down('sm')]: {
                marginTop: '20px !important',
            },
        },
        container1: {
            display: 'flex',
            flexDirection: 'column'
        },
        content: {
            marginTop: '2rem !important',
            fontSize: '20px !important',
            fontWeight: '400 !important',
            position: 'relative',
            zIndex: '1000',
            cursor: 'pointer',
            textDecoration: 'none',
            color: 'black',
            '&hover:': {
                textDecoration: 'none !important',
                color: 'black !important',
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '24px !important',
                fontSize: '16px !important',
            },
        },
        selectContainer: {
            margin: '1rem 0 !important',
            borderRadius: '10px !important',
            background: '#f7f7f7',
            '& fieldset': {
                border: 'none !important'
            }
        },
        label: {
            margin: '1rem 0 0 0.5rem !important'
        },
        subscription: {
            margin: '1rem !important',
            fontSize: '15px !important'
        }
    })
);


const Contact = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <img src={Wave} className={classes.wave} />
            <Container maxWidth='xl' className={classes.container}>
                <Grid container>
                    <Grid item md={6} className={classes.gridPadding}>
                        <Typography className={classes.title}>
                            Contact Us
                        </Typography>
                        <Typography className={classes.desc}>
                            Do you have any questions, or would like to receive more information related to our AI pathology solutions?
                        </Typography>
                        <Typography className={classes.subTitle}>
                            Email
                        </Typography>
                        <Typography className={classes.content} onClick={e => { window.location.href = 'mailto:contact@zaya.ai'; console.log('xxx'); }}>
                            contact@zaya.ai
                        </Typography>
                        <Typography className={classes.subTitle}>
                            Socials
                        </Typography>
                        <div className={classes.container1}>
                            <a href='https://twitter.com/ZayaAI_PathDx' target='_blank' className={classes.content}>
                                X (Twitter)
                            </a>
                            <a href='https://www.linkedin.com/company/zaya-artificial-intelligence' target='_blank' className={classes.content}>
                                LinkedIn
                            </a>
                            <a href='https://t.me/ZayaAI_EN' target='_blank' className={classes.content}>
                                Telegram
                            </a>
                            <a href='https://www.youtube.com/@zayaai' target='_blank' className={classes.content}>
                                Youtube
                            </a>
                        </div>
                    </Grid>

                    <Grid item md={6} className={classes.gridPaddingLeft}>
                        <GoogleReCaptchaProvider
                            reCaptchaKey={'6LdPkxIpAAAAAIPJrvExchYHTGhEjdi55ADAOEjd'}
                            scriptProps={{
                                async: false, // optional, default to false,
                                defer: false, // optional, default to false
                                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                                nonce: undefined, // optional, default undefined
                            }}
                        >
                            <ContactForm />
                        </GoogleReCaptchaProvider>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export { Contact };
