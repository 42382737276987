import React from 'react';
import {
    createStyles, makeStyles
} from '@mui/styles';

import {
    Box, Typography, Divider
} from '@mui/material';



const useStyles = makeStyles((theme: any) =>
    createStyles({
        caption: {
            display: 'flex !important',
            justifyContent: 'space-between',
            paddingTop: '0.5rem !important',
            paddingBottom: '0.5rem !important',
        },
        title: {
            fontFamily: 'OpenSans_Bold !important',
            color: 'white',
            fontSize: '14px !important',
            fontWeigh: 'bold !important',
            opacity: 1,
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px !important',
            }
        },
    })
);

type Row = {
    title: string,
    body: string
};

interface Props {
    values: Row[],
    className?: string
}

const CustomInfo: React.FC<Props> = ({
    values, className = ''
}: Props) => {
    const classes = useStyles();
    return (
        <Box className={className}>
            {
                values.map((item, index) => (
                    <Box className={classes.caption} key = {index}>
                        <Typography className={classes.title}>
                            {item.title}
                        </Typography>
                        <Typography className={classes.title}>
                            {item.body}
                        </Typography>
                    </Box>
                ))
            }
            <Divider sx={{
                backgroundColor: '#303137',
                marginTop: '2rem',
                marginBottom: '2rem'
            }} />
        </Box>
    );
};


export { CustomInfo };
