import React from 'react';
import {
    Button
} from '@mui/material';
import {
    createStyles, makeStyles
} from '@mui/styles';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        btn: {
            fontFamily: 'Inter_Bold !important',
            fontSize: '16px',
            background: 'transparent linear-gradient(90deg, #D02D02 0%, #FF5F07 50%, #FF8913 100%) 0% 0% no-repeat padding-box !important',
            width: '100%',
            padding: '0.6rem !important',
            color: 'white !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px !important',
            },
            '&:hover': {
                background: 'transparent linear-gradient(90deg, #FF8913 0%, #FF5F07 50%, #D02D02 100%) 0% 0% no-repeat padding-box !important',
                boxShadow: '0px 0px 10px #FF891326'
            },
            marginTop: '0.5rem !important',
        }
    })
);

interface Props {
    text: string,
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    disabled?: boolean
}

const GradientBtn = ({
    text,
    onClick,
    disabled = false
}: Props) => {
    const classes = useStyles();
    return (
        <Button
            disabled = {disabled}
            onClick={onClick}
            className={classes.btn}
            sx = {{
                background: disabled?'gray !important':'unset'
             }}
        >
            {text}
        </Button>
    );
};


export { GradientBtn };
